import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import fetchwrapper from '../../services/interceptors/fetchwrapper';
import { Box, Button, Typography, Grid, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TablePagination } from "@mui/material";
import Header from "../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import BackIcon from "../../components/BackIcon";
import ModalUploadFile from './Components/ModalUploadFile';


const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center", // Alineación central de los elementos
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "80vh",
}));


const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87",
    },
  },
});

function SolicitudEgresoDetalle() {
  const { id } = useParams();
  const [detalle, setDetalle] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idwmsInp: id })
    };

    fetchwrapper('/integracionjbg/cabdet_CyTsolpedtras', requestOptions)
      .then(response => response.json())
      .then(data => setDetalle(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [id]);

  const refreshParentScreen = () => {
      const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idwmsInp: id })
    };

    fetchwrapper('/integracionjbg/cabdet_CyTsolpedtras', requestOptions)
      .then(response => response.json())
      .then(data => setDetalle(data))
      .catch(error => console.error('Error fetching data:', error));
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    console.log("OpenModalclic");
    setOpenModal(true);
    console.log(openModal)
  };

  const handleCloseModal = () => {
    console.log("Funcion Close Padre");
    setOpenModal(false);
  };

  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    const d = new Date(date);
    const day = d.getUTCDate().toString().padStart(2, '0');
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = d.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  if (!detalle) {
    return <div>Cargando...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <StyledRoot>
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <h2 className="text-center mt-3">Solicitud de Egreso </h2>
          <Box mt={2} mb={2} className="text-center mt-3" >
            <Grid container spacing={2}>
              {Object.entries(detalle.cabecera).map(([key, value]) => (
                <Grid item xs={4} key={key}>
                  <Typography variant="subtitle1" gutterBottom><strong>{key}</strong>: {value}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop="20px" marginBottom="30px" marginRight="80px">
            <Button variant="contained" color="secondary" style={{ marginRight: '20px' }} onClick={handleOpenModal}>
              Importar datos
            </Button>
            {openModal && <ModalUploadFile  openModal={openModal} id={id} onCloseModal={handleCloseModal} refreshParentScreen={refreshParentScreen}/>}
          </Box>

          <TableContainer marginTop="20px">
            <Table striped="true" bordered hover="true" responsive="sm" className="border">
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: theme.palette.primary.main, color: 'white', textAlign: 'center' }}>ID</TableCell>
                  <TableCell style={{ backgroundColor: theme.palette.primary.main, color: 'white', textAlign: 'center' }}>POSICION</TableCell>
                  <TableCell style={{ backgroundColor: theme.palette.primary.main, color: 'white', textAlign: 'center' }}>CODIGO</TableCell>
                  <TableCell style={{ backgroundColor: theme.palette.primary.main, color: 'white', textAlign: 'center' }}>CANTIDAD</TableCell>
                  <TableCell style={{ backgroundColor: theme.palette.primary.main, color: 'white', textAlign: 'center' }}>FECHA_PEDIDO</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? detalle.detalles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : detalle.detalles
                ).map((detalle, index) => (
                  <TableRow key={index}>
                    <TableCell className="border" style={{ border: '1px solid #dddddd', textAlign: 'right' }}>{detalle.idwmsInp}</TableCell>
                    <TableCell className="border" style={{ border: '1px solid #dddddd', textAlign: 'right' }}>{detalle.BNFPO}</TableCell>
                    <TableCell className="border" style={{ border: '1px solid #dddddd', textAlign: 'right' }}>{detalle.MATNR}</TableCell>
                    <TableCell className="border" style={{ border: '1px solid #dddddd', textAlign: 'right' }}>{detalle.MENGE}</TableCell>
                    <TableCell className="border" style={{ border: '1px solid #dddddd', textAlign: 'right' }}>{formatDate(detalle.Fecha_Pedido)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={detalle.detalles.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </StyledRoot>
    </ThemeProvider>
  );
}

export default SolicitudEgresoDetalle;
