import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InnerModalPrinter from "./InnerModalPrinter";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PrintButton({ getProductsPrintable,printers }) {
  const [productsToPrint, setProductsToPrint] = useState([]);

  return (
    <div>
      <InnerModalPrinter 
      getProductsPrintable = {getProductsPrintable}
       />
    </div>
  );
}
