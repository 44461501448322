import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomBackdrop from "../../../components/CustomBackdrop";

function InputFiltrarClienteProforma({cabeceraProforma, setCabeceraProforma, setFormData, formData}) {
  const [clienteSelected, setClienteSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para controlar la carga

  // const filterOptions = createFilterOptions({
  //   limit: 200,
  //   matchFrom: "any",
  // });


  const getClientes = async () => {
    const options = {
      method: "POST",
      body: JSON.stringify({
        "search_term":inputValue
      }),
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    try {
      setIsLoading(true); // Mostrar animación de carga
      let response = await fetchwrapper("/proformas/filtros_de_cliente_all", options);
      response = await response.json();
      console.log(response);

      setOptions(response);

    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false); // Ocultar animación de carga
    }
  };

  const getDataCliente = async (clinombre) => {
    

    try {
      setIsLoading(true); // Mostrar animación de carga

      let data = await fetchwrapper(
        `/proformas/getCliente/${clinombre}`
      );
      data = await data.json();
      const newFormValues = {
        nombre: data.data.clinombre??"",
        clicodigo: data.data.clicodigo??"",
        cedulaRuc: data.data.cliruc??"",
        correo: data.data.cliemail??"",
        telefono: data.data.clitelef1??"",
        direccion: data.data.clidirec??"",
      };

      setFormData(newFormValues)
      console.log(data)
      console.log("tttt")

    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false); // Ocultar animación de carga
    }
  };

  useEffect(() => {
    getClientes();
  }, []);

  const filterOptions = createFilterOptions({
    limit: 200, // Limitar a las primeras 200 opciones
    matchFrom: 'start', // Permitir coincidencias parciales en cualquier parte del nombre
    stringify: (option) => option.clinombre, // Utilizar el nombre del cliente para el filtrado
  });

  return (
    <div>
      <CustomBackdrop isLoading={isLoading}/>
      <Autocomplete
        fullWidth
        value={value}
        onChange={ (event, newValue) => {
          if (newValue) {
            console.log(newValue)
            console.log("hareeee")
            setValue(newValue);
            console.log(newValue)
            

            
            console.log(newValue)
             getDataCliente(newValue.clinombre)


          } else {
            setValue("");
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.clinombre || ""}
        onInputChange={(event, newInputValue) => {
          if (event) {
            console.log(newInputValue)
            setInputValue(newInputValue);
          }
        }}
        id="controllable-states-demo"
        options={options}
        loading={isLoading} // Utilizar el estado de carga para bloquear el componente
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar cliente por nombre, cedula o ruc"
            key={options.clicodigo}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
               
                <>
                <IconButton onClick={getClientes}>
                  <SearchIcon />
                </IconButton>
                {isLoading && <CircularProgress color="inherit" size={20} />}{" "}
                {/* Mostrar animación de carga */}
                {params.InputProps.endAdornment}
              </>
              ),
            }}
          />
        )}
        filterOptions={filterOptions}
      />
    </div>
  );
}

export default InputFiltrarClienteProforma;
