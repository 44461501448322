import { createContext, useEffect, useState } from "react";

const GlobalContext = createContext();
const GlobalContextProvider = ({ children }) => {
    const [selectedBco, setSelectedBco] = useState([])



  const myContextData = {
    selectedBco,
    setSelectedBco

  };
  return (
    <GlobalContext.Provider value={myContextData}>
      {children}
    </GlobalContext.Provider>
  );
};


export {GlobalContext, GlobalContextProvider };
