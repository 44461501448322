import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Form from "./Form";
import { useVerificacionDocumentalContext } from "../Contexts/VerificacionDocumentalContext";
import DescargarImprimirFormularios from "./OpcionesDescargaImpresion";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import CustomBackdrop from "../../../components/CustomBackdrop";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAndPrintButton from "./SaveAndPrintButton";
import FormCheckListTransporteRecepcion from "./FormCheckListTransporteRecepcion";

export default function HorizontalLinearStepper({
  fetchedForms = [],
  isReadable = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const steps = [...fetchedForms].map((form) => form?.cabecera?.procesocod);

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const { forms, setForms, setSpecificForm } =
    useVerificacionDocumentalContext();

  const [areSavedForms, setAreSavedForms] = React.useState(false);

  //Actuliazar los formularios cuando ya esten cargados del fetch realizado
  useEffect(() => {
    if (fetchedForms.length !== 0) {
      setForms(fetchedForms);
    }
  }, [fetchedForms]);

  function _renderStepContent(step) {
    return (
      <Form
        form={forms[step]}
        setSpecificForm={setSpecificForm}
        step={step}
        isReadable={isReadable}
      />
    );
  }

  function esVariableVacia(variable) {
    // Expresión regular que verifica si la cadena está vacía o solo contiene espacios en blanco
    const regex = /^\s*$/;

    // Verificar si la variable es null o undefined
    if (variable === null || variable === undefined) {
      return true;
    }

    // Verificar si la variable es una cadena vacía o contiene solo espacios en blanco
    return regex.test(variable);
  }
  function isFormCompleted(step) {
    return !forms[step].preguntas.some(
      (pregunta) =>
        pregunta.pregobligatoria === -1 &&
        esVariableVacia(pregunta.respuestaSelected)
    );
  }
  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (!isFormCompleted(activeStep))
      return alert("Complete los campos que son obligatorios");
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  //call CREATE hook
  const {
    mutateAsync: createForms,
    isPending: isCreatingForms,
    isError: isErrorForms,
    isSuccess: isSuccessForms,
  } = useCreateForms();

  //CREATE hook (post new Forms to api)
  function useCreateForms() {
    return useMutation({
      mutationFn: async (forms) => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            forms: forms,
            codigoSolicitud: location.state.sgasoling,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        let response = await fetchwrapper(
          "/verificacionDocumental/createFormularioRespondido",
          options
        );
        response = await response.json();
        setAreSavedForms(true);

        return response.data;
      },
    });
  }

  const handleReset = async () => {
    // setActiveStep(0);

    //Solo guardar los formularios cuando no este en modo lectura
    if (isReadable) return;
    const formularios = [...forms];
    console.log(formularios);

    try {
      //Crear los formularios
      await createForms(formularios);
      alert("Formularios creado con éxito");
      navigate("/home/dashboard/verificacionDocumental/Crear");
      return false; //Exist error??
    } catch (err) {
      alert("Error al guardar los formularios");
      setActiveStep(0);
      return true;
    }

    //Descargar pdf e Imprimir pdf
    // DescargarImprimirFormularios(formularios);
    // saveAndPrint(forms[0],"Check-List-Transporte")
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomBackdrop isLoading={isCreatingForms} />
      <div style={{ width: "100%", overflow: "auto" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Todos los formularios fueron completados con éxito. De click en
            "Guardar" para grabar sus respuestas
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Regresar
            </Button>
            <Box sx={{ flex: "1 1 auto", position: "block" }} />
            <SaveAndPrintButton
              onAction={handleReset}
              disabled={areSavedForms}
              Component={<FormCheckListTransporteRecepcion data={forms[0]} />}
              nameDoc="Check=List-Transporte"
            />
            {/* <Button onClick={handleReset} disabled={areSavedForms}>
              Guardar
            </Button> */}
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div style={{ marginTop: "30px" }}>
            {_renderStepContent(activeStep)}
          </div>

          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Regresar
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
