import React, { useState, useEffect } from 'react';
import { useTable, usePagination } from 'react-table';
import { Table, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import fetchwrapper from '../../services/interceptors/fetchwrapper'; // Actualiza la ruta de importación según la estructura de tu proyecto
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, CircularProgress  } from "@mui/material";
import Header from "../../layouts/Header";
import BackIcon from "../../components/BackIcon";
import { Link } from 'react-router-dom';
import ModalNew from './components/ModalNew';

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "80vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});


function SolicitudEgreso() {
  const [cabeceras, setCabeceras] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para el spinner de carga
  const [refreshTable, setRefreshTable] = useState(false); // Estado para refrescar la tabla después de guardar


  useEffect(() => {
    fetchwrapper('/integracionjbg/cabeceras_sapCsolpedtras')
      .then(response => response.json())
      .then(data => {
        setCabeceras(data);
        setIsLoading(false); // Una vez que los datos se han cargado, establecemos isLoading a false
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [refreshTable]);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    console.log("OpenModalclic");
    setOpenModal(true);
    console.log(openModal)
  };

  const handleCloseModal = () => {
    console.log("Funcion Close Padre");
    setOpenModal(false);
  };
  


   // Función para cargar los datos iniciales de la tabla
   const handleSave = () => {
    // Llama a la API utilizando fetchwrapper
    fetchwrapper('/integracionjbg/cabeceras_sapCsolpedtras')
      .then(response => response.json())
      .then(data => setCabeceras(data))
      .catch(error => console.error('Error fetching data:', error));
  };


  const columns = React.useMemo(
    () => [
      // { Header: 'idwms', accessor: 'idwms' },
      {
        Header: 'Codigo Interno', accessor: 'idwmsInp',
        Cell: (params) => (
          <Link to={`/home/dashboard/SolicitudEgresoDetalle/${params.value}`}>
            {params.value}
          </Link>
        ),
      },
      // { Header: 'Centro_Origen', accessor: 'WERKS' },
      // { Header: 'Documento', accessor: 'BSART' },
      { Header: 'Orden', accessor: 'BANFN' },
      { Header: 'Centro_Destino', accessor: 'DED' },
      { Header: 'Almacén', accessor: 'BOD' },
      { Header: 'Tipo_Transaccion', accessor: 'MO' },
      { Header: 'Usuario', accessor: 'US' },
      { Header: 'Compañia', accessor: 'ciacodigo' },
      { Header: 'Localidad', accessor: 'loccodigo' },
      { Header: 'Código_Solicitud', accessor: 'sgasolegr' },
     
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: cabeceras,
      initialState: { pageIndex: 0, pageSize: 10 }, // Configura la paginación inicial a 20 elementos por página
    },
    usePagination
  );

  const theme = createTheme({
    palette: {
      primary: {
        main: "#196C87",
      },
      secondary: {
        main: "#196C87", // Cambia el color secundario a verde azulado
      },
    },
  });

  useEffect(() => {
    if (cabeceras.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [cabeceras]);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <Container fluid >
          <h3 className="text-center mt-3">Solicitud de Egreso</h3>
          <Button variant="contained" color="primary" onClick={handleOpenModal} style={{ float: 'right', margin: '10px' }}>Nueva Solicitud</Button>
          {openModal && <ModalNew openModal={openModal} onCloseModal={handleCloseModal} onSave={handleSave} />}
          {isLoading ? (<div style={{ textAlign: 'center' }}> <CircularProgress /></div>) 
          : 
          (<Table striped bordered hover responsive="sm" {...getTableProps()} className="mt-3">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()} style={{ backgroundColor: '#196C87', color: 'white', textAlign: 'center' }}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()} style={{ textAlign: 'center' }}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
             )}
            <div className="d-flex justify-content-center">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
              <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                {'>>'}
              </button>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{' '}
              </span>
              <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
                {[10, 20, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
        </Container>
      </div>
    </ThemeProvider>
  );
}

export default SolicitudEgreso;
