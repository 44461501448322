import React, { useState, useEffect, useContext } from "react";
import Header from "../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  ListItemText,
  List,
  ListItem,
} from "@mui/material";

import BackIcon from "../../components/BackIcon";

import fetchwrapper from "../../services/interceptors/fetchwrapper";
import { useLocation } from "react-router-dom";
import LoadingComponent from "../../components/loadingComponent";
import { GlobalContext } from "../../contexts/GlobalContext";
import moment from "moment";
import "moment/locale/es";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "rgb(37,211, 102,0.2)",
  cursor: "pointer",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const BcosDiasSaldosDetail = () => {
  const location = useLocation();

  const [bcoEstadoCtaSaldo, setBcoEstadoCtaSaldo] = useState([]);
  const [isLoadingBcoEstadoCtaSaldo, setisLoadingBcoEstadoCtaSaldo] =
    useState(false);

  const getDetailBcoEstadoCtaSaldo = async () => {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          bcocodigo: location?.state?.bcocodigo,
          max_tranfecha: location?.state?.max_tranfecha,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      setisLoadingBcoEstadoCtaSaldo(true);

      let response = await fetchwrapper(
        "/facturas_ventas/getBcoEstadoCtaSaldos",
        options
      );
      response = await response.json();

      setBcoEstadoCtaSaldo(response?.data);
    } catch (error) {
      alert("No se puedo obtener el estado de cuenta");
    } finally {
      setisLoadingBcoEstadoCtaSaldo(false);
    }
  };

  useEffect(() => {
    if (!location?.state?.bcocodigo || !location?.state?.max_tranfecha) return;

    getDetailBcoEstadoCtaSaldo();
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>{location.state.bcodescri}</b>
        </div>
        {isLoadingBcoEstadoCtaSaldo ? (
          <LoadingComponent />
        ) : (
          <Box className={StyledRoot}>
            <HistorialTransacciones transaccionesPorDia={bcoEstadoCtaSaldo} />
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

const HistorialTransacciones = ({ transaccionesPorDia }) => {
  const formatDate = (date) => {
    // Configura la localización en español
    moment.locale("es");
    // Fecha original
    const fechaOriginal = date;

    // Utiliza Moment.js para formatear el día de la semana y el día del mes
    const diaSemanaDia = moment(fechaOriginal).format("dddd D");

    // Utiliza Moment.js para formatear el mes y el año
    const mesAnio = moment(fechaOriginal).format("MMMM YYYY");

    return [diaSemanaDia, mesAnio];
  };

  const formatTranvalor = (tranvalor) => {
    if (tranvalor >= 0) {
      return `+$${tranvalor}`;
    } else {
      return `-$${tranvalor * -1}`;
    }
  };

  return (
    <Container>
      {Object.keys(transaccionesPorDia).map((fecha) => (
        <div key={fecha}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textTransform: "capitalize",
              fontWeight: "bolder",
              padding: "10px 10px",
              backgroundColor: "rgba(78,212,222,0.4)",
              borderRadius: "10px",
            }}
          >
            {formatDate(fecha).map((str) => (
              <div>{str}</div>
            ))}
          </Typography>
          {transaccionesPorDia[fecha].map((transaccion, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{ borderRadius: "20px", marginBottom: "10px" }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {transaccion.Registro}
                    </Typography>
                    <Typography variant="subtitle1">
                      {transaccion.trandescri}
                    </Typography>
                    <Typography variant="subtitle1">
                      {transaccion.tranumbco}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" align="right">
                      <strong>{formatTranvalor(transaccion.tranvalor)}</strong>
                    </Typography>
                    <Typography variant="subtitle1" align="right">
                      Saldo Actual: ${transaccion.SaldoActualBco}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </div>
      ))}
    </Container>
  );
};

export default BcosDiasSaldosDetail;
