function fetchwrapper(url, options) {
  const API_URL = "https://siac.fsbsec.com:6050"
  //const API_URL = "https://fsoftapptest.futuresoft-ec.com:6050"
  // const API_URL = "http://127.0.0.1:5000";
  const token = localStorage.getItem("accessToken");
  if (token) {
    if (!options) {
      options = {};
    }
    if (!options.headers) {
      options.headers = {};
    }
    options.headers.Authorization = `Bearer ${JSON.parse(token)}`;
  }
  return fetch(API_URL + url, options).then((response) => {
    if (!response.ok) {
      // Si el código de estado no está en el rango 200-299
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
  });
}
export default fetchwrapper;
