import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
  Checkbox,
  Button,
} from "@mui/material";

import { MultiSelect } from "react-multi-select-component";
import "./styles.css";

// import MultiSelect from "../../../components/Multiselect";

import BackIcon from "../../../components/BackIcon";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import EjecutarAccionIcon from "../../../assets/iconos/EjecutarAccion.ico";
import { useNavigate } from "react-router-dom";
import Obturador from "../../../components/Obturador/Obturador";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const CrearPaletizacion = () => {
  const navigate = useNavigate();

  const [isSelectedBodega, setIsSelectedBodega] = useState(false);

  // bodegas
  const [openCBBodegas, setOpenCBBodegas] = useState(false);
  const [allBodegas, setAllBodegas] = useState([]);
  const [selectedBodega, setSelectedBodega] = useState(null);
  const [isLoadingAllBodegas, setIsLoadingAllBodegas] = useState(false);

  // bahias
  const [allBahias, setAllBahias] = useState([]);
  const [selectedBahias, setSelectedBahias] = useState([]);
  const [isLoadingAllBahias, setIsLoadingAllBahias] = useState(false);

  // pasillos
  const [allPasillos, setAllPasillos] = useState([]);
  const [selectedPasillos, setSelectedPasillos] = useState([]);
  const [isLoadingAllPasillos, setIsLoadingAllPasillos] = useState(false);

  // niveles
  const [allNiveles, setAllNiveles] = useState([]);
  const [selectedNiveles, setSelectedNiveles] = useState([]);
  const [isLoadingAllNiveles, setIsLoadingAllNiveles] = useState(false);

  const getAllBodegas = async () => {
    try {
      setIsLoadingAllBodegas(true);
      let allBodegas = await fetchwrapper("/paletizacion/getAllBodegas");
      allBodegas = await allBodegas.json();

      setAllBodegas(allBodegas.data);

      console.log(allBodegas.data);
    } catch {
      alert("No se encontraron bodegas");
    } finally {
      setIsLoadingAllBodegas(false);
    }
  };

  const getAllBahias = async () => {
    try {
      setIsLoadingAllBahias(true);
      let allBahias = await fetchwrapper(
        `/paletizacion/getAllBahias/${selectedBodega?.bodcodigo}`
      );
      allBahias = await allBahias.json();

      setAllBahias(allBahias.data);

      console.log(allBahias);
    } catch {
      alert("No se encontraron bahias");
    } finally {
      setIsLoadingAllBahias(false);
    }
  };

  const getAllPasillos = async () => {
    try {
      setIsLoadingAllPasillos(true);
      let allPasillos = await fetchwrapper(
        `/paletizacion/getAllPasillos/${selectedBodega?.bodcodigo}`
      );
      allPasillos = await allPasillos.json();

      setAllPasillos(allPasillos.data);

      console.log(allPasillos);
    } catch {
      alert("No se encontraron pasillos");
    } finally {
      setIsLoadingAllPasillos(false);
    }
  };

  const getAllNiveles = async () => {
    try {
      setIsLoadingAllNiveles(true);
      let allNiveles = await fetchwrapper(
        `/paletizacion/getAllNiveles/${selectedBodega?.bodcodigo}`
      );
      allNiveles = await allNiveles.json();

      setAllNiveles(allNiveles.data);

      console.log(allNiveles);
    } catch {
      alert("No se encontraron niveles");
    } finally {
      setIsLoadingAllNiveles(false);
    }
  };

  useEffect(() => {
    getAllBodegas();
  }, []);

  useEffect(() => {
    cleanFiltros();
    if (!isSelectedBodega) return;
    getAllBahias();
    getAllPasillos();
    getAllNiveles();
  }, [selectedBodega?.bodcodigo]);

  const cleanFiltros = () => {
    setSelectedBahias([]);
    setAllBahias([]);

    setSelectedPasillos([]);
    setAllPasillos([]);

    setSelectedNiveles([]);
    setAllNiveles([]);
  };

  const textIsAlreadyDecoded = (infoRead) => {
    const [bahia, pasillo, nivel, posicion] =
      infoRead.barcodeDataText.split(";");
    // const [bahia, pasillo, nivel] = "PP-05-01!02!01".split("!");

    if (bahia && pasillo && nivel) {
      const data = {
        type: "barcodeUbicacion",
        bahia: bahia,
        pasillo: pasillo,
        nivel: nivel,
        posicion: posicion,
      };

      navigate("Ejecutar", { state: data });
    } else {
      throw new Error("");
    }
  };

  const ejecutarBusqueda = () => {
    if (!selectedBodega) return alert("Seleccione una bodega");
    const data = {
      bodega: selectedBodega,
      bahias: selectedBahias,
      pasillos: selectedPasillos,
      niveles: selectedNiveles,
    };

    navigate("Ejecutar", { state: data });
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Paletizacion</b>
        </div>

        <Box className={StyledRoot}>
          <Grid container spacing={2} sx={{ justifyContent: "center" }}>
            <Grid item xs={12} md={10}>
              <div style={{ marginBottom: "25px" }}>
                <p style={{ display: "inline" }}>Ubicacion:</p>
                <Obturador
                  scannerParams={{
                    msg: "Escanea el código de barras",
                    codeType: "bar",
                  }}
                  executeFunction={textIsAlreadyDecoded}
                />
              </div>
              {/* Bodegas */}
              <Autocomplete
                fullWidth
                sx={{ marginBottom: "30px" }}
                value={selectedBodega}
                key={(option) => option.bodcodigo}
                id="asynchronous-demo"
                open={openCBBodegas}
                onOpen={() => {
                  setOpenCBBodegas(true);
                }}
                onClose={() => {
                  setOpenCBBodegas(false);
                }}
                onChange={(event, newValue) => {
                  setIsSelectedBodega(newValue?.bodcodigo ? true : false);
                  setSelectedBodega(newValue);
                }}
                getOptionLabel={(option) => option.boddescri}
                options={allBodegas}
                loading={isLoadingAllBodegas}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bodegas*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAllBodegas ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <div style={{ position: "relative" }}>
                <div
                  style={
                    !selectedBodega
                      ? {
                          zIndex: 999,
                          position: "absolute", // o "absolute" según tus necesidades
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(169, 169, 169, 0.5)", // Gris con opacidad
                          cursor: "not-allowed", // Cursor bloqueado
                          pointerEvents: "auto",
                        }
                      : {}
                  }
                ></div>

                <MultiSelect
                  className="multiselect"
                  disabled={!isSelectedBodega}
                  options={allBahias}
                  value={selectedBahias}
                  isLoading={isLoadingAllBahias}
                  onChange={setSelectedBahias}
                  labelledBy="Select"
                  overrideStrings={{
                    selectAll: "Seleccionar todo",
                    selectSomeItems: "Selecciona las bahias",
                    allItemsAreSelected: "Todas los items fueron seleccionados",
                  }}
                />

                <MultiSelect
                  className="multiselect"
                  disabled={!isSelectedBodega}
                  options={allPasillos}
                  value={selectedPasillos}
                  isLoading={isLoadingAllPasillos}
                  onChange={setSelectedPasillos}
                  labelledBy="Select"
                  overrideStrings={{
                    selectAll: "Seleccionar todo",
                    selectSomeItems: "Selecciona las pasillos",
                    allItemsAreSelected: "Todas los items fueron seleccionados",
                  }}
                />

                <MultiSelect
                  className="multiselect"
                  disabled={!isSelectedBodega}
                  options={allNiveles}
                  value={selectedNiveles}
                  isLoading={isLoadingAllNiveles}
                  onChange={setSelectedNiveles}
                  labelledBy="Select"
                  overrideStrings={{
                    selectAll: "Seleccionar todo",
                    selectSomeItems: "Selecciona las niveles",
                    allItemsAreSelected: "Todas los items fueron seleccionados",
                  }}
                />
              </div>

              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={ejecutarBusqueda}
                >
                  Ejecutar Búsqueda
                  <span style={{ marginLeft: "8px" }}>
                    <img
                      src={EjecutarAccionIcon}
                      alt="ejecutar"
                      style={{ width: "40px" }}
                    />
                  </span>
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default CrearPaletizacion;

// Bahias
//             <Autocomplete
//               disabled={!isSelectedBodega}
//               value={selectedBahias}
//               onChange={(event, newValue) => {
//                 setSelectedBahias(newValue.map((option) => option));
//               }}
//               multiple
//               id="checkboxes-tags-demo"
//               options={allBahias}
//               disableCloseOnSelect
//               getOptionLabel={(option) => option?.bahdescripcion}
//               renderOption={(props, option, { selected }) => (
//                 <li {...props} key={option.bahcodigo}>
//                   <Checkbox
//                     icon={icon}
//                     checkedIcon={checkedIcon}
//                     style={{ marginRight: 8 }}
//                     checked={selected}
//                   />
//                   {option.bahdescripcion}
//                 </li>
//               )}
//               style={{ width: 500 }}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Bahías"
//                   InputProps={{
//                     ...params.InputProps,
//                     endAdornment: (
//                       <React.Fragment>
//                         {isLoadingAllBahias ? (
//                           <CircularProgress color="inherit" size={20} />
//                         ) : null}
//                         {params.InputProps.endAdornment}
//                       </React.Fragment>
//                     ),
//                   }}
//                 />
//               )}
//             />
