import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const CustomBackdrop = ({ isLoading, zIndex = 0 }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: zIndex || ((theme) => theme.zIndex.drawer + 1),
      }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default CustomBackdrop;
