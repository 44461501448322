import React, { useEffect, useState } from "react";
import Header from "../../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, TextField, Button } from "@mui/material";

import BackIcon from "../../../../../components/BackIcon";

import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../../../components/CustomBackdrop";
import ProductsInventarioReadable from "../../../components/ProductsInventarioReadable";
import ZebraPrinter from "../../../../utils/zebraPrinter";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const styles = {
  accordionSummary: {
    backgroundColor: "rgb(25, 108, 135)",
    color: "white",
    fontWeight: "bolder",
  },
  row: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    paddingBlock: "10px",
  },
  rowTitle: {
    alignSelf: "end",
    paddingRight: "20px",
    fontWeight: "bolder",
  },
};

const DisabledTextField = ({ label = "", value }) => {
  return (
    <span style={{ flexGrow: 1 }}>
      <TextField
        fullWidth
        id={`outlined`}
        variant="standard"
        label={label}
        value={value ?? ""}
        disabled
      />
    </span>
  );
};

const BuscarTodosArticulosIngresoAsignacionUbicacion = () => {
  const location = useLocation();
  const navigate = useNavigate();

  //-----------------------------------------------------

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    error: isFetchingProductosError,
  } = useGetPProductos();

  //READ hook (get InProductos from api)
  function useGetPProductos() {
    return useQuery({
      queryKey: ["ProductosAsignacionUbicacionReadableBuscar"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/cuarentena/getAllIngresosPalletsDetalle/${location.state?.cabecera?.tranumero}` //Esta es la misma api que se usa en cuarentena para traer todos los articulos asociados a un ingreso
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }
  const {
    data: fetchedUbicacionDestino = {},
    isFetching: isFetchingUbicacionDestino,
    isLoading: isLoadingUbicacionDestino,
  } = useGetPUbicacionDestino();

  //READ hook (get InProductos from api)
  function useGetPUbicacionDestino() {
    return useQuery({
      queryKey: ["UbicacionDestinoBuscado"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getUbicacionDestinoBuscar/${location.state?.cabecera?.tranumero}` //Esta es la misma api que se usa en cuarentena para traer todos los articulos asociados a un ingreso
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
      onerror: () => {
        alert(
          "No se pudo obtener la ubicación destino de este ingreso. Intente con recargar esta página otra vez"
        );
      },
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>ARTICULOS ASOCIADOS AL INGRESO</b>
        </div>
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Solicitud:</strong>{" "}
            {location.state.cabecera.sgasoling}
          </p>
          <p>
            <strong>Código de Ingreso:</strong>{" "}
            {location.state?.cabecera?.tranumero}
          </p>
          <p>
            <strong>Código de Palet:</strong>{" "}
            {location.state?.cabecera?.palletid}
          </p>
          <p>
            <strong>Observación:</strong>{" "}
            {location.state?.cabecera?.tradescripcion}
          </p>
          <br />
          <br />
        </div>
        <CustomBackdrop
          isLoading={
            isLoadingProductos ||
            isFetchingProductos ||
            isLoadingUbicacionDestino ||
            isFetchingUbicacionDestino
          }
        />

        {isLoadingProductosError || isFetchingProductosError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Bahía de Ingreso:</span>
              <DisabledTextField value={fetchedUbicacionDestino?.bahcodigo} />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Descripción Bahía de Ingreso:</span>
              <DisabledTextField
                value={fetchedUbicacionDestino?.bahdescripcion}
              />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Posición de Ingreso:</span>
              <DisabledTextField value={fetchedUbicacionDestino?.poscodigo} />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>
                Descripción Posición de Ingreso:
              </span>
              <DisabledTextField
                value={fetchedUbicacionDestino?.posdescripcion}
              />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Código Nivel de Ingreso:</span>
              <DisabledTextField value={fetchedUbicacionDestino?.nivcodigo} />
            </div>
            <div style={styles.row}>
              <span style={styles.rowTitle}>Descripción Nivel de Ingreso:</span>
              <DisabledTextField
                value={fetchedUbicacionDestino?.nivdescripcion}
              />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Zona de Ingreso:</span>
              <DisabledTextField value={fetchedUbicacionDestino?.izoncodigo} />
            </div>

            <div style={styles.row}>
              <span style={styles.rowTitle}>Pasillo de Ingreso:</span>
              <DisabledTextField value={fetchedUbicacionDestino?.pascodigo} />
            </div>
            <br />
            <br />
            <ProductsInventarioReadable
              productsCuarentena={fetchedProductos?.productosIngreso}
            />
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default BuscarTodosArticulosIngresoAsignacionUbicacion;
