import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper";
import Chip from '@mui/material/Chip';

const API_URL_FILTRAR = "/filter/";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function InputFiltrarProductos({ label, placeholder, handleChange }) {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0)

  const getOptionLabel = (option) => {
    if (option.artcodigo === "seleccionar-todos") {
      return option['artdescri'];
    } else {
      return option.artcodigo + " - " + option.artdescri;
    }
  };

  useEffect(() => {
    const getOptions = async () => {
      const cliciaciacodigo = JSON.parse(localStorage.getItem("jwt")).seleccion.cliciaciacodigo;

      try {
        const request = {
          seleccion: {
            cliciaciacodigo: cliciaciacodigo,
          },
          localidad: {
            loccodigo: "07",
            locdescri: "BODEGA SAMBO",
          },
        };
        const options = {
          method: "POST",
          body: JSON.stringify(request),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        let response = await fetchwrapper(API_URL_FILTRAR + "get_producto", options);
        response = await response.json();
        response = response.map((item) => {
          return {
            artcodigo: item.artcodigo,
            artdescri: item.artdescri,
          };
        });
        setOptions(response);
      } catch (err) {
        console.error("error");
      }
    };
    getOptions();
  }, []);

  const filterOptions = createFilterOptions({
    limit: 10,
    matchFrom: "any",
    stringify: (option) => option.artcodigo + " " + option.artdescri,
  });

  const handleSelectOption = (event, newValue) => {
    // if select all is selected, choose all the options
    if (newValue === null) {
      setSelectAll(false);
      setSelectedValues([]);
      handleChange([]);
      return;
    }
    //check if first value or last value is select all
    //do this only if not empty newValue
    if (newValue.length === 0) {
      setSelectAll(false);
      setSelectedValues([]);
      handleChange([]);
      return;
    }
    setSelectAll(false);
    if (newValue.some((option) => option.artcodigo === "seleccionar-todos")) {
      //if the input is empty and select all is unchecked, select all
      if (inputValue.length === 0 && !selectAll) {
        setSelectAll(true);
        setCounter(options.length)
        //select only first 10 options
        setSelectedValues(options.slice(0, 10));
        handleChange(options.map((option) => option.artcodigo));
        return;
      }
      setSelectAll(true);
      setSelectedValues(filteredOptions);
      handleChange(filteredOptions.map((option) => option.artcodigo));
      //if select all is checked, unchecked it
      if (selectAll) {
        setSelectAll(false);
        setSelectedValues([]);
        handleChange([]);
        return;
      }
      return;

    }
    //if select all is not selected, choose only the options that are selected
    const newValues = newValue.filter((option) => {
      return option.artcodigo !== "seleccionar-todos";
    });
    setSelectedValues(newValues);
    if (counter <=10) {
      setCounter(newValue.length);
    }
    else{
      newValue.length <= values || values===0 ? setCounter(counter-1) : setCounter(counter+1)
      setValues(newValue.length)
    }
    handleChange(newValues.map((option) => option.artcodigo));
  };

  const addSelectAllOptions = (options) => {
    //first verify that there are options
    if (options) {
      //this only if there are options
      if (options.length === 0) {
        return [];
      }
      return [
        {
          artcodigo: "seleccionar-todos",
          artdescri: "Seleccionar todos",
        },
        ...options,
      ];
    }
  };

  const handleCheckboxChange = (selected) => {
    selected ? setCounter(counter-1) : setCounter(counter+1)
  };

  const filterAndAddSelectAllOptions = (options, params) => {
    const filtered = filterOptions(options, params);
    const optionsWithSelectAll = addSelectAllOptions(filtered);
    return optionsWithSelectAll;
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // filter the options based on the user's input
    if (newInputValue.length === 0) {
      setFilteredOptions(options);
      setCounter(0)
      return;
    }
    if (!newInputValue) {
      setFilteredOptions(options);
      return;
    }
    const recommendedOptions = options.filter((option) => {
      return option['artdescri'].toLowerCase().includes(newInputValue.toLowerCase());
    });

    // set the filtered options as the autocomplete suggestions
    setFilteredOptions(recommendedOptions);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <div>
        {/* <div>{`value: ${value !== null ? `'${value.artcodigo}'` : "null"}`}</div>
        <div>{`inputValue: '${inputValue}'`}</div>
        <br /> */}
        <Autocomplete
          multiple
          size="small"
          value={selectedValues}
          disableCloseOnSelect
          onChange={handleSelectOption}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          id="controllable-states-demo"
          options={options}
          getOptionLabel={getOptionLabel}
          sx={{maxWidth:"75vw"}}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder={placeholder} />
          )}
          renderOption={(props, option, { selected }) => {
            const isSelectAllOption = option.artcodigo === "seleccionar-todos";
            return (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected || (isSelectAllOption && selectAll)}
                  onChange={() => handleCheckboxChange(selected)}
                />
                {option['artdescri']}
              </li>
            );
          }}
          ListboxProps={{ style: { maxHeight: '200px', overflowY: 'auto' } }}
          isOptionEqualToValue={(option, value) =>
            value !== null && option.artcodigo === value.artcodigo
          }
          filterOptions={
            filterAndAddSelectAllOptions
          }
          renderTags={(value, getTagProps) => {
            const maxDisplayedTags = 3;
            const selectedTags = value.slice(0, maxDisplayedTags);
            const hiddenTagsCount = value.length - selectedTags.length;
        
            return (
              <>
              <div>
                {selectedTags.map((option, index) => (
                  <Chip
                    key={index}
                    label={option.artdescri}
                    style={{ margin: '4px', fontSize: '11px', padding: '4px', maxWidth: '75%' }}
                    {...getTagProps({ index })}
                  />
                ))}
              </div>
              <div style={{marginLeft: '6px'}}>
                {hiddenTagsCount > 0 && <span>+{hiddenTagsCount} más</span>}
              </div>
              </>            
            );
          }}
        />
        {selectedValues.length > 0 && (
          <p>Elementos seleccionados: {counter}</p>
        )}
      </div>
    </Grid>
  );
}

export default InputFiltrarProductos;
