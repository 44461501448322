import * as React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Grid } from '@mui/material';

function DatePickerValue({ label, setDate }) {
  const [value, setValue] = React.useState(dayjs());

  const handleDateChange = (newValue) => {
    const formattedValue = dayjs(newValue)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    
    setValue(formattedValue);
    console.log(formattedValue.format("YYYY-MM-DD HH:mm:ss.SSS"));
    setDate(formattedValue.format("YYYY-MM-DD HH:mm:ss.SSS"));
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={value}
            onChange={handleDateChange}
            sx={{ width: '100%' }}
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}




export default DatePickerValue;