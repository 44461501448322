import { useNavigate } from "react-router-dom"

const useCleanSession = ()=>{
    const navigate = useNavigate()
    const cleanSession = () =>{
        localStorage.clear()
        navigate("/")

    }
    return cleanSession

}

export default useCleanSession