import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

import GrabarIcon from "../../../assets/iconos/Grabar.ico";
import truncateNumber from "../../utils/math/truncate";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../components/CustomBackdrop";

import { useNavigate } from "react-router-dom";
import CustomInputNumber from "../../../components/CustomInputNumber";

const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}));

const columnas = [
  {
    headerName: "Cantidad Etiquetas",
    field: "cantidadEtiquetas",
    sort: "asc",
    width: 100,
    type: "number",
    editable: true,
    valueGetter: (params) => {
      const value = Math.floor(params.value);
      return value > 0 ? value : 1;
    },
    valueFormatter: (params) => {
      const value = Math.floor(params.value);
      return value > 0 ? value : 1; // Puedes devolver una cadena vacía si prefieres que 0 aparezca como vacío
    },
  },
  {
    headerName: "Bahia Código",
    field: "bahcodigo",
    width: 150,
  },
  {
    headerName: "Bahía",
    field: "bahdescripcion",
    width: 200,
  },
  {
    headerName: "Nivel Código",
    field: "nivcodigo",
    width: 150,
  },
  {
    headerName: "Nivel",
    field: "nivdescripcion",
    width: 200,
  },
  {
    headerName: "Posición Código",
    field: "poscodigo",
    width: 150,
  },
  {
    headerName: "Posición",
    field: "posdescripcion",
    width: 200,
  },
  {
    headerName: "Artículo Código",
    field: "artcodigo",
    width: 200,
  },
  {
    headerName: "Artículo Descripción",
    field: "artdescri",
    width: 500,
  },
  {
    headerName: "Stock De Cantidad",
    field: "stokdetcantidad",
    width: 200,
    valueFormatter: (params) => {
      return truncateNumber(params.value);
    },
  },
  {
    headerName: "Zona Código",
    field: "izoncodigo",
    width: 150,
  },
  {
    headerName: "Zona",
    field: "izondescripcion",
    width: 200,
  },
  {
    headerName: "Bodega Código",
    field: "bodcodigo",
    width: 200,
  },
  {
    headerName: "Bodega",
    field: "boddescri",
    width: 200,
  },
  {
    headerName: "Pasillo Código",
    field: "pascodigo",
    width: 150,
  },
  {
    headerName: "Pasillo",
    field: "pasdescripcion",
    width: 200,
  },
  {
    headerName: "Embalaje Código",
    field: "embcodigo",
    width: 150,
  },
  {
    headerName: "Embalaje",
    field: "embdescripcion",
    width: 200,
  },
  {
    headerName: "Tonalidad Código",
    field: "toncodigo",
    width: 150,
  },
  {
    headerName: "Tonalidad",
    field: "tondescripcion",
    width: 200,
  },
];

const Info = ({ fechaHoraISO, palletidGenerated, ipPrinter }) => {
  const formatearFecha = (fechaISO) => {
    const fechaFormateada = new Date(fechaISO).toLocaleString("es-EC", {
      timeZone: "America/Guayaquil",
    });
    return fechaFormateada;
  };
  return (
    <div
      style={{ textAlign: "center", fontWeight: "bolder", fontSize: "17px" }}
    >
      <p>Pallet ID: {palletidGenerated}</p>
      <p>Fecha y Hora Actual: {formatearFecha(fechaHoraISO)}</p>
      <p>Usuario: {localStorage.getItem("cliciausu")}</p>
      <p>Impresora IP: {ipPrinter}</p>
    </div>
  );
};

const DatagridEjecutar = ({ data, setData, filtrarBusqueda }) => {
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState([]); //Solo guarda los ids de las filas seleccionada

  const [fechaHoraISO, setFechaHoraISO] = useState(new Date().toISOString());

  const [isLoadingPaletizarProcesos, setIsLoadingPaletizarProcesos] =
    useState(false);

  const [palletidGenerated, setPalletidGenerated] = useState("");
  const [isLoadingPalletidGenerated, setisLoadingPalletidGenerated] =
    useState(false);

  const palletIdUpdatedBD = useRef("");

  const [ipPrinter, setIpPrinter] = useState("");
  const [isLoadingIpPrinter, setIsLoadingIpPrinter] = useState(false);

  const [cantidadEtiquetasPallet, setCantidadEtiquetasPallet] = useState(0);

  const generatePalletId = async () => {
    try {
      setisLoadingPalletidGenerated(true);
      let response = await fetchwrapper("/paletizacion/generatePalletid");
      response = await response.json();

      setPalletidGenerated(response.palletIdGenerated);
    } catch {
      alert("No se pudo generar el numero de pallet correctamente");
      navigate(-1);
    } finally {
      setisLoadingPalletidGenerated(false);
    }
  };

  const getIpPrinter = async () => {
    try {
      setIsLoadingIpPrinter(true);
      let options = {
        method: "POST",
        body: JSON.stringify({
          printproceso: "ZEBRA",
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let response = await fetchwrapper("/externos/get_impresoras", options);
      response = await response.json();
      if (response.length === 0) throw new Error();

      setIpPrinter(response[0]?.printip);
    } catch {
      alert("No se pudo obtener la ip de la impresora");
      navigate(-1);
    } finally {
      setIsLoadingIpPrinter(false);
    }
  };

  useEffect(() => {
    generatePalletId();
    getIpPrinter();
  }, []);

  //Actualiza el objeto data que contiene todos los articulos con el nuevo valor de cantidadEtiquetas de la fila seleccionada
  const handleCantidadEtiquetasChange = (params) => {
    // Busca el artículo en el arreglo de datos y actualiza la propiedad "cantidadEtiquetas"
    const articulosActualizados = data.map((articulo) => {
      if (
        params.izoncodigo === articulo.izoncodigo &&
        params.bahcodigo === articulo.bahcodigo &&
        params.pascodigo === articulo.pascodigo &&
        params.nivcodigo === articulo.nivcodigo &&
        params.poscodigo === articulo.poscodigo &&
        params.embcodigo === articulo.embcodigo &&
        params.toncodigo === articulo.toncodigo &&
        params.artcodigo === articulo.artcodigo
      ) {
        return { ...articulo, cantidadEtiquetas: params.cantidadEtiquetas };
      }
      return articulo;
    });
    setData(articulosActualizados);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    handleCantidadEtiquetasChange(updatedRow);

    return updatedRow;
  };

  //Obtiene toda la info de las filas seleccionadas, usando el arreglo de ids guardados en rowSelectionModel
  const getAllRowsSelected = () => {
    const listaArticulos = data.filter((articulo) =>
      rowSelectionModel.includes(
        `${articulo.izoncodigo}|${articulo.bahcodigo}|${articulo.pascodigo}|${articulo.nivcodigo}|${articulo.poscodigo}|${articulo.embcodigo}|${articulo.toncodigo}|${articulo.artcodigo}`
      )
    );

    console.log(listaArticulos);

    return listaArticulos;
  };

  // -----------------------------------------------------------------
  //                 PRINTING PROCESS
  // ----------------------------------------------------------------
  function getLocalDevicesAsync(BrowserPrint) {
    return new Promise((resolve, reject) => {
      BrowserPrint.getLocalDevices(
        function (deviceList) {
          resolve(deviceList);
        },
        function () {
          reject(
            new Error("Error al obtener la lista de dispositivos locales")
          );
        },
        "printer"
      );
    });
  }
  async function setup(BrowserPrint) {
    try {
      const deviceList = await getLocalDevicesAsync(BrowserPrint);
      return deviceList;
    } catch (error) {
      // setIsLoadingPrinter(false);
      throw new Error("No se pudo obtener las impresoras en red");
    }
  }

  const getCurrentPrinterDevice = (allPrintersDevices, ipPrinter) => {
    const selectedPrint = allPrintersDevices.find((device) => {
      return ipPrinter.includes(device.uid.split(":")[0]);
    });

    return selectedPrint;
  };
  const [isLoadingPrinter, setIsLoadingPrinter] = useState(false);

  // async function printLabels(printerDeviceSelected, labels) {
  //   let errorOccurred = false;

  //   // function handleSuccess() {
  //   //   // Éxito: la etiqueta se imprimió correctamente
  //   // }

  //   function handleError(errorMessage) {
  //     // Error: la etiqueta no se pudo imprimir
  //     errorOccurred = true;
  //     console.error("Error al imprimir una etiqueta");
  //   }

  //   async function sendLabelAsync(printerDeviceSelected, label) {
  //     return new Promise((resolve) => {
  //       printerDeviceSelected.send(label, resolve, (errorMessage) => {
  //         handleError(errorMessage);
  //         resolve(); // Continuar con la impresión de las etiquetas restantes
  //       });
  //     });
  //   }

  // // core action
  // for (const label of labels) {
  //   try {
  //     await sendLabelAsync(printerDeviceSelected, label);
  //     // handleSuccess();
  //   } catch (err) {
  //     // Capturar cualquier error inesperado
  //     console.error("Error inesperado:", err);
  //     errorOccurred = true;
  //   }
  // }

  //   if (errorOccurred) {
  //     // Al menos una etiqueta no se imprimió correctamente
  //     throw new Error("Se produjo un error al imprimir algunas etiquetas");
  //   } else {
  //     // Todas las etiquetas se imprimieron correctamente
  //     alert("Etiquetas impresas");
  //   }
  // }
  async function printLabels(printerDeviceSelected, labels) {
    // Printing all labels
    try {
      labels.forEach((label) => {
        printerDeviceSelected.send(
          label,
          () => {
            const lastLabel = labels[labels.length - 1];
            if (label === lastLabel) {
              //alert("Etiquetas impresas");
              // setIsPrintingLabels(false); //Last label printed
            }
          },
          (errorMessage) => {
            alert("Error: " + errorMessage);
          }
        );
      });
      alert(labels.length + " Etiquetas impresas");
    } catch (err) {
      alert("No se pudo conectar a la impresora");
      // setIsPrintingLabels(false)
    }
  }

  // Llamada a la función

  async function writeToSelectedPrinter(printerDeviceSelected, products) {
    let temporalProductsToPrint = products.data; //list of productos
    let temporalPalletsToPrint = products.data; //list of pallets
    let numPallet = products.numPallet;
    console.log(temporalPalletsToPrint, temporalProductsToPrint, "impres");
    let labels = [];

    const numeroRamndom = 666666;
    const fraseRamdom = "estoy";

    //PRODUCTS LABELS ----------------
    temporalProductsToPrint.forEach((producto) => {
      const numEtiquetas = producto?.cantidadEtiquetas;
      const labelsPerProducto = [];
      const {
        ciacodigo,
        loccodigo,
        usrcodigo,
        invcodigo,
        bodcodigo,
        boddescri,
        palletid,
        artcodigo,
        artdescri,
        artlote,
        artserie,
        artservicio,
        artdecimal,
        artregissani,
        artobserva,
        arttemperatura,
        artconcentra,
        lincodigo,
        lindescri,
        precodigo,
        predescri,
        marcodigo,
        mardescri,
        medcodigo,
        meddescri,
        pascodigo,
        pasdescripcion,
        bahcodigo,
        bahdescripcion,
        poscodigo,
        posdescripcion,
        nivcodigo,
        nivdescripcion,
        embcodigo,
        embdescripcion,
        toncodigo,
        tondescripcion,
        izoncodigo,
        izondescripcion,
        stokdetcantidad,
        stokdetcantconver,
        artcodbarra,
        cantidadEtiquetas,
      } = producto;

      for (let i = 0; i < numEtiquetas; i++) {
        let zplString = `^XA
        ^BY4,3,67^FT25,85^BCN,,N,N
        ^FD>;${artcodigo}^FS
        ^FT664,83^A0N,25,24^FH\\^FD17/10/2023^FS
        ^FT485,83^A0N,25,24^FH\\^FDFECHA DE ING.:^FS
        ^FT662,48^A0N,28,28^FH\\^FD00000001^FS
        ^FT28,421^A0N,25,24^FH\\^FD${artobserva}^FS
        ^FT350,48^A0N,28,28^FH\\^FDORDEN DE INGRESO N\\F8^FS
        ^FT402,180^A0N,17,16^FH\\^FDPRESENTACION^FS
        ^FT23,180^A0N,17,16^FH\\^FD${artconcentra}^FS
        ^FT402,157^A0N,17,16^FH\\^FDPRINCIPIO ACTIVO^FS
        ^FT23,157^A0N,17,16^FH\\^FDNOMBRE COMERCIAL:^FS
        ^FT225,134^A0N,17,16^FH\\^FDCODIGO PROV.:^FS
        ^FT370,134^A0N,17,16^FH\\^FD684105^FS
        ^FT405,371^A0N,17,16^FH\\^FDCOMERCIALIZADOR:^FS
        ^FT27,369^A0N,17,16^FH\\^FDFABRICANTE:^FS
        ^FT332,345^A0N,17,16^FH\\^FDVALIDEZ REG.SAN.:^FS
        ^FT27,345^A0N,17,16^FH\\^FDREG. SAN.:^FS
        ^FT551,371^A0N,17,16^FH\\^FDNOMBRE DEL^FS
        ^FT551,392^A0N,17,16^FH\\^FDCOMERCIALIZADOR^FS
        ^FT516,322^A0N,17,16^FH\\^FDFECHA VENC.:^FS
        ^FT128,369^A0N,17,16^FH\\^FD${boddescri}^FS
        ^FT477,345^A0N,17,16^FH\\^FD17/10/2026^FS
        ^FT131,345^A0N,17,16^FH\\^FD${artregissani}^FS
        ^FT271,322^A0N,17,16^FH\\^FDFECHA ELAB.:^FS
        ^FT620,322^A0N,17,16^FH\\^FD17/10/2024^FS
        ^FT375,322^A0N,17,16^FH\\^FD17/10/2022^FS
        ^FT28,322^A0N,17,16^FH\\^FDLOTE:^FS
        ^FT24,134^A0N,17,16^FH\\^FDCOD. PROD.:^FS
        ^FT87,322^A0N,17,16^FH\\^FD${artlote}^FS
        ^FT147,134^A0N,17,16^FH\\^FD${artcodigo}^FS
        ^FT194,110^A0N,20,19^FH\\^FD123456^FS
        ^FT24,110^A0N,20,19^FH\\^FDORDEN DE PEDIDO:^FS`;

        // Comprobar si es producto europeo ya que ellos usan ian13 y gs1128
        if (artcodbarra?.length === 13) {
          zplString += `^BY3,2,90^FT265,281^BEN,,Y,N
          ^FD${artcodbarra}^FS
          ^FT141,588^A0N,28,28^FH\\^FD(01)${artcodbarra}(17)241017(10)${artcodigo}^FS
          ^BY3,3,121^FT59,558^BCN,,N,N
          ^FD>;>801178654219113551724101710516468^FS`;
        } else {
          //completar con upc con productos americanos
        }

        zplString += `^FT794,390^A0B,17,16^FH\\^FD${arttemperatura}^FS
        ^FT794,566^A0B,17,16^FH\\^FDCOND. DE ALMACENAJE:^FS
        ^PQ1,0,1,Y^XZ`;
        zplString = zplString.replace(/\s+/g, "");

        labelsPerProducto.push(zplString);
      }
      labels.push(...labelsPerProducto);
    });

    //PALLETS LABELS ----------------
    const numEtiquetas = cantidadEtiquetasPallet;
    const labelsPerPallet = [];

    for (let i = 0; i < numEtiquetas; i++) {
      let zplString = `^XA
        ^FT412,70^A0N,28,28^FH\\^FDN\\A7 INGRESO:^FS
        ^FT602,70^A0N,28,28^FH\\^FD${numeroRamndom}^FS
        ^FT23,113^A0N,23,24^FH\\^FDFECHA DE INGRESO: 16/10/2023^FS
        ^FT23,149^A0N,23,24^FH\\^FDN\\A7 ORDENPEDIDO: 38945098^FS
        ^FT23,186^A0N,23,24^FH\\^FDCLIENTE: ${"DETERMINARRR"}^FS
        ^FT26,260^A0N,28,28^FH\\^FDN\\A7 PALLET:^FS
        ^BY6,3,212^FT110,487^BCN,,Y,Y^FD>;${numPallet}^FS
        ^FT539,559^A0N,39,38^FH\\^FDULM: 1/10^FS
        ^PQ1,0,1,Y^XZ`;
      zplString = zplString.replace(/\s+/g, "");

      labelsPerPallet.push(zplString);
    }
    labels.push(...labelsPerPallet);
    console.log(labels, "impresionnn");

    //Aqui se tiene que obtener el status de la impresora para ver si esta lista para imprimir
    try {
      const isReadyPrinter = await getStatusPrinter(printerDeviceSelected);
      if (!isReadyPrinter) throw new Error();

      await printLabels(printerDeviceSelected, labels);
    } catch (err) {
      console.error(err);
      throw new Error("No se pudo imprimir. Revise el estado de su impresora");
    }
  }
  const getStatusPrinter = async (printerDeviceSelected) => {
    return new Promise((resolve, reject) => {
      const zebraPrinter = new window.Zebra.Printer(printerDeviceSelected);
      zebraPrinter.getStatus(
        (status) => {
          resolve(status.isPrinterReady());
        },
        (err) => {
          console.log(err);
          reject(err);
        }
      );
    });
  };

  const ImprimirEtiquetas = async (products) => {
    const BrowserPrint = window.BrowserPrint;
    const IP_PRINTER = ipPrinter; //GET IP FROM LOCALSTORAGE

    setIsLoadingPrinter(true);

    try {
      //OBTENER TODAS LAS IMPRESORAS QUE ESTE EN LA RED
      const allPrintersDevices = await setup(BrowserPrint);
      //OBTNER SOLO LA IMPRESORA QUE COINCIDA CON LA IP DEL USUARIO
      const printerDeviceSelected = getCurrentPrinterDevice(
        allPrintersDevices,
        IP_PRINTER
      );
      if (!printerDeviceSelected)
        throw new Error(`No se pudo conectar a la impresora ${IP_PRINTER}`);

      await writeToSelectedPrinter(printerDeviceSelected, products);
    } catch (error) {
      alert(error.message);
      console.error(error);
      throw new Error("Error en el proceso de impresion");
    } finally {
      setIsLoadingPrinter(false);
    }
  };
  // -----------------------------------------------------------------------------------------

  const PaletizarProcesos = async () => {
    try {
      setIsLoadingPaletizarProcesos(true);
      const allProducts = getAllRowsSelected();
      if (!allProducts || allProducts.length === 0)
        return alert("Seleccione al menos un producto");

      //Generando num pallet y actualizando los productos con ese num pallet
      let options1 = {
        method: "POST",
        body: JSON.stringify({
          allProducts: allProducts,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let allProductsUpdated = await fetchwrapper(
        "/paletizacion/updateNumPallet",
        options1
      );
      allProductsUpdated = await allProductsUpdated.json();
      const numPallet = allProductsUpdated.data.palletIdGenerated;

      const options2 = {
        method: "POST",
        body: JSON.stringify({
          allProducts: allProducts,
          palletId: numPallet,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let allProductsWithAditionalInfo = await fetchwrapper(
        "/paletizacion/getAllProductosToPrint",
        options2
      );
      allProductsWithAditionalInfo = await allProductsWithAditionalInfo.json();

      palletIdUpdatedBD.current = numPallet;
      const dataToPrint = {
        data: allProductsWithAditionalInfo?.data,
        numPallet: numPallet,
      };

      await ImprimirEtiquetas(dataToPrint);

      alert("Pallet creado correctamente");
    } catch (e) {
      //Delete pallet generated if printer process fail
      const options = {
        method: "POST",
        body: JSON.stringify({
          idPallet: palletIdUpdatedBD.current,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      await fetchwrapper("/paletizacion/buscar/eliminarPallet", options);

      console.error(e);
      alert("Error en crear pallet");
      navigate(-1);
    } finally {
      // Refrescar la página
      await filtrarBusqueda();
      await generatePalletId();
      setIsLoadingPaletizarProcesos(false);
    }
    // alert("Proceso completado");
  };

  return (
    <>
      <CustomBackdrop
        isLoading={
          isLoadingPaletizarProcesos ||
          isLoadingPalletidGenerated ||
          isLoadingIpPrinter
        }
      />
      <Info
        fechaHoraISO={fechaHoraISO}
        palletidGenerated={palletidGenerated}
        ipPrinter={ipPrinter}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={PaletizarProcesos}
        sx={{ marginBottom: "20px" }}
      >
        Guardar
        <span style={{ marginLeft: "8px" }}>
          <img src={GrabarIcon} alt="guardar" style={{ width: "40px" }} />
        </span>
      </Button>

      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <p style={{ marginRight: "8px" }}>
          Cantidad de etiquetas de pallet a imprimir:
        </p>

        <CustomInputNumber
          placeholderText="Cantidad de etiquetas pallet"
          initValue={2}
          setNumber={setCantidadEtiquetasPallet}
        />
      </div>

      <Box
        className={StyledDataGridContainer}
        sx={{ height: 800, width: "100%" }}
      >
        <DataGrid
          getRowId={(data) =>
            data.izoncodigo +
            "|" +
            data.bahcodigo +
            "|" +
            data.pascodigo +
            "|" +
            data.nivcodigo +
            "|" +
            data.poscodigo +
            "|" +
            data.embcodigo +
            "|" +
            data.toncodigo +
            "|" +
            data.artcodigo
          }
          columnVisibilityModel={{
            izondescripcion: false,
            bahdescripcion: false,
            pasdescripcion: false,
            nivdescripcion: false,
            posdescripcion: false,
            embdescripcion: false,
            tondescripcion: false,
            artcodigo: false,
            boddescri: false,
          }}
          columns={columnas}
          rows={data}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          processRowUpdate={processRowUpdate}
          rowSelectionModel={rowSelectionModel}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
        />
      </Box>
    </>
  );
};

export default DatagridEjecutar;
