import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
} from "@mui/material";
import { TextField, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import BackIcon from "../../../components/BackIcon";
import CrearIcon from "../../../assets/iconos/Crear.ico";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../components/CustomBackdrop";
import DatagridDragDrop from "../components/DatagridDragDrop";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const CrearFormulario = () => {
  const navigate = useNavigate();
  const Estados = [
    { value: "A", description: "Activo" },
    { value: "I", description: "Inactivo" },
  ];
  const [descripcion, setDescripcion] = useState("");
  const [estado, setEstado] = useState(Estados[0]);
  //   const [procesos, setProcesos] = useState([]);
  const [procesoSelected, setProcesoSelected] = useState(null);
  const [isLoadingCreateFormulario, setIsLoadingCreateFormulario] =
    useState(false);
  //State for drag and drop component
  const [allPreguntas, setAllPreguntas] = useState([]);
  const [preguntasSelectedFormulario, setPreguntasSelectedFormulario] =
    useState([]);

  const {
    data: fetchedAllPreguntas = [],
    isError: isLoadingAllPreguntasError,
    isFetching: isFetchAllPreguntas,
    isLoading: isLoadingAllPreguntas,
    refetch: refetchAllPreguntas,
  } = useGetAllPreguntas();

  //READ hook (get all preguntas from api)
  function useGetAllPreguntas() {
    return useQuery({
      queryKey: ["FormulariosAllCabecerasPreguntas"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          "/BancoDePreguntas/getAllBancoDePreguntas"
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }

  if (isLoadingAllPreguntasError) {
    alert("Error al obtener las preguntas");
    navigate(-1);
  }

  //When all preguntas load set in state
  useEffect(() => {
    if (fetchedAllPreguntas.length !== 0) {
      setAllPreguntas([...fetchedAllPreguntas]);
    }
  }, [fetchedAllPreguntas]);

  const {
    data: fetchedProcesosFormularios = [],
    isError: isLoadiProcesosFormulariosError,
    isFetching: isFetchProcesosFormularios,
    isLoading: isLoadiProcesosFormularios,
    refetch: refetchProcesosFormularios,
  } = useGetFormularios();

  if (isLoadiProcesosFormulariosError) {
    alert("Error al obtener todos los procesos");
    navigate(-1);
  }
  //READ hook (get Formularios from api)
  function useGetFormularios() {
    return useQuery({
      queryKey: ["procesosFormulario"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper("/FormularioProcesos/getProcesos");
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !descripcion ||
      !estado ||
      !procesoSelected ||
      preguntasSelectedFormulario.length === 0
    ) {
      alert("Complete todos los campos");
      return;
    }

    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          cabecera: {
            descripcion,
            estado: estado.value,
            procesocod: procesoSelected.procesocod,
          },
          detalle: preguntasSelectedFormulario,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      setIsLoadingCreateFormulario(true);

      let response = await fetchwrapper(
        "/FormularioProcesos/createFormulario",
        options
      );
      response = await response.json();
      clearAll();
      alert(`Formulario creado con código ${response.formcodigoGenerated}`);
      await refetchAllPreguntas();
      await refetchProcesosFormularios();
    } catch (error) {
      alert("No se puedo crear el formulario");
    } finally {
      setIsLoadingCreateFormulario(false);
    }
  };

  const clearAll = () => {
    setDescripcion("");
    setEstado(Estados[0]);
    setProcesoSelected(null);
    setAllPreguntas([...fetchedAllPreguntas]);
    setPreguntasSelectedFormulario([]);
  };

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Formulario</b>
        </div>
        <CustomBackdrop
          isLoading={
            isLoadiProcesosFormularios ||
            isFetchProcesosFormularios ||
            isLoadingAllPreguntas ||
            isFetchAllPreguntas ||
            isLoadingCreateFormulario
          }
        />

        <Box className={StyledRoot}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Descripción"
              margin="normal"
              fullWidth
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              required
            />

            <div>
              <InputLabel
                id="estadosCB"
                sx={{ paddingBlock: "10px", paddingLeft: "5px" }}
              >
                Estado
              </InputLabel>
              <Select
                fullWidth
                labelId="estadosCB"
                value={estado.value}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setEstado(
                    Estados.find((estado) => estado.value === selectedValue)
                  );
                }}
              >
                {Estados.map((estado) => (
                  <MenuItem key={estado.value} value={estado.value}>
                    {estado.description}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Autocomplete
              sx={{ marginTop: "30px" }}
              options={fetchedProcesosFormularios}
              getOptionLabel={(option) => option?.procesocod}
              id="procesos"
              value={procesoSelected}
              onChange={(event, newValue) => {
                setProcesoSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Procesos"
                  variant="outlined"
                  required
                />
              )}
              required
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              sx={{ marginTop: "20px" }}
            >
              <img src={CrearIcon} alt="Crear" style={{ width: "40px" }} />
              Crear
            </Button>
          </form>

          <div>
            <DatagridDragDrop
              data1={allPreguntas}
              setData1={setAllPreguntas}
              data2={preguntasSelectedFormulario}
              setData2={setPreguntasSelectedFormulario}
            />
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default CrearFormulario;
