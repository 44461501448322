import ProformaRedable from "./components/ProformaRedable";
import ProformaEditable from "./components/ProformaEditable";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import fetchwrapper from "../../services/interceptors/fetchwrapper";
import { useQuery } from "@tanstack/react-query";
import CustomBackdrop from "../../components/CustomBackdrop";
import { useNavigate } from "react-router-dom";
import { setQuantity, useCartStore } from "../../stores/cartStore";
import formatItemCart from "../../helpers/proforma/formatItemCart";
import { det } from "mathjs";
import CuerpoProforma from "./components/EmisionProforma";
// Solo se manejan dos estados de la proforma: P (PENDIENTE), C (COMPLETADO)
const Proforma = () => {
  const navigate = useNavigate();
  const { id: idProforma } = useParams();
  const { clearUseCartStoreStorage } = useCartStore();

  const [cabeceraProforma, setCabeceraProforma] = useState({});
  const [detalleProforma, setDetalleProforma] = useState([]);

  const getTotalsPrices = (cart) => {
    const subTotal = cart.reduce(
      (partialSum, value) => partialSum + value.totalPrice,
      0
    );
    const IVA = cart.reduce(
      (partialSum, value) => partialSum + value.totalIVA,
      0
    );
    const total = cart.reduce(
      (partialSum, value) => partialSum + value.totalPriceWithIVA,
      0
    );

    return {
      subTotal,
      IVA,
      total,
    };
  };

  const setSpecificQuantity = (idProduct, cart, quantity) => {
    const updatedCart = setQuantity(idProduct, cart, quantity);
    setDetalleProforma(updatedCart);
  };

  // useEffect(() => {
  //   const getCabeceraProforma = async () => {
  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({ numped: id }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     };
  //     let response = await fetchwrapper(
  //       "/proformas/cabecera_por_numped",
  //       options
  //     );
  //     if (!response.ok) {
  //       return console.error("Error");
  //     }
  //     response = await response.json();
  //     console.log("c", response);

  //     setCabeceraProforma(response.data);
  //     //console.log(cabeceraProforma, "////");
  //   };

  //   const getDetalleProforma = async () => {
  //     const options = {
  //       method: "POST",
  //       body: JSON.stringify({ numped: id }),
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Allow-Origin": "*",
  //       },
  //     };
  //     let response = await fetchwrapper(
  //       "/proformas/detalle_por_numped",
  //       options
  //     );
  //     if (!response.ok) {
  //       return console.error("Error");
  //     }
  //     response = await response.json();
  //     console.log("d", response);

  //     setDetalleProforma(response.data);
  //   };

  //   getCabeceraProforma();
  //   getDetalleProforma();
  // }, []);

  const {
    data: fetchedProforma = {},
    isError: isLoadingProformaError,
    // isFetching: isFetchingProforma,
    isLoading: isLoadingProforma,
    refetch: getProforma,
  } = useGetProforma();

  if (isLoadingProformaError) {
    console.log("Error en cargar proforma");
  }

  //READ hook (get Proforma from api)
  function useGetProforma() {
    return useQuery({
      queryKey: ["Proforma"],
      queryFn: async () => {
        //send api request here
        //idProforma='001'
        let response = await fetchwrapper(
          `/proformas/getProforma/${idProforma}`
        );
        console.log("rrrrrrrrrr");

        console.log("rrrrrrrrrr");
        response = await response.json();
        console.log(response);

        return response;
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: false,
    });
  }

  const clearProforma = () => {
    setCabeceraProforma({});
    setDetalleProforma([]);
  };
  const handleGetProforma = async () => {
    try {
      await getProforma();
    } catch {
      alert("No se pudo cargar los datos de la proforma");
      navigate(-1);
    }
  };
  useEffect(() => {
    clearProforma();
    clearUseCartStoreStorage();
    console.log("eliminadesde");
    handleGetProforma();
  }, []);

  useEffect(() => {
    if (Object.keys(fetchedProforma).length !== 0) {
      const { cabecera, detalle } = fetchedProforma;
      const formatedDetalle = detalle.map((product) => formatItemCart(product));
      setCabeceraProforma(cabecera);
      setDetalleProforma(formatedDetalle);

      console.log(fetchedProforma, "aquii");
    }
  }, [fetchedProforma]);

  return (
    <>
      <CustomBackdrop isLoading={isLoadingProforma} />

      {!isLoadingProforma && Object.keys(fetchedProforma).length !== 0 && (
        <>
          { cabeceraProforma?.pedstatus === "P" ? (
            <ProformaEditable
              idProforma={idProforma}
              cabeceraProforma={cabeceraProforma}
              setCabeceraProforma={setCabeceraProforma}
              detallesProforma={detalleProforma}
              setDetallesProforma={setDetalleProforma}
              getTotalsPrices={getTotalsPrices}
              setSpecificQuantity={setSpecificQuantity}
            />
          ) : (
            <ProformaRedable
              idProforma={idProforma}
              cabeceraProforma={cabeceraProforma}
              detallesProforma={detalleProforma}
              getTotalsPrices={getTotalsPrices}
            />
          )}
        </>
      )}
    </>
  );
};

export default Proforma;

/**cabeceraProforma?.pedstatus === "NONE" ? (
            <CuerpoProforma
            //idProforma=""
            //cabeceraProforma={cabeceraProforma}
            //setCabeceraProforma = {setCabeceraProforma}
            //detallesProforma={detalleProforma}
            //setDetallesProforma={setDetalleProforma}
            //getTotalsPrices={getTotalsPrices}
            //setSpecificQuantity={setSpecificQuantity}
            />
          ) : */