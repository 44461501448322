import { useState } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { Autocomplete, TextField, Button } from "@mui/material";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import LoadingComponent from "../../../components/loadingComponent";

import months from "../assets/months";
import years from "../assets/years";
import BuscarIcon from "../../../assets/iconos/Buscar.ico";

export default function BasicPie() {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [pieData, setPieData] = useState([]);
  const [isLoadingPie, setIsLoadingPie] = useState(false);

  const convertDataToPieData = (data) => {
    const pieData = data.map((d, index) => ({
      id: index,
      value: d?.facvaltot,
      label: d?.locdescri,
    }));

    return pieData;
  };

  const getVtasDiasLocalidadPie = async () => {
    if (!selectedMonth || !selectedYear) {
      alert("Complete los campos");
      return;
    }
    try {
      setIsLoadingPie(true);
      let response = await fetchwrapper(
        `/facturas_ventas/getFactVentasPie/${selectedMonth.value}/${selectedYear.value}`
      );
      response = await response.json();

      //Convertir el lo que devuelve el api a la estrucutura de dato que maneja el componente pie
      const pieData = convertDataToPieData(response?.data);
      setPieData(pieData);

      console.log(pieData);
    } catch (error) {
      alert("No se pudo encontrar data");
    } finally {
      setIsLoadingPie(false);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 30px 30px 30px",
          fontSize: "20px",
        }}
      >
        <b>Gráfico circular</b>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Autocomplete
          sx={{ width: "40%" }}
          id="month-combo"
          options={months}
          getOptionLabel={(option) => option.label}
          value={selectedMonth}
          onChange={(event, newValue) => {
            setSelectedMonth(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Selecciona un mes" />
          )}
        />
        <Autocomplete
          sx={{ width: "40%" }}
          id="year-combo"
          options={years}
          getOptionLabel={(option) => option.label}
          value={selectedYear}
          onChange={(event, newValue) => {
            setSelectedYear(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Selecciona un año" />
          )}
        />
      </div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ width: "30%" }}
          onClick={getVtasDiasLocalidadPie}
          endIcon={
            <img src={BuscarIcon} style={{ width: "22px" }} alt="buscar" />
          }
        >
          Filtrar
        </Button>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        {isLoadingPie ? (
          <LoadingComponent />
        ) : pieData.length === 0 ? (
          "No existen datos"
        ) : (
          <PieChart
            series={[
              {
                data: pieData,
              },
            ]}
            width={400}
            height={200}
          />
        )}
      </div>
    </div>
  );
}
