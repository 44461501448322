import React, { useState, useEffect } from "react";
import Header from "../../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

import BackIcon from "../../../../../components/BackIcon";

import { NavLink, useNavigate, useLocation } from "react-router-dom";
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper";
// import DatagridBuscarEjecutar from "../../components/DatagridBuscarEjecutar";
import CustomBackdrop from "../../../../../components/CustomBackdrop";
import DatagridBuscarUbicacion from "../../../components/DatagridBuscarUbicacion";
import GalleryImages from "../../../components/GalleryImages";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const StyledIcons = styled(NavLink)(({ theme }) => ({
  height: 250,
  width: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  border: "1px solid #ddd",
  cursor: "pointer",

  "& img": {
    width: "128px",
  },
}));

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const BuscarUbicacionPaletizacion = () => {
  const location = useLocation();
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isLoadingFiltrarBusqueda, setIsLoadingFiltrarBusqueda] =
    useState(false);

  const filtrarBusqueda = async () => {
    try {
      setIsLoadingFiltrarBusqueda(true);
      const options = {
        method: "POST",
        body: JSON.stringify({
          ...location.state,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let response = await fetchwrapper(
        "/paletizacion/buscar/getProductosxPalletxUbicacion",
        options
      );
      response = await response.json();

      setDataFiltered(response.data);

      console.log(location.state, response);
    } catch (err) {
      alert("Error en filtrar los datos");
    } finally {
      setIsLoadingFiltrarBusqueda(false);
    }
  };

  useEffect(() => {
    filtrarBusqueda();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Paletizacion</b>
        </div>

        <CustomBackdrop isLoading={isLoadingFiltrarBusqueda} />

        <Box className={StyledRoot}>
          {dataFiltered.length === 0 ? (
            <div style={{ textAlign: "center" }}>No existen registros</div>
          ) : (
            <>
              <DatagridBuscarUbicacion
                data={dataFiltered}
                setData={setDataFiltered}
                filtrarBusqueda={filtrarBusqueda}
                info={location.state}
              />
              <GalleryImages producto={location.state} />
            </>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default BuscarUbicacionPaletizacion;
