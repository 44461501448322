import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

import BackIcon from "../../components/BackIcon";

import fetchwrapper from "../../services/interceptors/fetchwrapper";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "../../components/loadingComponent";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
  textAlign: "center",
  backgroundColor: "rgb(37,211, 102,0.2)",
  cursor: "pointer",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const BcosDiasSaldos = () => {
  const navigate = useNavigate();
  const [bcoEstadosCta, setBcoEstadosCta] = useState([]);
  const [isLoadingBcoEstadosCta, setisLoadingBcoEstadosCta] = useState(false);

  const getAllBcoEstadosCta = async () => {
    try {
      setisLoadingBcoEstadosCta(true);
      let response = await fetchwrapper("/facturas_ventas/getBcoEstadoCta");
      response = await response.json();

      setBcoEstadosCta(response?.data);
    } catch (error) {
      alert("No se puedo obtner los estados de cuenta");
    } finally {
      setisLoadingBcoEstadosCta(false);
    }
  };

  useEffect(() => {
    getAllBcoEstadosCta();
  }, []);

  const goToBcosDiasSaldosDetail = (item) => {
    navigate(`/home/dashboard/BcosDiasSaldos/${item?.bcocodigo}`,{state:item})
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Estados de cuenta</b>
        </div>
        {isLoadingBcoEstadosCta ? (
          <LoadingComponent />
        ) : (
          <Box className={StyledRoot}>
            <Grid container spacing={2}>
              {bcoEstadosCta.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.bcocodigo}>
                  <StyledCard onClick={() => goToBcosDiasSaldosDetail(item)}>
                    <CardContent>
                      <Typography variant="h5" component="h2">
                        {item.bcodescri}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Saldo actual: ${item.sum_tranvalor}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default BcosDiasSaldos;
