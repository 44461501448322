import { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
//import notification from "../data/Notification";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import BackIco from "../assets/iconos/Regresar.ico"

//STYLES
const StyledBackIcon = styled("div")(({ theme }) => ({
  marginTop: "25px",
  marginBottom: "25px",
  alignItems: "center",
  cursor: "pointer",
}));
const BackIcon = () => {
  const [currentURL, setCurrentURL] = useState("");
  useEffect(() => {
    const currentUrl = window.location.href;
    setCurrentURL(currentURL);
    console.log("URL actual:", currentUrl);

    return () => {
      const urlRestante = window.location.pathname;
      if (urlRestante === "/home") {
        document.body.classList.add("sidebar-show");
      }
    };
  }, [currentURL]);

  const goBack = () => {
    window.history.back();
  };

  return (
    <StyledBackIcon onClick={goBack}>
      <img src={BackIco} style={{width:"40px",marginRight: "8px"}} alt="regresaar"/>
      Regresar
    </StyledBackIcon>
  );
};

export default BackIcon;
