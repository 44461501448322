import React, { useState } from "react";
import { Box, Grid, TextField, Paper, Typography } from "@mui/material";
import Header from "../../../layouts/Header";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import math from "../../utils/math";
import ReadableTableDetalleProforma from "./ReadableTableDetalleProforma";
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate";
import BackIcon from "../../../components/BackIcon";

//Estilos para el color
const theme = createTheme({
  palette: {
    primary: {
      main: "#E8DFD1",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const ProformaEditableResp = ({
  idProforma,
  cabeceraProforma,
  detallesProforma,
  getTotalsPrices,
}) => {
  const [totalPrices, setTotalPrices] = useState({
    subTotal: 0,
    IVA: 0,
    total: 0,
  });

  React.useEffect(() => {
    const { subTotal, IVA, total } = getTotalsPrices(detallesProforma);
    setTotalPrices({
      subTotal,
      IVA,
      total,
    });
  }, [detallesProforma, detallesProforma.length]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4"></div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px",
            }}
          >
            <Paper
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* *****************************************************
                                  CABECERA
              ****************************************************** */}
              <Typography style={{ margin: "10px" }} variant="h6">
                Información22
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="NumPed"
                    defaultValue={idProforma}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                {/* <DatePicker
                  key={cabeceraProforma.pedfecemi}
                  label="Fecha de Creación"
                  // setDate={setFechaInicio}
                  date={cabeceraProforma?.pedfecemi}
                  disabled={true}
                /> */}
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Fecha de Creación"
                    defaultValue={normalFormatDate(cabeceraProforma.pedfecisys)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    key={cabeceraProforma.clinombre}
                    id="outlined-read-only-input"
                    label="Cliente"
                    defaultValue={cabeceraProforma.clinombre}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    key={cabeceraProforma.pedstatus}
                    id="outlined-read-only-input"
                    label="Estado"
                    defaultValue={cabeceraProforma.pedstatus}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    key={cabeceraProforma.vennombre}
                    id="outlined-read-only-input"
                    label="Vendedor"
                    defaultValue={cabeceraProforma.vennombre}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>

                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Empresa"
                    defaultValue={
                      JSON.parse(localStorage.getItem("jwt")).seleccion
                        .cliciacianombre
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}
              </Grid>
            </Paper>
          </div>

          {/* *****************************************************
                                  DETALLE (TABLE)
              ****************************************************** */}

          <ReadableTableDetalleProforma detallesProforma={detallesProforma} />

          {/* *****************************************************
                                  TOTAL PRICES
              ****************************************************** */}

          <Box sx={{ marginLeft: "15px", paddingTop: "25px" }}>
            <div>
              <p>
                {" "}
                <strong>Subtotal:</strong>
                {` $${math.round(totalPrices.subTotal, 2)}`}
                {cabeceraProforma.pedsubtot}
              </p>
            </div>
            <div>
              <p>
                <strong>IVA:</strong>
                {` $${math.round(totalPrices.IVA, 2)}`}
                {cabeceraProforma.pediva}
              </p>
            </div>
            <div>
              <p>
                <strong>Total:</strong>
                {` $${math.round(totalPrices.total, 2)}`}
                {cabeceraProforma.pedtotal}
              </p>
            </div>
          </Box>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ProformaEditableResp;
