import {
  Badge,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import { Link, json, useNavigate } from "react-router-dom";
import { useCartStore } from "../stores/cartStore";

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ProductInnerNotification from "./ProductInnerNotification";
import { useState } from "react";
import fetchwrapper from "../services/interceptors/fetchwrapper";
import { CircularProgress } from "@mui/material";
import truncateNumber from "../pages/utils/math/truncate";
import math from "../pages/utils/math";
import CustomBackdrop from "../components/CustomBackdrop";

import { useMutation } from "@tanstack/react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  heigth: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

export default function Cart({ type = "default", handleProductsProfoma}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "cart-popover" : undefined;

  //-------------NUEVOOOOOOOOOO
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [isLoading, setIsLoading] = useState(false);
  const [loadFails, setLoadFails] = useState(false);
  const navigate = useNavigate();

  const formatCartProductsProforma = (cart, cabeceraProforma) => {
    const user = localStorage.getItem("cliciausu");
    const newDetalleProforma = cart.map((item) => {
      const newDetalle = {
        artcodigo: item.cabecera.codigo,
        artdescri: item.cabecera.descripcion,
        pedcantidad: item.totalToBuy,
        pedprecio: Number(item.cabecera.precio),
        pediva: 12,
        pedvaldesc: 0,
        pedvalor: truncateNumber(item.totalToBuy * item.cabecera.precio),
        // pedvaltot: truncateNumber(
        //   item.totalToBuy * item.cabecera.cantidad +
        //     item.totalToBuy * item.cabecera.cantidad * 0.12
        pedvalsubtot: truncateNumber(item.totalToBuy * item.cabecera.precio),
        pedivaliva: truncateNumber(
          item.totalToBuy * item.cabecera.precio * 0.12
        ),
        pedvaltot: truncateNumber(
          item.totalToBuy * item.cabecera.precio * 1.12
        ),
        user: user,
        pedusuisys: user,
        pednumped: cabeceraProforma.numPed,
        pedstatus: "P",
      };
      return newDetalle;
    });

    console.log(newDetalleProforma, "hereeeeeeee***");
    //setDetalleProforma(newDetalleProforma);
    return newDetalleProforma;
  };

  // //--------------------------------------------------------------

  //                     NEW !!!!!!
  // //--------------------------------------------------------------

  const { cart, getTotalsPrices,clearUseCartStoreStorage } = useCartStore();
  // const [isLoadingProforma, setIsLoadingProforma] = useState(false);

  //call UPDATE hook
  const { mutateAsync: createProforma, isPending: isCreatingProforma } =
    useCreateProforma();

  //UPDATE hook (put Proforma in api)
  function useCreateProforma() {
    return useMutation({
      mutationFn: async (proforma) => {
        //send api update request here
        const options = {
          method: "POST",
          body: JSON.stringify({
            ...proforma,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };

        let response = await fetchwrapper("/proformas/createProforma", options);
        response = await response.json();

        return response.numPed;
      },
    });
  }
  const handleCreateProforma = async () => {
    let detalle = cart; 

    //Generate cabecera
    /*const totalProductsWithoutIVA = cart.reduce(
      (partialSum, value) => (value.sysiva === 0 ? partialSum + 1 : partialSum),
      0
    );
    const totalProductsWithIVA = cart.length - totalProductsWithoutIVA;

    const { subTotal, IVA, total } = getTotalsPrices();

    const cabecera = {
      pedtivacer: totalProductsWithoutIVA,
      pedtivapor: totalProductsWithIVA,
      pedsubtot: math.round(subTotal, 2),
      pediva: math.round(IVA, 2),
      pedtotal: math.round(total, 2),
    };

    const proforma = {
      cabecera,
      detalle,
    }; */
    try {
      handleClose();
      //const idProforma = await createProforma(proforma);
      //clearUseCartStoreStorage()
      //Update store cart
      // setIdProformaCart(idProforma);
      // // setCabeceraCart({})
      // enableProformaProcess();

      //alert(`Proforma ${idProforma} creada con exito`);
   

      navigate(`/home/dashboard/Proformas / EmisionProforma`);
    } catch (error) {
      alert("No se pudo crear la proforma");
    }
  };

  const [totalPrices, setTotalPrices] = useState({
    subTotal: 0,
    IVA: 0,
    total: 0,
    subtotalNeto:0
  });

  React.useEffect(() => {
    const { subTotal, IVA, total,subtotalNeto } = getTotalsPrices();
    setTotalPrices({
      subTotal,
      IVA,
      total,
      subtotalNeto
    });
  }, [cart, cart.length]);

  return (
    <React.Fragment>
      <CustomBackdrop isLoading={isCreatingProforma} />

      <div>
        <Button aria-describedby={id} onClick={handleClick}>
          <Badge badgeContent={cart.length} color="primary">
            <ShoppingCartIcon style={{ color: "#114B5E" }} />
          </Badge>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            style: { width: "70%" },
          }}
        >
          <Grid container sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            {cart.length === 0 ? (
              <Box sx={{ margin: "1.5rem" }}>No items</Box>
            ) : (
              <>
                {cart.map((item, index) => (
                  <>
                    <Grid item xs={12} key={item.codigo}>
                      <ProductInnerNotification
                        product={item}
                        MIN_CANTIDAD={1}
                        // MAX_CANTIDAD={item.maxTotalStockToBuy}
                        MAX_CANTIDAD={Infinity}
                      />
                      <hr />
                    </Grid>
                  </>
                ))}
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: "center", marginBottom: "10px" }}
                >
                  <strong>Subtotal:</strong> $
                  {math.round(totalPrices.subTotal, 2)}
                  <br />
                  <strong>IVA:</strong> {math.round(totalPrices.IVA, 2)}
                  <br />
                  <strong>Total:</strong> ${math.round(totalPrices.total, 2)}
                  <br />
                </Grid>
              </>
            )}
          </Grid>

          {isCreatingProforma ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {type === "default" && (
                <>
                  {cart.length !== 0 ? (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#196C87", color: "white" }}
                      sx={{ width: "100%", margin: "0 auto", display: "block" }}
                      onClick={handleCreateProforma}
                      // disabled={!isCreatingProforma}
                    >
                      Siguiente
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "gray", color: "white" }}
                      sx={{ width: "100%", margin: "0 auto", display: "block" }}
                      onClick={() =>
                        navigate(
                          `/home/dashboard/Creacion de Catalogos/Filtros`
                        )
                      }
                      disabled={true}
                    >
                      Agregue productos proformar
                    </Button>
                  )}
                </>
              )}
              {/* ---El tipo modal solo se usa cuando se esta en la pagina de profoma/pedidos y el default es usado globalmente */}
              {type === "modal" && (
                <>
                  {cart.length !== 0 ? (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#196C87", color: "white" }}
                      sx={{ width: "100%", margin: "0 auto", display: "block" }}
                      onClick={()=>handleProductsProfoma()}
                      // disabled={!isCreatingProforma}
                    >
                      Añadir productos a la proforma
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "gray", color: "white" }}
                      sx={{ width: "100%", margin: "0 auto", display: "block" }}
                      onClick={() =>
                        navigate(
                          `/home/dashboard/Creacion de Catalogos/Filtros`
                        )
                      }
                      disabled={true}
                    >
                      Agregue productos a la proforma
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Popover>
      </div>
    </React.Fragment>
  );
}