import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import esPreguntaObligatoria from "../utils/esPreguntaObligatoria";

const TextoLibre = ({
  description,
  setValue,
  pregcodigo,
  value,
  pregobligatoria,
  form,
  step,
  isReadable
}) => {
  const handleTextFieldChange = (event) => {
    // Actualizar el estado con el valor escrito
    const nuevaRespuesta = event.target.value;

    const preguntaConRespuesta = form.preguntas.map((pregunta) => {
      if (pregunta?.pregcodigo === pregcodigo) {
        return {
          ...pregunta,
          respuestaSelected: nuevaRespuesta,
        };
      }
      return pregunta;
    });

    let updatedForm = {
      cabecera: { ...form.cabecera },
      preguntas: preguntaConRespuesta,
    };

    setValue(step, updatedForm);
  };

  return (
    <div>
      <p
        style={{ paddingLeft: "5px", fontWeight: "bolder", fontSize: "1.1rem" }}
      >
        {description}
      </p>
      <TextField
        sx={{ marginTop: "0px" }}
        label={`Texto libre ${esPreguntaObligatoria(pregobligatoria)}`}
        variant="outlined"
        fullWidth
        margin="normal"
        value={value}
        onChange={handleTextFieldChange}
        {...isReadable && { InputProps: { readOnly: true } }}
      />
    </div>
  );
};

export default TextoLibre;
