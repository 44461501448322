import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { CircularProgress } from '@mui/material';
import fetchwrapper from '../../../../../services/interceptors/fetchwrapper';
import PriceSelector from './PriceSelector';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

//import components of table mui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
const theme = createTheme();

const ModalStyles = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    },
    [theme.breakpoints.up('md')]: {
        width: 500,
    },
    [theme.breakpoints.up('lg')]: {
        width: 800,
    },
}));

const ProductImageStyles = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: 'auto',
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
        height: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
        maxWidth: '50%',
        height: 'auto',
    },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // set the width to be responsive
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    maxHeight: '90vh', // set the maximum height of the modal to be 90% of the viewport height
    overflowY: 'auto', // add a scrollbar if the content exceeds the height of the modal
};


const themeTable = createTheme({
    //make the width of the table responsive
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    //make the font responsive
    typography: {
        fontSize: 12,
    },
    //make the width smaller to fit the table
    spacing: 1,
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '0.5rem',
                },
            },
        },
    },

});


const styleTable = {
    maxHeight: 200,
    overflow: 'auto',
    //make font responsive
    fontSize: '0.1rem',
};

function ModalProducto({ isOpen, handleClose, cproducto }) {
    const [producto, setProducto] = useState({});
    const [existe, setExiste] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const cliciaciacodigo = JSON.parse(localStorage.getItem("jwt")).seleccion.cliciaciacodigo;
    const loccodigo = JSON.parse(localStorage.getItem("jwt")).localidad.loccodigo;
    useEffect(() => {
        setOpen(isOpen);
        async function fetchProducto() {
            //loading start
            setIsLoading(true);
            let link = cproducto.split("-")[0];
            //the code may contain various - so we need to join the array from the second element
            let code = cproducto.split("-").slice(1).join("-");
            //if link is only begin with / then we need to add /get_producto_por_codigo because this is a barcode
            //If is barcode
            if (link[0]!=="/") {
                link = "/productos/get_producto_por_codigo";
                code = cproducto
            }
            const request = {
                seleccion: {
                    cliciaciacodigo: cliciaciacodigo,
                },
                localidad: {
                    loccodigo: loccodigo,
                },
                producto_codigo: code
            };
            const options = {
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            };
            console.log(request)
            try {
                console.log(request)
                const response = await fetchwrapper(link, options);
                /*const response = await fetch(cproducto, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                });*/
                const data = await response.json();
                console.log(data);
                setProducto(data);
                setExiste(true);
                //loading end
            } catch (error) {
                setExiste(false);
                //loading end
                console.log(error);
            } finally {
                //loading end
                setIsLoading(false);
            }
        }

        if (isOpen) {
            fetchProducto();
        } else {
            setProducto({});
            setExiste(false);
        }
    }, [isOpen, cproducto]);

    return (
        <ThemeProvider theme={theme}>
            <ModalStyles>
                <Modal open={open} onClose={handleClose}>
                    <Box sx={{ ...style, width: 400 }}>
                        <Typography variant="h4" component="h2" align="center" gutterBottom>
                            Información del producto
                        </Typography>
                        {isLoading ? (
                            <Grid container justifyContent="center" direction="column" alignItems="center">
                                <Grid item>
                                    <CircularProgress />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" component="h2" align="center" gutterBottom>
                                        Cargando...
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            existe ? (
                                <Grid container spacing={2}>
                                    <Carousel>

                                    {producto?.imagen?.length>0 ? (
                                        producto.imagen.map(img=>
                                            <img
                                                src={`data:image/jpeg;base64,${img}`}
                                                alt={producto?.cabecera?.descripcion}
                                                style={{ maxWidth: "100%", height: "auto" }}
                                            />
                                        )
                                        
                                    ) : (
                                        
                                                <p>No existe imagen para este producto</p>
                                    )}
                                    </Carousel>
                                    <Grid item xs={12} sm={6} style={{ height: "100%" }}>
                                        <Box style={{ height: "100%" }}>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Código:</b> {producto.cabecera.codigo}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Descripción:</b> {producto.cabecera.descripcion}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Marca:</b> {producto.cabecera.marca}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Medida:</b> {producto.cabecera.medida}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Presentación:</b> {producto.cabecera.presentacion}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Linea:</b> {producto.cabecera.linea}
                                            </Typography>
                                            <Typography variant="body2" gutterBottom>
                                                <b>Precio:</b> ${parseFloat(producto.cabecera.precio).toFixed(2)}
                                            </Typography>
                                            <Typography variant="body2" 
                                            color={producto.cabecera.cantidad > 1 ? 'text.secondary' : 'error'}
                                            gutterBottom>
                                                <b>Stock total:</b> {parseFloat(producto.cabecera.cantidad).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    {producto.cuerpo.length > 0 &&
                                        <Grid item xs={12}>
                                            <ThemeProvider theme={themeTable}>
                                                <TableContainer component={Paper} style={styleTable}>
                                                    <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                                                        <TableHead sx={{ backgroundColor: '#196C87' }}>
                                                            <TableRow>
                                                                <TableCell sx={{ color: '#ffffff', fontWeight: 'bold' }}>Bod. Codigo</TableCell>
                                                                <TableCell align="right" sx={{ color: '#ffffff', fontWeight: 'bold' }}>Bodega</TableCell>
                                                                <TableCell align="right" sx={{ color: '#ffffff', fontWeight: 'bold' }}>Cantidad Bodega</TableCell>
                                                                <TableCell align="right" sx={{ color: '#ffffff', fontWeight: 'bold' }}>Localidad</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {producto.cuerpo.map((row) => (
                                                                <TableRow key={row.bodcodigo}>
                                                                    <TableCell component="th" scope="row">
                                                                        {row.bodcodigo}
                                                                    </TableCell>
                                                                    <TableCell align="right">{row.bodega}</TableCell>
                                                                    <TableCell align="right">{parseFloat(row.cantidad_bodega).toFixed(2)}</TableCell>
                                                                    <TableCell align="right">{row.localidad}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </ThemeProvider>
                                        </Grid>
                                    }

                                    <Grid item xs={12}>
                                        <PriceSelector producto={producto} handleClose={handleClose} />
                                    </Grid>
                                </Grid>

                            ) : (
                                <Typography variant="subtitle1" gutterBottom>
                                    No se encontró información del producto
                                </Typography>
                            )

                        )}


                    </Box>
                </Modal >
            </ModalStyles>
        </ThemeProvider>

    );
}

ModalProducto.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    cproducto: PropTypes.string.isRequired,
};

export default ModalProducto;