import React, { useState, useMemo } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomDatePicker from "../../../components/CustomDatePicker";
import dayjs from "dayjs";
import { TextField } from "@mui/material";
import Obturador from "../../../components/Obturador/Obturador";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import SearchIcon from "@mui/icons-material/Search";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../components/CustomBackdrop";

const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  closeStyle: {
    cursor: "pointer",
    marginLeft: "95%",
  },
};

const TableLotes = ({ data, setData }) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "codigoLote",
        header: "Lote",
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: "cantidadLote",
        header: "Cantidad",
      },
      {
        accessorKey: "fechaElaboracionLote",
        header: "Fecha de Elaboración",
        Cell: ({ cell }) => <div>{normalFormatDate(cell.getValue())}</div>,
      },
      {
        accessorKey: "fechaVencimientoLote",
        header: "Fecha de Vencimiento",
        Cell: ({ cell }) => <div>{normalFormatDate(cell.getValue())}</div>,
      },
    ],
    []
  );

  const deleteLote = (row) => {
    const newLotesData = data.filter(
      (lote) => lote.codigoLote !== row.original.codigoLote
    );

    setData(newLotesData);
  };

  const table = useMaterialReactTable({
    localization: { ...MRT_Localization_ES },
    columns,
    data,
    getRowId: (row) => row.codigoLote,
    enableRowActions: true,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => deleteLote(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
};

const IntegerInput = ({ value, setValue }) => {
  const handleChange = (event) => {
    const newValue = event.target.value.replace(/\D/g, ""); // Eliminar todos los caracteres que no sean dígitos
    setValue(Number(newValue));
  };
  return (
    <TextField
      fullWidth
      variant="outlined"
      type="number"
      value={value}
      onChange={handleChange}
    />
  );
};

const LoteObturador = ({ value, setValue, onSearch, statusBlocking }) => {
  const handleSearch = async () => {
    if (onSearch) {
      await onSearch(value);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSearch();
    }
  };

  return (
    <div>
      <TextField
        disabled={statusBlocking}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus={true}
        onKeyPress={handleKeyPress}
        variant="outlined"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const Lotes = ({ maxCantidadEmbalaje, setLotesInProducto, artdiasseg }) => {
  const [open, setOpen] = React.useState(false); //modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoadingSearchLote, setIsLoadingSearchLote] = useState(false);
  const [statusBlockingInput, setStatusBlockingInput] = useState(false);

  const [fechaElaboracionLote, setFechaElaboracionLote] = useState(
    dayjs(new Date())
  );
  const [fechaVencimientoLote, setfechaVencimientoLote] = useState(
    dayjs(new Date())
  );
  const [codigoLote, setCodigoLote] = useState("");
  const [cantidadLote, setCantidadLote] = useState("");

  const [lotesData, setLotesData] = useState([]);

  const addLote = async () => {
    //Verificar que si el codigo lote existe, coincida con los campos que digito el usuario
    try {
      setIsLoadingSearchLote(true);
      const options = {
        method: "POST",
        body: JSON.stringify({
          codigoLote: codigoLote,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let response = await fetchwrapper(`/cuarentena/getLote`, options);
      response = await response.json();
      const { artlote, artfecfab, artfecven } = response.data;

      if (artlote && artfecfab && artfecven) {
        console.log("aqui");
        if (
          codigoLote !== artlote ||
          !fechaElaboracionLote.isSame(new dayjs(artfecfab)) ||
          !fechaVencimientoLote.isSame(new dayjs(artfecven))
        ) {
          alert(
            "Ese lote existe, pero sus datos no coinciden. Busque primero ese lote para obtener su datos válidos"
          );
          return false; //No agregar lote
        }
      }
    } catch (error) {
      unblockInputs();
    } finally {
      setIsLoadingSearchLote(false);
    }
    //Verificar que los campos no esten vacios
    if (
      fechaElaboracionLote === "" ||
      fechaElaboracionLote === null ||
      fechaVencimientoLote === "" ||
      fechaVencimientoLote === null ||
      codigoLote === "" ||
      codigoLote === null ||
      cantidadLote === "" ||
      cantidadLote === null
    ) {
      alert("No se aceptan campos vacios");

      return;
    }

    //Verificar que el codigo de lote no se repita
    if (lotesData.some((lote) => lote.codigoLote === codigoLote)) {
      alert("No se permite lotes con códigos repetidos");
      return;
    }

    //Verficar que las fecha de elaboracion no sea mayor a la de vencimiento
    if (fechaElaboracionLote.isAfter(fechaVencimientoLote)) {
      alert(
        "La fecha de elaboración debe ser menor que la fecha de expiración"
      );
      return;
    }

    //Verficar que la diferencia entre las dos fechas del lote
    // sea mayor a los dias de seguridad
    if (fechaVencimientoLote.diff(fechaElaboracionLote, "day") <= artdiasseg) {
      alert(
        `Las fechas no cumplen con los días de seguridad establecido: ${artdiasseg} días`
      );
      return;
    }

    //Verificar que la cantidad del lote sea distinto de 0
    if (cantidadLote === 0) {
      alert("Digite una cantidad diferente de 0");
      return;
    }

    //Verificar que la cantidad actual en el lote no sea mayor a la cantidad de embalaje
    const cantidadTotalLotesRegistrados = lotesData.reduce(
      (acc, lote) => acc + lote.cantidadLote,
      0
    );
    const maxCantidad = maxCantidadEmbalaje - cantidadTotalLotesRegistrados;
    if (cantidadLote > maxCantidad) {
      alert(
        `La cantidad total en lotes ${
          cantidadLote + cantidadTotalLotesRegistrados
        } no debe ser mayor a la cantidad a ingresar ${maxCantidadEmbalaje}`
      );
      return;
    }

    //Si cumple con todas las verificacion agrega el lote al arreglo de lotesData
    setLotesData((prev) => [
      ...prev,
      {
        codigoLote: codigoLote,
        cantidadLote: cantidadLote,
        fechaElaboracionLote: fechaElaboracionLote.toISOString(),
        fechaVencimientoLote: fechaVencimientoLote.toISOString(),
      },
    ]);

    //Limpiar los inputs de datos
    limpiarInputs();
  };

  const limpiarInputs = () => {
    // setFechaElaboracionLote(dayjs(new Date()));
    // setfechaVencimientoLote(dayjs(new Date()));
    setCodigoLote("");
    setCantidadLote("");
    unblockInputs();
  };
  const limpiarModal = () => {
    setFechaElaboracionLote(dayjs(new Date()));
    setfechaVencimientoLote(dayjs(new Date()));
    setCodigoLote("");
    setCantidadLote("");

    setLotesData([]);
    unblockInputs();
  };

  const blockInputs = () => {
    setStatusBlockingInput(true);
  };

  const unblockInputs = () => {
    setStatusBlockingInput(false);
  };

  const saveLotes = () => {
    // Verificar que los cantidad lotes agregados en la tabla coincidad con la cantidad de embalaje digitada por el usuario
    const cantidadTotalLotesRegistrados = lotesData.reduce(
      (acc, lote) => acc + lote.cantidadLote,
      0
    );

    if (cantidadTotalLotesRegistrados !== maxCantidadEmbalaje) {
      alert(
        `La cantidad ingresada por lote ${cantidadTotalLotesRegistrados} es diferente de ${maxCantidadEmbalaje}`
      );
      return;
    }

    //Si cumple con la verficacion guardar los lotes registrados en el producto en el que se esta trabajando
    setLotesInProducto(lotesData);
    handleClose();
  };

  const searchLote = async () => {
    try {
      setIsLoadingSearchLote(true);
      const options = {
        method: "POST",
        body: JSON.stringify({
          codigoLote: codigoLote,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let response = await fetchwrapper(`/cuarentena/getLote`, options);
      response = await response.json();
      const { artlote, artfecfab, artfecven } = response.data;

      if (!artlote || !artfecfab || !artfecven) {
        unblockInputs();
        return false;
      }

      setCodigoLote(artlote);
      setFechaElaboracionLote(dayjs(artfecfab));
      setfechaVencimientoLote(dayjs(artfecven));
      blockInputs();
      return true;
    } catch (error) {
      alert("No se pudo encontrar ese lote");
      unblockInputs();
      return false;
    } finally {
      setIsLoadingSearchLote(false);
    }
  };

  return (
    <span style={{ flexGrow: 1 }}>
      <CustomBackdrop isLoading={isLoadingSearchLote} zIndex={999999} />
      <Button
        variant="text"
        color="primary"
        fullWidth
        style={{
          backgroundColor: "#4DB1E3",
          color: "white",
          fontSize: "1.1rem",
          letterSpacing: "1rem",
          fontWeight: "bolder",
        }}
        onClick={() => {
          if (maxCantidadEmbalaje <= 0) {
            alert("Digite la cantidad de embalaje primero ");
            return;
          }
          limpiarModal();
          handleOpen();
        }}
      >
        ...
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <IconButton style={styles.closeStyle} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              addLote();
            }}
          >
            <div>
              <span>Lote:</span>

              <LoteObturador
                value={codigoLote}
                setValue={setCodigoLote}
                onSearch={searchLote}
                statusBlocking={statusBlockingInput}
              />
            </div>

            <div>
              <span>Fecha de Elaboración:</span>
              <CustomDatePicker
                value={fechaElaboracionLote}
                setValue={setFechaElaboracionLote}
                slotProps={{ textField: { fullWidth: true } }}
                disabled={statusBlockingInput}
              />
            </div>

            <div>
              <span>Fecha de Vencimiento:</span>
              <CustomDatePicker
                value={fechaVencimientoLote}
                setValue={setfechaVencimientoLote}
                slotProps={{ textField: { fullWidth: true } }}
                disabled={statusBlockingInput}
              />
            </div>

            <div>
              <span>Cantidad:</span>
              <IntegerInput value={cantidadLote} setValue={setCantidadLote} />
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
            >
              Agregar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={limpiarInputs}
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                backgroundColor: "#DB2C20",
              }}
            >
              Descartar
            </Button>
          </form>

          <TableLotes data={lotesData} setData={setLotesData} />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "10px", backgroundColor: "#009161" }}
            onClick={saveLotes}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </span>
  );
};

export default Lotes;
