import React, { useState, useEffect } from "react";
import Header from "../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import BackIcon from "../../../../components/BackIcon";
import fetchwrapper from "../../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../../components/CustomBackdrop";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ProductsReadable from "../../components/ProductsReadable";
import GrabarIcon from "../../../../assets/iconos/Grabar.ico";
import CustomSelectComponent from "../../../../components/CustomSelectComponent";
import Obturador from "../../../../components/Obturador/Obturador";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const stylesDestino = {
  contenedor: {
    paddingBlock: "15px",
    textAlign: "center",
    borderTop: "5px solid #ccc" /* Borde superior */,
    borderBottom: "5px solid #ccc" /* Borde inferior */,
  },
  titulo: {
    fontSize: "25px",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const MovilizarMovilizacionBahia = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [productosAMovilizarCabecera, setProductosAMovilizarCabecera] =
    useState({});

  function agruparPorSolicitudes(data) {
    const solicitudes = {};

    // Recorrer cada producto en la lista de datos
    data.forEach((item) => {
      const sgasoling = item.sgasoling;

      // Si la solicitud no está en el objeto, agregarla
      if (!solicitudes[sgasoling]) {
        solicitudes[sgasoling] = {
          cabecera: {
            sgasoling,
            bahcodigo: productosAMovilizarCabecera.bahcodigo,
            bahcodigodestino: productosAMovilizarCabecera.bahcodigodestino,
            bahdescripcion: productosAMovilizarCabecera.bahdescripcion,
            bahmetros3: productosAMovilizarCabecera.bahmetros3,
            bodcodigo: productosAMovilizarCabecera.bodcodigo,
            boddescri: productosAMovilizarCabecera.boddescri,
            clicodigo: productosAMovilizarCabecera.clicodigo,
            invcodigo: productosAMovilizarCabecera.invcodigo,
            invdescri: productosAMovilizarCabecera.invdescri,
            izoncodigo: productosAMovilizarCabecera.izoncodigo,
            izoncodigodestino: productosAMovilizarCabecera.izoncodigodestino,
            izondescripcion: productosAMovilizarCabecera.izondescripcion,
            nivcodigo: productosAMovilizarCabecera.nivcodigo,
            nivcodigodestino: productosAMovilizarCabecera.nivcodigodestino,
            palletid: productosAMovilizarCabecera.palletid,
            pascodigo: productosAMovilizarCabecera.pascodigo,
            pascodigodestino: productosAMovilizarCabecera.pascodigodestino,
            pasdescripcion: productosAMovilizarCabecera.pasdescripcion,
            poscodigo: productosAMovilizarCabecera.poscodigo,
            poscodigodestino: productosAMovilizarCabecera.poscodigodestino,
            tradescripcion: productosAMovilizarCabecera.tradescripcion,
          },
          detalle: [],
        };
      }

      // Añadir el producto al detalle de la solicitud correspondiente
      solicitudes[sgasoling].detalle.push(item);
    });

    // Convertir el objeto a un arreglo de subarreglos
    const resultado = Object.keys(solicitudes).map((key) => solicitudes[key]);

    return resultado;
  }

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
  } = useGetProductos();

  //READ hook (get Productos from api)
  function useGetProductos() {
    return useQuery({
      queryKey: ["FiltrosMovilizacionBahroductos"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/movilizacionBahia/getPallet/${location.state.palletId}`
        );
        response = await response.json();

        let cabeceraResponse = response?.data?.cabecera;
        setProductosAMovilizarCabecera(cabeceraResponse);

        let productosResponse = response?.data?.detalle;

        return productosResponse;
      },
    });
  }

  const {
    mutateAsync: saveMovilizacionBahia,
    isPending: isSavingMovilizacionBahia,
  } = useSaveMovilizacionBahia();

  function useSaveMovilizacionBahia() {
    return useMutation({
      mutationFn: async (data) => {
        const options = {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };

        let response = await fetchwrapper(
          `/movilizacionBahia/saveMobilizacion`,
          options
        );

        response = response.json();
        return response;
      },
      onError: () => {
        alert("No se pudo hacer la movilización. Intente otra vez");
      },
      onSuccess: () => {
        alert("Movilización realizada con éxito");
        navigate(-1);
      },
    });
  }
  const handleSaveProductsAMovilizar = async () => {
    let data = {
      detalle: agruparPorSolicitudes(fetchedProductos),
    };

    await saveMovilizacionBahia(data);
  };

  const textIsAlreadyDecodedUbicacion = (infoRead, closeModalQr) => {
    const textDecoded = infoRead?.barcodeDataText;

    if (textDecoded) {
      const [bahia, pasillo, nivel, posicion] =
        infoRead.barcodeDataText.split(";");

      if (
        bahia === productosAMovilizarCabecera?.bahcodigodestino &&
        pasillo === productosAMovilizarCabecera?.pascodigodestino &&
        nivel === productosAMovilizarCabecera?.nivcodigodestino &&
        posicion === productosAMovilizarCabecera?.poscodigodestino
      ) {
        let confirmacion = window.confirm(
          "¿Desea realizar la movilización del pallet?"
        );
        if (confirmacion) {
          closeModalQr();
          handleSaveProductsAMovilizar();
        }
      } else {
        alert(`La ubicacion leída no coincide:\n
        Bahía: ${bahia}\n
        Pasillo: ${pasillo}\n
        Nivel: ${nivel}\n
        Posición:${posicion}\n
        `);
        closeModalQr();
      }
    } else {
      throw new Error("");
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Movilización Bahía</b>
        </div>

        <CustomBackdrop
          isLoading={
            isLoadingProductos ||
            isFetchingProductos ||
            isSavingMovilizacionBahia
          }
        />

        <Box className={StyledRoot}>
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Código de pallet:</strong> {location.state.palletId}
            </p>
            <div style={stylesDestino.contenedor}>
              <b style={stylesDestino.titulo}>Ubicación Destino Pallet</b>
              <div style={{ marginTop: "10px" }}>
                <p>
                  <strong>Zona:</strong>{" "}
                  {productosAMovilizarCabecera?.izoncodigodestino}
                </p>
                <p>
                  <strong>Bahía:</strong>{" "}
                  {productosAMovilizarCabecera?.bahcodigodestino}
                </p>
                <p>
                  <strong>Pasillo:</strong>{" "}
                  {productosAMovilizarCabecera?.pascodigodestino}
                </p>
                <p>
                  <strong>Nivel:</strong>{" "}
                  {productosAMovilizarCabecera?.nivcodigodestino}
                </p>
              </div>
              <p>
                <strong>Posición:</strong>{" "}
                {productosAMovilizarCabecera?.poscodigodestino}
              </p>

              <Obturador
                scannerParams={{
                  msg: "Escanea el código de barras",
                  codeType: "bar",
                }}
                executeFunction={textIsAlreadyDecodedUbicacion}
              />
            </div>
            <br />
            <br />

            <p>
              <strong>Inventario:</strong>{" "}
              {productosAMovilizarCabecera?.invdescri}
            </p>
            <p>
              <strong>Bodega:</strong> {productosAMovilizarCabecera?.boddescri}
            </p>
            <p>
              <strong>Bahía:</strong> {productosAMovilizarCabecera?.bahcodigo}
            </p>
            <p>
              <strong>Zona:</strong>{" "}
              {productosAMovilizarCabecera?.izondescripcion}
            </p>
            <p>
              <strong>Pasillo:</strong> {productosAMovilizarCabecera?.pascodigo}
            </p>
            <p>
              <strong>Posición:</strong>{" "}
              {productosAMovilizarCabecera?.poscodigo}
            </p>
            <p>
              <strong>Nivel:</strong> {productosAMovilizarCabecera?.nivcodigo}
            </p>

            <br />
            <br />
          </div>

          <div>
            {isLoadingProductosError ? (
              <div>No se pudo ejecutar la búsqueda.Intente otra vez</div>
            ) : (
              <>
                {fetchedProductos.length === 0 ? (
                  <div>Este pallet aún no se puede movilizarse a la bahía</div>
                ) : (
                  <>
                    {/* <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSaveProductsAMovilizar}
                      sx={{ marginBottom: "20px" }}
                    >
                      Guardar
                      <span style={{ marginLeft: "8px" }}>
                        <img
                          src={GrabarIcon}
                          alt="guardar"
                          style={{ width: "40px" }}
                        />
                      </span>
                    </Button> */}
                    <ProductsReadable products={fetchedProductos} />
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default MovilizarMovilizacionBahia;
