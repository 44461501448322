import { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";

export default function CustomDatePicker({ value, setValue, ...params }) {
  const theme = useTheme();
  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            value={value}
            onChange={(newValue) => setValue(newValue)}
            {...params}
          />
        </DemoContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
