import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import PracticasaLogo from "../../../../../assets/imgEmpresa/Practicasa/PracticasaLogo.png";
import PracticasaSelloAgua from "../../../../../assets/imgEmpresa/Practicasa/PracticasaSelloAgua.png";
import truncateNumber from "../../../../utils/math/truncate";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    paddingBottom: 10,
  },
  logo: {
    width: 200,
    marginBottom: 10,
  },
  watermark: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-80%, -50%)",
    // opacity: 0.1,
    width: "50%",
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderColor: "#000",
    // marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.6%",
    backgroundColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
  },
  tableCol: {
    width: "16.6%",
    // borderStyle: "solid",
    // borderWidth: 1,
    // borderColor: "#000",
  },
  tableImageCell: {
    margin: 5,
    width: "100%",
    height: "auto",
  },
  tableCellHeader: {
    margin: 5,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
  },
  section: {
    marginBottom: 10,
  },
  sectionCliente: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderBottom: "1px solid black",
    paddingBottom: 10,
    marginBottom: 10,
  },
  sectionTotales: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 10,
    marginBottom: 10,
  },
  title: {
    textAlign: "center",
    marginVertical: 10,
    fontSize: 12,
    fontWeight: "bold",
  },
  totalContainer: {
    marginLeft: "auto",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  column: {
    flexDirection: "column",
    marginLeft: 10,
    alignItems: "flex-end",
  },
  label: {
    fontWeight: "bold",
    padding: "1px 0 1px 0",
  },
  value: {
    textAlign: "right",
    padding: "1px 0 1px 0",
  },

  horizontalLine: {
    borderBottom: "1px solid black",
    marginVertical: 5,
    width: "100%",
  },
  horizontalLineVoid: {
    borderBottom: "1px solid white",
    marginVertical: 5,
    width: "100%",
  },

  signatureSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    paddingTop: 10,
  },
  signatureBlock: {
    flex: 1,
    textAlign: "center",
    margin: "0 5px", // Espacio entre las secciones
  },
  signatureLine: {
    borderTop: "1px solid #000",
    width: 200,
    margin: "10px auto",
  },
  signatureTitle: {
    fontSize: 16,
    marginBottom: 5,
  },
  signatureName: {
    marginTop: 2,
  },
});

const ProformaPDF = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image style={styles.watermark} src={PracticasaSelloAgua} />
      <View style={styles.header}>
        <Image style={styles.logo} src={PracticasaLogo} />
        <View>
          <Text>PractiCASA</Text>
          {/* <Text>C.C.CENTRO LOCAL 37 - FUSIÓN</Text> */}
          <Text>Teléfonos: 0996352966</Text>
          <Text>RUC: 091311170001</Text>
          <Text>GUAYAQUIL - ECUADOR</Text>
          <Text>CONTRIBUYENTE ESPECIAL</Text>
        </View>
      </View>

      <Text style={{ ...styles.title, color: "blue" }}>
        PROFORMA: {data.cabecera.proformaCodigoGenerated} CLIENTE :{" "}
        {data.cabecera.clicodigo} ESTADO : PENDIENTE
      </Text>

      <View style={styles.sectionCliente}>
        <View>
          <Text style={styles.title}>CLIENTE:</Text>
          <Text>NOMBRE: {data.cabecera.clinombre}</Text>
          <Text>DIRECCION: {data.cabecera.clidirec}</Text>
          <Text>N. IDENTIFICACION: {data.cabecera.cliruc}</Text>
          <Text>TELEFONO: {data.cabecera.clitelef1}</Text>
          <Text>ZONA/CIUDAD: {data.cabecera.prodescri}</Text>
        </View>
        <View>
          <Text style={styles.title}></Text>
          <Text>
            FECHA/PEDIDO : {/* {data.cabecera.pedfemi} */}
            {new Date().toLocaleDateString()}
          </Text>
          <Text>
            {/* HORA/PEDIDO : {data.cabecera.pedfemi} */}
            HORA/PEDIDO : {new Date().toLocaleTimeString()}
          </Text>
          <Text>VENDEDOR: {data.cabecera.vennombre}</Text>
          <Text>FORMA/PAGO: {data.cabecera.fordescri}</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{ width: "4%" }}>
            <Text style={styles.tableCellHeader}></Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Imagen</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Cant. Ped</Text>
          </View>
          {/* <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>F. Entrega</Text>
          </View> */}
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Articulo</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Descripción</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Val. Unitario</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>SubT.Bruto</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>Desc</Text>
          </View>
          <View style={styles.tableColHeader}>
            <Text style={styles.tableCellHeader}>SubT.Neto</Text>
          </View>
        </View>

        {data.detalle.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={{ width: "4%" }}>
              <Text style={styles.tableCell}>{index + 1}</Text>
            </View>
            <View style={styles.tableCol}>
              <Image
                style={styles.tableImageCell}
                src={`data:image/jpeg;base64,${item.imagen}`}
              />
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.totalToBuy}</Text>
            </View>
            {/* <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.fechaEntrega}</Text>
            </View> */}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.codigo}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{item.descripcion}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {truncateNumber(item.precio)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {truncateNumber(item.totalPrice)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {truncateNumber(item.porcentajeDescuento)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {truncateNumber(item.totalPriceWithDescuento)}
              </Text>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.sectionTotales}>
        {/* <Text>SON: UN MIL NOVECIENTOS CINCUENTA Y NUEVE CON 29/100</Text> */}

        <View style={styles.totalContainer}>
          <View style={styles.column}>
            <Text style={styles.horizontalLineVoid}></Text>
            <Text style={styles.label}>SubTotal Bruto:</Text>
            <Text style={styles.label}>(-)Descuento por Artículo:</Text>
            <Text style={styles.horizontalLineVoid}></Text>
            <Text style={styles.label}>SubTotal Neto:</Text>
            <Text style={styles.label}>Valor IVA:</Text>
            {/* <Text style={styles.label}>Valor IVA 5%:</Text> */}
            <Text style={styles.horizontalLineVoid}></Text>
            <Text style={styles.label}>Total:</Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.horizontalLine}></Text>
            <Text style={styles.value}>
              {truncateNumber(data.cabecera.subtotalBruto)}
            </Text>
            <Text style={styles.value}>
              {truncateNumber(data.cabecera.descuentoTotal)}
            </Text>
            <Text style={styles.horizontalLine}></Text>
            <Text style={styles.value}>
              {truncateNumber(data.cabecera.subtotalNeto)}
            </Text>
            <Text style={styles.value}>
              {truncateNumber(data.cabecera.valorIVATotal)}
            </Text>
            {/* <Text style={styles.value}>{truncateNumber(data.cabecera.iva5)}</Text> */}
            <Text style={styles.horizontalLine}></Text>
            <Text style={styles.value}>
              {parseFloat(data.cabecera.subtotalNeto.toFixed(2)) === 0
                ? 0.00
                : (
                    parseFloat(data.cabecera.subtotalNeto.toFixed(2)) +
                    parseFloat(data.cabecera.pediva.toFixed(2))
                  ).toFixed(2)}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          ...styles.section,
          borderBottom: "1px solid black",
          paddingBottom: 10,
          marginBottom: 10,
          color: "red",
        }}
      >
        <Text>
          NOTA: LOS PRECIOS AQUÍ COTIZADOS ESTÁN SUJETOS A CAMBIOS SIN PREVIO
          AVISO.
        </Text>
      </View>

      {/* <View style={styles.section}>
        <Text>CARTERA:</Text>
        <Text>Cuota: {data.cabecera.cuota}</Text>
        <Text>Valor: {data.cabecera.valor}</Text>
        <Text>Referencia: {data.cabecera.referencia}</Text>
      </View> */}

      <View style={styles.section}>
        <Text>Observación:</Text>
      </View>

      <View style={styles.signatureSection}>
        <View style={styles.signatureBlock}>
          <View style={styles.signatureLine} />
          <Text style={styles.signatureName}>Vendedor</Text>
        </View>
        <View style={styles.signatureBlock}>
          <View style={styles.signatureLine} />
          <Text style={styles.signatureName}>Aprobación</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default ProformaPDF;
