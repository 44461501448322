import * as React from "react";
import { Button, TextField, Box, Alert } from "@mui/material";
import { useCartStore } from "../../../../../stores/cartStore";
import math from "../../../../utils/math/index";

export default function PriceSelector({ producto, handleClose, selectedItem }) {
  const MIN_CANTIDAD = 0.1;
  const [currentCantidad, setCurrentCantidad] = React.useState(1);
  const MAX_CANTIDAD = parseInt(producto.cabecera.cantidad);
  const [warning, setWarning] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { add } = useCartStore();

  const handleInputChange = (event) => {
    const value = parseFloat(event.target.value);
    if (value > MAX_CANTIDAD || value < MIN_CANTIDAD) {
      return;
    }
    setCurrentCantidad(value);
  };

  const handleIncrease = () => {
    const newCantidad = parseFloat((currentCantidad + 0.1).toFixed(2));
    if (newCantidad >= MAX_CANTIDAD) {
      return;
    }
    setCurrentCantidad(newCantidad);
  };

  const handleDecrease = () => {
    const newCantidad = parseFloat((currentCantidad - 0.1).toFixed(2));
    if (newCantidad <= MIN_CANTIDAD) {
      return;
    }
    setCurrentCantidad(newCantidad);
  };

  const handleAddClick = async () => {
    try {
      if (!selectedItem) {
        setWarning(true);
        return;
      }

      if (!selectedItem.bodcodigo) {
        setWarning(true);
        return;
      }

      const price = math.number(producto.cabecera.precio);
      const priceIVA = price * (producto.cabecera.sysiva / 100);
      const totalIVA = priceIVA * currentCantidad;
      const totalPrice = price * currentCantidad;
      const totalPriceWithIVA = totalPrice + totalIVA;

      const product = {
        ...producto.cabecera,
        bodegas: [...producto.cuerpo],
        priceIVA,
        price,
        totalToBuy: currentCantidad,
        maxTotalStockToBuy: MAX_CANTIDAD,
        totalPrice,
        totalIVA,
        totalPriceWithIVA,
      };
      
      // Manejo de la excepción al agregar al carrito
      try {
        add(product);
      } catch (err) {
        console.error("Error al agregar al carrito:", err);
        setError("Ocurrió un error al intentar agregar el producto al carrito.");
      }

      handleClose();
    } catch (err) {
      console.error("Error en el manejo del clic:", err);
      setError("Ocurrió un error inesperado.");
    }
  };

  return (
    <>
      {warning && (
        <Alert severity="warning" onClose={() => setWarning(false)}>
          Debes seleccionar un registro válido antes de agregar al carrito.
        </Alert>
      )}
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {producto.cabecera.cantidad >= MIN_CANTIDAD && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button variant="outlined" onClick={handleDecrease}>
            -
          </Button>
          <TextField
            type="number"
            value={currentCantidad}
            onChange={handleInputChange}
            sx={{ mx: 2, width: "6rem" }}
            inputProps={{
              min: MIN_CANTIDAD,
              max: MAX_CANTIDAD,
              step: 0.1,
            }}
          />
          <Button variant="outlined" onClick={handleIncrease}>
            +
          </Button>
        </div>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "1.5rem",
        }}
      >
        <Button onClick={handleClose} sx={{ textAlign: "center" }}>
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={handleAddClick}
          sx={{ textAlign: "center" }}
          style={{ backgroundColor: "#196C87" }}
          disabled={!selectedItem || !selectedItem.bodcodigo}
        >
          Agregar
        </Button>
      </Box>
    </>
  );
}
