import React from "react";

// Dashboard
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagement from "../dashboard/ProductManagement";

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";

//Protected routes
import { FiltrarProformas, SubMenu } from "../pages";
import {
  CatalogoProductos,
  FiltrosCatalogosProductos,
  EmisionQR,
  Proforma,
} from "../pages";
import { SimpleMenu } from "../pages";

// Consultas e imformes
import {
  VtasDiasLocalidad,
  BcosDiasSaldos,
  BcosDiasSaldosDetail,
} from "../pages";

//Proceso de paletizacion
import {
  Paletizacion,
  BuscarPaletizacion,
  CrearPaletizacion,
  EjecutarPaletizacion,
  EjecutarBuscarPaletizacion,
  BuscarUbicacionPaletizacion,
  BuscarLotesPaletizacion,
} from "../pages";

//Banco de preguntas
import {
  BancosDePreguntas,
  CrearBancosDePreguntas,
  EditarBancosDePreguntas,
} from "../pages";

// Gestion de almacen - Procesos
import {
  GestionAlmacenProcesos,
  CrearGestionAlmacenProceso,
  EditarGestionAlmacenProceso,
} from "../pages";

//Formularios Procesos
import {
  FormulariosProcesos,
  CrearFormulario,
  EditarFormulario,
} from "../pages";
import IntegracionExterna from "../pages/IntegracionExterna";
import SolicitudEgreso from "../pages/SolicitudEgreso";
import SolicitudEgresoDetalle from "../pages/SolicitudEgresoDetalle";
import CuerpoProforma from "../pages/Proforma/components/EmisionProforma";

// Verificacion de Documental
import {
  VerificacionDocumental,
  BuscarVerificacionDocumental,
  FormulariosReadable,
  CrearVerificacionDocumental,
  Formularios,
} from "../pages";

//Descarga en cuarentena
import {
  DescargaCuarentena,
  CrearDescargaCuarentena,
  VerTodosProductos,
  BuscarDescargaCuarentena,
  VerTodosProductosReadable,
  VerTodosArticulosIngreso,
} from "../pages";

//Asignacion de Ubicacion
import {
  AsignacionUbicacion,
  CrearAsignacionUbicacion,
  CrearTodosIngresosReadableAsignacionUbicacion,
  CrearTodosArticulosIngresoAsignacionUbicacion,
  BuscarAsignacionUbicacion,
  BuscarTodosIngresosReadableAsignacionUbicacion,
  BuscarTodosArticulosIngresoAsignacionUbicacion,
  ActualizarAsignacionUbicacion,
  ActualizarTodosIngresosReadableAsignacionUbicacion,
  ActualizarTodosArticulosIngresoAsignacionUbicacion,
} from "../pages";

// Verificacion Quimico
import {
  VerificacionQuimico,
  CrearVerificacionQuimico,
  VerificarVerificacionQuimico,
  BuscarVerificacionQuimico,
  VerificadoVerificacionQuimico,
} from "../pages";

// Movilizacion Bahia
import {
  MovilizacionBahia,
  CrearMovilizacionBahia,
  MovilizarMovilizacionBahia,
} from "../pages";

//Verificacion de Codigo de Barras
import { VerificacionCodigoDeBarras } from "../pages";

const protectedRoutes = [
  { path: "Submenu/:label/:id", element: <SubMenu /> },
  { path: "dashboard/Emisión QR / Barras", element: <EmisionQR /> },
  { path: "dashboard/Proforma/:id", element: <Proforma /> },
  { path: "dashboard/Proformas / Pedidos", element: <FiltrarProformas /> },
  {
    path: "dashboard/Proformas / EmisionProforma",
    element: <CuerpoProforma />,
  },

  { path: "dashboard/Creacion de Catalogos", element: <CatalogoProductos /> },
  {
    path: "dashboard/Creacion de Catalogos/Filtros",
    element: <FiltrosCatalogosProductos />,
  },
  { path: "dashboard/VtasDiasLocalidad", element: <VtasDiasLocalidad /> },
  { path: "dashboard/BcosDiasSaldos", element: <BcosDiasSaldos /> },
  {
    path: "dashboard/BcosDiasSaldos/:bcocodigo",
    element: <BcosDiasSaldosDetail />,
  },
  { path: "dashboard/Paletizacion", element: <Paletizacion /> },
  { path: "dashboard/Paletizacion", element: <Paletizacion /> },
  { path: "dashboard/Paletizacion/Crear", element: <CrearPaletizacion /> },
  {
    path: "dashboard/Paletizacion/Crear/Ejecutar",
    element: <EjecutarPaletizacion />,
  },
  { path: "dashboard/Paletizacion/Buscar", element: <BuscarPaletizacion /> },
  {
    path: "dashboard/Paletizacion/Buscar/Ejecutar",
    element: <EjecutarBuscarPaletizacion />,
  },
  {
    path: "dashboard/Paletizacion/Buscar/Ejecutar/:idArticulo",
    element: <BuscarUbicacionPaletizacion />,
  },
  {
    path: "dashboard/Paletizacion/Buscar/Ejecutar/:idArticulo/:numLotes",
    element: <BuscarLotesPaletizacion />,
  },
  { path: "dashboard/BancoDePreguntas", element: <BancosDePreguntas /> },
  {
    path: "dashboard/BancoDePreguntas/crear",
    element: <CrearBancosDePreguntas />,
  },
  {
    path: "dashboard/BancoDePreguntas/editar/:id",
    element: <EditarBancosDePreguntas />,
  },
  {
    path: "dashboard/Procesos",
    element: <GestionAlmacenProcesos />,
  },
  {
    path: "dashboard/Procesos",
    element: <GestionAlmacenProcesos />,
  },
  {
    path: "dashboard/Procesos/crear",
    element: <CrearGestionAlmacenProceso />,
  },
  {
    path: "dashboard/Procesos/editar/:proceso",
    element: <EditarGestionAlmacenProceso />,
  },
  {
    path: "dashboard/FormulariosParaProcesos",
    element: <FormulariosProcesos />,
  },
  {
    path: "dashboard/FormulariosParaProcesos/crear",
    element: <CrearFormulario />,
  },
  {
    path: "dashboard/FormulariosParaProcesos/editar/:formularioID",
    element: <EditarFormulario />,
  },
  { path: "dashboard/IntegracionExterna", element: <IntegracionExterna /> },
  { path: "dashboard/SolicitudEgreso", element: <SolicitudEgreso /> },
  {
    path: "dashboard/SolicitudEgresoDetalle/:id",
    element: <SolicitudEgresoDetalle />,
  },
  {
    path: "dashboard/verificacionDocumental",
    element: <VerificacionDocumental />,
  },
  {
    path: "dashboard/verificacionDocumental/Buscar",
    element: <BuscarVerificacionDocumental />,
  },
  {
    path: "dashboard/verificacionDocumental/Buscar/Formularios",
    element: <FormulariosReadable />,
  },
  {
    path: "dashboard/verificacionDocumental/Crear",
    element: <CrearVerificacionDocumental />,
  },
  {
    path: "dashboard/verificacionDocumental/Crear/Formularios",
    element: <Formularios />,
  },
  {
    path: "dashboard/descargaCuarentena",
    element: <DescargaCuarentena />,
  },
  {
    path: "dashboard/descargaCuarentena/crear",
    element: <CrearDescargaCuarentena />,
  },
  {
    path: "dashboard/descargaCuarentena/crear/ver",
    element: <VerTodosProductos />,
  },
  {
    path: "dashboard/descargaCuarentena/buscar",
    element: <BuscarDescargaCuarentena />,
  },
  {
    path: "dashboard/descargaCuarentena/buscar/ver",
    element: <VerTodosProductosReadable />,
  },
  {
    path: "dashboard/descargaCuarentena/buscar/ver/articulos",
    element: <VerTodosArticulosIngreso />,
  },
  {
    path: "dashboard/asignacionUbicacion",
    element: <AsignacionUbicacion />,
  },
  // {
  //   path: "dashboard/asignacionUbicacion/crear",
  //   // element: <CrearAsignacionUbicacion />,  #No se usa se redirecciona a asignacionUbicacion/crear/ver
  // },
  {
    path: "dashboard/asignacionUbicacion/crear/ver",
    element: <CrearTodosIngresosReadableAsignacionUbicacion />,
  },
  {
    path: "dashboard/asignacionUbicacion/crear/ver/articulos",
    element: <CrearTodosArticulosIngresoAsignacionUbicacion />,
  },
  {
    path: "dashboard/asignacionUbicacion/buscar",
    element: <BuscarAsignacionUbicacion />,
  },
  {
    path: "dashboard/asignacionUbicacion/buscar/ver",
    element: <BuscarTodosIngresosReadableAsignacionUbicacion />,
  },
  {
    path: "dashboard/asignacionUbicacion/buscar/ver/articulos",
    element: <BuscarTodosArticulosIngresoAsignacionUbicacion />,
  },
  // {
  //   path: "dashboard/asignacionUbicacion/actualizar",
  //   element: <ActualizarAsignacionUbicacion />, #No se usa se redirecciona a asignacionUbicacion/actualizar/ver
  // },
  {
    path: "dashboard/asignacionUbicacion/actualizar/ver",
    element: <ActualizarTodosIngresosReadableAsignacionUbicacion />,
  },
  {
    path: "dashboard/asignacionUbicacion/actualizar/ver/articulos",
    element: <ActualizarTodosArticulosIngresoAsignacionUbicacion />,
  },
  {
    path: "dashboard/verificacionQuimico",
    element: <VerificacionQuimico />,
  },
  {
    path: "dashboard/verificacionQuimico/crear",
    element: <CrearVerificacionQuimico />,
  },
  {
    path: "dashboard/verificacionQuimico/crear/verificar",
    element: <VerificarVerificacionQuimico />,
  },
  {
    path: "dashboard/verificacionQuimico/buscar",
    element: <BuscarVerificacionQuimico />,
  },
  {
    path: "dashboard/verificacionQuimico/buscar/verificado",
    element: <VerificadoVerificacionQuimico />,
  },
  {
    path: "dashboard/movilizacionBahia",
    element: <MovilizacionBahia />,
  },
  {
    path: "dashboard/movilizacionBahia/crear",
    element: <CrearMovilizacionBahia />,
  },
  {
    path: "dashboard/movilizacionBahia/crear/movilizar",
    element: <MovilizarMovilizacionBahia />,
  },

  {
    path: "dashboard/verificacionCodigoDeBarras",
    element: <VerificacionCodigoDeBarras />,
  },
];

// const protectedRoutes = [
//   { path: "dashboard/finance", element: <FinanceMonitoring /> },
//   { path: "dashboard/events", element: <EventManagement /> },
//   { path: "dashboard/sales", element: <SalesMonitoring /> },
//   { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
//   { path: "dashboard/crypto", element: <Cryptocurrency /> },
//   { path: "dashboard/helpdesk", element: <HelpdeskService /> },
//   { path: "dashboard/storage", element: <StorageManagement /> },
//   { path: "dashboard/product", element: <ProductManagement /> },
//   { path: "apps/gallery-music", element: <GalleryMusic /> },
//   { path: "apps/gallery-video", element: <GalleryVideo /> },
//   { path: "apps/tasks", element: <Tasks /> },
//   { path: "apps/contacts", element: <Contacts /> },
//   { path: "apps/chat", element: <Chat /> },
//   { path: "apps/calendar", element: <AppCalendar /> },
//   { path: "apps/email", element: <Email /> },
//   { path: "apps/file-manager", element: <FileManager /> },
//   { path: "pages/pricing", element: <Pricing /> },
//   { path: "pages/faq", element: <Faq /> },
//   { path: "pages/profile", element: <Profile /> },
//   { path: "pages/people", element: <People /> },
//   { path: "pages/activity", element: <Activity /> },
//   { path: "pages/events", element: <Events /> },
//   { path: "pages/settings", element: <Settings /> },
//   { path: "docs/layout/grid", element: <LayoutGrid /> },
//   { path: "docs/layout/columns", element: <LayoutColumns /> },
//   { path: "docs/layout/gutters", element: <LayoutGutters /> },
//   { path: "docs/com/accordions", element: <Accordions /> },
//   { path: "docs/com/alerts", element: <Alerts /> },
//   { path: "docs/com/avatars", element: <Avatars /> },
//   { path: "docs/com/badge", element: <Badges /> },
//   { path: "docs/com/breadcrumbs", element: <Breadcrumbs /> },
//   { path: "docs/com/buttons", element: <Buttons /> },
//   { path: "docs/com/cards", element: <Cards /> },
//   { path: "docs/com/carousel", element: <Carousels /> },
//   { path: "docs/com/dropdown", element: <Dropdowns /> },
//   { path: "docs/com/images", element: <Images /> },
//   { path: "docs/com/listgroup", element: <Listgroup /> },
//   { path: "docs/com/markers", element: <Markers /> },
//   { path: "docs/com/modal", element: <Modals /> },
//   { path: "docs/com/navtabs", element: <NavTabs /> },
//   { path: "docs/com/offcanvas", element: <OffCanvas /> },
//   { path: "docs/com/pagination", element: <Paginations /> },
//   { path: "docs/com/placeholders", element: <Placeholders /> },
//   { path: "docs/com/popovers", element: <Popovers /> },
//   { path: "docs/com/progress", element: <Progress /> },
//   { path: "docs/com/spinners", element: <Spinners /> },
//   { path: "docs/com/toasts", element: <Toasts /> },
//   { path: "docs/com/tooltips", element: <Tooltips /> },
//   { path: "docs/com/tables", element: <Tables /> },
//   { path: "docs/form/elements", element: <FormElements /> },
//   { path: "docs/form/selects", element: <FormSelects /> },
//   { path: "docs/form/checksradios", element: <FormChecksRadios /> },
//   { path: "docs/form/range", element: <FormRange /> },
//   { path: "docs/form/pickers", element: <FormPickers /> },
//   { path: "docs/form/layouts", element: <FormLayouts /> },
//   { path: "docs/chart/apex", element: <ApexCharts /> },
//   { path: "docs/chart/chartjs", element: <ChartJs /> },
//   { path: "docs/map/leaflet", element: <MapLeaflet /> },
//   { path: "docs/map/vector", element: <MapVector /> },
//   { path: "docs/icon/remix", element: <IconRemix /> },
//   { path: "docs/icon/feather", element: <IconFeather /> },
//   { path: "docs/util/background", element: <UtilBackground /> },
//   { path: "docs/util/border", element: <UtilBorder /> },
//   { path: "docs/util/colors", element: <UtilColors /> },
//   { path: "docs/util/divider", element: <UtilDivider /> },
//   { path: "docs/util/flex", element: <UtilFlex /> },
//   { path: "docs/util/sizing", element: <UtilSizing /> },
//   { path: "docs/util/spacing", element: <UtilSpacing /> },
//   { path: "docs/util/opacity", element: <UtilOpacity /> },
//   { path: "docs/util/position", element: <UtilPosition /> },
//   { path: "docs/util/typography", element: <UtilTypography /> },
//   { path: "docs/util/shadows", element: <UtilShadows /> },
//   { path: "docs/util/extras", element: <UtilExtras /> }
// ]

export default protectedRoutes;
