import React, { useState, useEffect } from "react";
import Header from "../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";

import BackIcon from "../../../../components/BackIcon";

import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import fetchwrapper from "../../../../services/interceptors/fetchwrapper";
import DatagridEjecutar from "../../components/DatagridEjecutar";
import CustomBackdrop from "../../../../components/CustomBackdrop";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const StyledIcons = styled(NavLink)(({ theme }) => ({
  height: 250,
  width: 250,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  border: "1px solid #ddd",
  cursor: "pointer",

  "& img": {
    width: "128px",
  },
}));

const StyledTextIcon = styled("div")(({ theme }) => ({
  fontSize: "18px",
  marginTop: "10px",
  fontWeight: "bolder",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const EjecutarPaletizacion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataFiltered, setDataFiltered] = useState([]);
  const [isLoadingFilteringData, setIsLoadingFilteringData] = useState(false)

  const filtrarBusqueda = async () => {
    try {
      setIsLoadingFilteringData(true)
      const options = {
        method: "POST",
        body: JSON.stringify({
          ...location.state,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      //Detect what api is calling
      const typeOfFilter = location?.state?.type;
      let apiUrl = "";
      switch (typeOfFilter) {
        case "barcodeUbicacion":
          apiUrl = "/paletizacion/getAllFiltrosCrearXUbicacion";
          break;
        default:
          apiUrl = "/paletizacion/getAllFiltrosCrear";
          break;
      }
      let response = await fetchwrapper(apiUrl, options);
      response = await response.json();

      setDataFiltered(response.data);
    } catch (err) {
      alert("Error en filtrar los datos");
      navigate(-1);
    }finally{
      setIsLoadingFilteringData(false)
    }
  };

  useEffect(() => {
    filtrarBusqueda();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Paletizacion</b>
        </div>
        <CustomBackdrop isLoading={isLoadingFilteringData}/>

        <Box className={StyledRoot}>
          <DatagridEjecutar
            data={dataFiltered}
            setData={setDataFiltered}
            filtrarBusqueda={filtrarBusqueda}
          />
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default EjecutarPaletizacion;
