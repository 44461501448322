import { Grid, Autocomplete, TextField } from "@mui/material/";
import { useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { createFilterOptions } from "@mui/material/Autocomplete";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import Chip from '@mui/material/Chip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedICON = <CheckBoxIcon fontSize="small" />;

const InputMultivalueFiltrarProductos = ({ label, placeholder, setCodes, fetchLink, fetchResponse }) => {
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [counter, setCounter] = useState(0);
  const [values, setValues] = useState(0)

  const handleSelectOption = (event, newValue) => {
    // if select all is selected, choose all the options
    if (newValue === null) {
      setSelectAll(false);
      setSelectedValues([]);
      setCodes([]);
      return;
    }
    //check if first value or last value is select all
    //do this only if not empty newValue
    if (newValue.length === 0) {
      setSelectAll(false);
      setSelectedValues([]);
      setCodes([]);
      return;
    }
    setSelectAll(false);
    if (newValue[0][fetchResponse.code] === "seleccionar-todos" || newValue[newValue.length - 1][fetchResponse.code] === "seleccionar-todos") {
      //if the input is empty and select all is unchecked, select all
      if (inputValue.length === 0 && !selectAll) {
        setSelectAll(true);
        setCounter(options.length)
        setSelectedValues(options.slice(0, 10));
        setCodes(options.map((option) => option[fetchResponse.code]));
        return;
      }
      setSelectAll(true);
      setSelectedValues(filteredOptions);
      setCodes(filteredOptions.map((option) => option[fetchResponse.code]));
      //if select all is checked, unchecked it
      if (selectAll) {
        setSelectAll(false);
        setSelectedValues([]);
        setCodes([]);
        return;
      }
      return;

    }
    //if select all is not selected, choose only the options that are selected
    const newValues = newValue.filter((option) => {
      return option[fetchResponse.code] !== "seleccionar-todos";
    });
    setSelectedValues(newValues);
    if (counter <=10) {
      setCounter(newValue.length);
    }
    else{
      newValue.length <= values || values===0 ? setCounter(counter-1) : setCounter(counter+1)
      setValues(newValue.length)
    }
    setCodes(newValues.map((option) => option[fetchResponse.code]));
  };

  const filterOptions = createFilterOptions({
    limit: 10,
    matchFrom: "any",
    stringify: (option) => option[fetchResponse.description],
  });

  const addSelectAllOptions = (options) => {
    if (options) {
      //this only if there are options
      if (options.length === 0) {
        return [];
      }
      return [
        {
          [fetchResponse.code]: "seleccionar-todos",
          [fetchResponse.description]: "Seleccionar todos",
        },
        ...options,
      ];
    }
  };

  useEffect(() => {
    const getOptions = async () => {
      const cliciaciacodigo = JSON.parse(localStorage.getItem("jwt")).seleccion.cliciaciacodigo;
      try {
        const request = {
          user: "­v}xg",
          seleccion:
          {
            cliciaciacodigo: cliciaciacodigo,
            cliciacianombre: "PRACTICASA",
            clicianonBD: "SiacPracticasa",
            cliciarutaBD: "fsoftapptest.futuresoft-ec.com,14666"
          },
          localidad:
          {
            loccodigo: "07",
            locdescri: "BODEGA SAMBO"
          }
        };
        const options = {
          method: "POST",
          body: JSON.stringify(request),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        let response = await fetchwrapper(fetchLink, options);
        response = await response.json();
        setOptions(response);
      } catch (err) {
        console.error("error");
      }
    };
    getOptions();
  }, []);

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    // filter the options based on the user's input
    if (newInputValue.length === 0) {
      setFilteredOptions(options);
      setCounter(0)
      return;
    }
    if (!newInputValue) {
      setFilteredOptions(options);
      return;
    }
    const recommendedOptions = options.filter((option) => {
      return option[fetchResponse.description].toLowerCase().includes(newInputValue.toLowerCase());
    });

    // set the filtered options as the autocomplete suggestions
    setFilteredOptions(recommendedOptions);
  };

  const filterAndAddSelectAllOptions = (options, params) => {
    const filtered = filterOptions(options, params);
    const optionsWithSelectAll = addSelectAllOptions(filtered);
    return optionsWithSelectAll;
  };

  const handleCheckboxChange = (selected) => {
    selected ? setCounter(counter-1) : setCounter(counter+1)
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Autocomplete
        multiple
        id="size-small-outlined-multi"
        size="small"
        disableCloseOnSelect
        options={options}
        value={selectedValues}
        getOptionLabel={(option) => option[fetchResponse.description]}
        onInputChange={handleInputChange}
        onChange={handleSelectOption}
        sx={{maxWidth:"75vw"}}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={placeholder} key={options[fetchResponse.description]} />
        )}
        filterOptions={filterAndAddSelectAllOptions}
        renderOption={(props, option, { selected }) => {
          const isSelectAllOption = option[fetchResponse.code] === "seleccionar-todos";
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedICON}
                style={{ marginRight: 8 }}
                checked={selected || (isSelectAllOption && selectAll)}
                onChange={() => handleCheckboxChange(selected)}
              />
              {option[fetchResponse.description]}
            </li>
          );
        }}
        ListboxProps={{ style: { maxHeight: '200px', overflowY: 'auto' } }}
        renderTags={(value, getTagProps) => {
          const maxDisplayedTags = 3;
          const selectedTags = value.slice(0, maxDisplayedTags);
          const hiddenTagsCount = value.length - selectedTags.length;
      
          return (
            <div>
              {selectedTags.map((option, index) => (
                <Chip
                  key={index}
                  label={option[fetchResponse.description]}
                  style={{ margin: '4px', fontSize: '11px', padding: '4px', maxWidth: '80%' }}
                  {...getTagProps({ index })}
                />
              ))}
              {hiddenTagsCount > 0 && <span>+{hiddenTagsCount} más</span>}
            </div>
          );
        }}
      />
        {selectedValues.length > 0 && (
          <p>Elementos seleccionados: {counter}</p>
        )}


    </Grid>
  );
};

export default InputMultivalueFiltrarProductos;
