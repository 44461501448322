// import React, { useState, useEffect } from "react";

// import CloseIcon from "@mui/icons-material/Close";
// import { IconButton, Grid, Typography, TextField, Button } from "@mui/material";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import SearchIcon from "@mui/icons-material/Search";
// import InputFiltrarClienteProforma from "./InputFiltrarClientesProforma";
// import fetchwrapper from "../../../services/interceptors/fetchwrapper";
// import CustomBackdrop from "../../../components/CustomBackdrop";

// const ModalCreateClient = ({
//   initialValues,
//   handleCloseModalCreateClient,
//   setCabeceraProforma,
//   cabeceraProforma,
// }) => {
//   const [formData, setFormData] = useState({}); // Estado para manejar los datos del formulario
//   const [isLoadingCreatingCliente, setIsLoadingCreatingCliente] =
//     useState(false);

//   //   useEffect(() => {
//   //     // Inicializar formData con los valores iniciales
//   //     setFormData(initialValues);
//   //   }, [initialValues]);

//   const handleSubmit = async (values, { setSubmitting }) => {
//     setSubmitting(false);
//     console.log(values);
//     console.log("cabeceradesdecliente");
//     console.log(cabeceraProforma);

//     try {
//       setIsLoadingCreatingCliente(true);
//       // Aquí se envia los datos del cliente al backend para procesarlos
//       const request = {
//         clinombre: values.nombre,
//         cliruc: values.cedulaRuc,
//         cliemail: values.correo,
//         clitelef1: values.telefono,
//         clidirec: values.direccion,
//       };
//       const options = {
//         method: "POST",
//         body: JSON.stringify(request),
//         headers: {
//           "Content-Type": "application/json",
//           "Access-Control-Allow-Origin": "*",
//         },
//       };
//       let result = await fetchwrapper("/proformas/createCliente", options);
//       result = await result.json();
//       setCabeceraProforma({
//         ...cabeceraProforma,
//         clinombre: values.nombre,
//         clicodigo: result.cliccodigo,
//       });

//       alert("Nuevo cliente creado");

//       // Limpia el formulario después de enviar los datos
//       //   clearModalCreateClient();
//       setFormData({});

//       //Cerrar el modal
//       handleCloseModalCreateClient();
//     } catch (err) {
//       console.log(err);
//       alert("No se pudo crear el cliente");
//     } finally {
//       setIsLoadingCreatingCliente(false);
//     }
//   };

//   //   const handleSearchInput = async (inputId, valuesForm, setValuesForm) => {
//   //     //Fetch info existing cliente
//   //     const searchText = valuesForm[inputId];
//   //     console.log(searchText);
//   //     console.log("eeeeeeeeeeeeeee111");

//   //     const newFormValues = {
//   //       nombre: "",
//   //       cedulaRuc: "",
//   //       correo: "",
//   //       telefono: "",
//   //       direccion: "",
//   //     };
//   //     await setValuesForm(newFormValues);
//   //   };

//   return (
//     <div>
//       <CustomBackdrop isLoading={isLoadingCreatingCliente} zIndex={1000000} />
//       <div style={{ marginLeft: "96%" }}>
//         <IconButton onClick={handleCloseModalCreateClient}>
//           <CloseIcon />
//         </IconButton>
//       </div>
//       <Typography variant="h3" align="center" gutterBottom>
//         Crear Cliente
//       </Typography>
//       <Formik
//         enableReinitialize={true}
//         initialValues={formData}
//         validationSchema={Yup.object({
//           nombre: Yup.string()
//             .max(100, "Debe ser máximo 100 carácteres o menos")
//             .required("Este campo es obligatorio"),
//           cedulaRuc: Yup.string()
//             .matches(/^[0-9]+$/, "Debe ser un valor numérico")
//             .test(
//               "tipo",
//               "El número debe ser de cédula (10 caracteres) o RUC (13 caracteres)",
//               (value) => {
//                 const length = value ? value.length : 0;
//                 return (
//                   (length === 10 && /^[0-9]{10}$/.test(value)) ||
//                   (length === 13 && /^[0-9]{13}$/.test(value))
//                 );
//               }
//             )
//             .required("Este campo es obligatorio"),
//           correo: Yup.string().email("Dirección de email inválida"),
//           //.required("Este campo es obligatorio"),
//           telefono: Yup.string()
//             .matches(/^[0-9]+$/, "Debe ser un valor numérico")

//             .required("Este campo es obligatorio"),
//           direccion: Yup.string()
//             .max(100, "Debe ser máximo 100 carácteres o menos")
//             .required("Este campo es obligatorio"),
//         })}
//         onSubmit={handleSubmit}
//       >
//         {(formik) => (
//           <form onSubmit={formik.handleSubmit}>
//             <Grid container spacing={2}>
//               {/* <Grid item xs={12} sm={12} md={12}>
//                 <InputFiltrarClienteProforma
//                   setFormData={setFormData}
//                   cabeceraProforma={cabeceraProforma}
//                   setCabeceraProforma={setCabeceraProforma}
//                 />
//               </Grid> */}

//               {/* const newFormValues = {
//         nombre: data.data.clinombre??"",
//         cedulaRuc: data.data.cliruc??"",
//         correo: data.data.cliemail??"",
//         telefono: data.data.clitelef1??"",
//         direccion: data.data.clidirec??"",
//       }; */}

//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   id="nombre"
//                   label="Nombre*"
//                   {...formik.getFieldProps("nombre")}
//                   error={formik.touched.nombre && Boolean(formik.errors.nombre)}
//                   helperText={formik.touched.nombre && formik.errors.nombre}
//                   value={formik.values.nombre} // Usa value directamente de formik.values
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   id="cedulaRuc"
//                   label="Cédula o Ruc*"
//                   {...formik.getFieldProps("cedulaRuc")}
//                   error={
//                     formik.touched.cedulaRuc && Boolean(formik.errors.cedulaRuc)
//                   }
//                   helperText={
//                     formik.touched.cedulaRuc && formik.errors.cedulaRuc
//                   }
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                   /*InputProps={{
//                     endAdornment: (
//                       <IconButton
//                         onClick={() =>
//                           handleSearchInput(
//                             "cedulaRuc",
//                             formik.values,
//                             formik.setValues
//                           )
//                         }
//                       >
//                         <SearchIcon />
//                       </IconButton>
//                     ),
//                   }}*/
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   id="correo"
//                   label="Correo*"
//                   {...formik.getFieldProps("correo")}
//                   error={formik.touched.correo && Boolean(formik.errors.correo)}
//                   helperText={formik.touched.correo && formik.errors.correo}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   id="telefono"
//                   label="Teléfono*"
//                   {...formik.getFieldProps("telefono")}
//                   error={
//                     formik.touched.telefono && Boolean(formik.errors.telefono)
//                   }
//                   helperText={formik.touched.telefono && formik.errors.telefono}
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   id="direccion"
//                   label="Dirección*"
//                   {...formik.getFieldProps("direccion")}
//                   error={
//                     formik.touched.direccion && Boolean(formik.errors.direccion)
//                   }
//                   helperText={
//                     formik.touched.direccion && formik.errors.direccion
//                   }
//                   InputLabelProps={{
//                     shrink: true,
//                   }}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   type="submit"
//                   style={{ background: "#114B5E", color: "white" }}
//                 >
//                   Crear
//                 </Button>
//               </Grid>
//             </Grid>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default ModalCreateClient;

import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../components/CustomBackdrop";

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
  position: "relative",
  border: "1px solid #0072B1",
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-0.75em",
  left: "1em",
  backgroundColor: theme.palette.background.paper,
  // padding: `0 ${theme.spacing(2)}px`,
  padding: "0 16px",
}));

const FlexRow = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  flexDirection: "column", // Default to column
  [theme.breakpoints.up("md")]: {
    flexDirection: "row", // Change to row on medium screens and up
  },
}));

const tipoIdentificacionOptions = [
  { value: "C", label: "Cédula" },
  { value: "R", label: "RUC" },
  { value: "P", label: "Pasaporte" },
];

const Formulario = ({
  handleCloseModalCreateClient,
  cabeceraProforma,
  setCabeceraProforma,
}) => {
  const [isLoadingCreatingCliente, setIsLoadingCreatingCliente] =
    useState(false);
  const [formData, setFormData] = useState({
    codigo: "", // Codigo cliente generado o ya buscado
    tipoIdentificacion: "",
    nIdentificacion: "",
    nombre: "",
    direccion: "",
    telefono1: "",
    telefono2: "",
    fax: "",
    celular: "",
    email: "",
    // ruc: "",
    // razonSocial: "",
  });
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const resetModalCliente = async () => {
    await refetchCodigoClienteGenerated();
    setFormData((prev) => ({
      ...prev,
      codigo: fetchedCodigoClienteGenerated,
      tipoIdentificacion: "",
      nombre: "",
      direccion: "",
      telefono1: "",
      telefono2: "",
      fax: "",
      celular: "",
      email: "",
      // ruc: "",
      // razonSocial: "",
    }));
  };

  // -------------------------------------------
  //               QUERYS
  // -------------------------------------------
  const {
    data: fetchedFormData = {},
    isError: isLoadingFormDataError,
    isFetching: isFetchingFormData,
    isLoading: isLoadingFormData,
    refetch: refetchFormData,
    error: formDataError,
  } = useGetFormData();

  function useGetFormData() {
    return useQuery({
      queryKey: ["FormDataProformaExisted"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/proformas/getClienteWithNumIndentificacion/${formData.nIdentificacion}`
        );
        response = await response.json();
        response = response?.data;
        if (Object.keys(response).length === 0 || !response) {
          await resetModalCliente();
        }
        return response;
      },
      refetchOnWindowFocus: false,
      enabled: false,
    });
  }

  const {
    data: fetchedCodigoClienteGenerated = "",
    isError: isLoadingCodigoClienteGeneratedError,
    isFetching: isFetchingCodigoClienteGenerated,
    isLoading: isLoadingCodigoClienteGenerated,
    refetch: refetchCodigoClienteGenerated,
  } = useGetCodigoClienteGenerated();

  function useGetCodigoClienteGenerated() {
    return useQuery({
      queryKey: ["useGetCodigoClienteGeneratedProformaCliente"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/proformas/getLastClienteCodigoAvaliable`
        );
        response = await response.json();
        const codigoClienteResponse = response?.data?.clienteCodigoGenerated;

        return codigoClienteResponse ?? "";
      },
      refetchOnWindowFocus: false,
      enabled: false,
    });
  }
  // -------------------------------------------

  useEffect(() => {
    refetchCodigoClienteGenerated();
  }, []);

  useEffect(() => {
    if (fetchedCodigoClienteGenerated) {
      setFormData((prev) => ({
        ...prev,
        codigo: fetchedCodigoClienteGenerated,
      }));
    }
  }, [fetchedCodigoClienteGenerated]);

  useEffect(() => {
    if (fetchedFormData && Object.keys(fetchedFormData).length !== 0) {
      setFormData(() => ({
        codigo: fetchedFormData.clicodigo,
        tipoIdentificacion: fetchedFormData.cliidentifica,
        nIdentificacion: fetchedFormData.cliruc,
        nombre: fetchedFormData.clinombre,
        direccion: fetchedFormData.clidirec,
        telefono1: fetchedFormData.clitelef1,
        telefono2: fetchedFormData.clitelef2,
        fax: fetchedFormData.clifax,
        celular: "",
        email: fetchedFormData.cliemail,
        ruc: fetchedFormData.clirucmatriz,
        razonSocial: fetchedFormData.clinommatriz,
      }));
    }
  }, [fetchedFormData]);

  useEffect(() => {
    if (formDataError?.error) {
      alert(
        "No se pudo hacer la consulta si ese número de identificación existe en la base de datos"
      );
    }
  }, [formDataError?.error]);

  const handleSubmit = async (e) => {
    const {
      tipoIdentificacion,
      nIdentificacion,
      nombre,
      direccion,
      telefono1,
      telefono2,
      celular,
      fax,
      email,
    } = formData;

    // -----VERIFICACIONES-----
    const regexExpression = /^[0-9]+$/;

    if (!regexExpression.test(nIdentificacion))
      return alert("El número de identificacion no es válido");

    if (!nIdentificacion || !tipoIdentificacion)
      return alert("Complete los campos de identificación");

    if (tipoIdentificacion === "C" && nIdentificacion.length !== 10)
      return alert("Digite correctamente el número de cédula");
    if (tipoIdentificacion === "R" && nIdentificacion.length !== 13)
      return alert("Digite correctamente el ruc");

    // -----SAVE CLIENTE-----

    try {
      setIsLoadingCreatingCliente(true);
      // Aquí se envia los datos del cliente al backend para procesarlos
      const request = {
        cliidentifica: tipoIdentificacion,
        cliruc: nIdentificacion,
        clinombre: nombre,
        clidirec: direccion,
        clitelef1: telefono1,
        clitelef2: telefono2,
        celular: celular,
        clifax: fax,
        cliemail: email,
        clirucmatriz: nIdentificacion,
        clinommatriz: nombre,
      };
      const options = {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };
      let result = await fetchwrapper("/proformas/createCliente", options);
      result = await result.json();
      console.log({ clinombre: nombre, clicodigo: result.clicodigo },"aquii");
      setCabeceraProforma({
        ...cabeceraProforma,
        clinombre: nombre,
        clicodigo: result.clicodigo,
      });

      alert("Cliente procesado correctamente");

      // Limpia el formulario después de enviar los datos
      //   clearModalCreateClient();
      setFormData({});

      //Cerrar el modal
      handleCloseModalCreateClient();
    } catch (err) {
      console.log(err);
      alert("No se pudo crear el cliente");
    } finally {
      setIsLoadingCreatingCliente(false);
    }

    console.log(formData, tipoIdentificacion);
  };

  const handleBlurOrEnter = (e) => {
    if (e.type === "blur" || (e.type === "keypress" && e.key === "Enter")) {
      if (!formData.nIdentificacion) return;
      refetchFormData();
    }
  };

  return (
    <>
      <CustomBackdrop
        isLoading={
          isLoadingFormData ||
          isFetchingFormData ||
          isLoadingCodigoClienteGenerated ||
          isFetchingCodigoClienteGenerated ||
          isLoadingCreatingCliente
        }
        zIndex={1000000}
      />
      <Box sx={{ p: 2 }}>
        <Section elevation={3}>
          <SectionTitle variant="h6">Datos Generales</SectionTitle>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlexRow>
                <TextField
                  label="Código"
                  name="codigo"
                  value={formData.codigo}
                  InputProps={{
                    readOnly: true,
                    style: { backgroundColor: "#f0f0f0" }, // Fondo gris para indicar que está bloqueado
                  }}
                  fullWidth
                  variant="outlined"
                  disabled
                />
                <TextField
                  select
                  label="Tipo de Identificación"
                  name="tipoIdentificacion"
                  value={formData.tipoIdentificacion}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                >
                  {tipoIdentificacionOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Número de Identificación"
                  name="nIdentificacion"
                  value={formData.nIdentificacion}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  onBlur={handleBlurOrEnter}
                  onKeyPress={handleBlurOrEnter}
                  autoComplete="off"
                />
              </FlexRow>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Nombre"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Dirección"
                name="direccion"
                value={formData.direccion}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <FlexRow>
                <TextField
                  label="Teléfono 1"
                  name="telefono1"
                  value={formData.telefono1}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
                <TextField
                  label="Teléfono 2"
                  name="telefono2"
                  value={formData.telefono2}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              </FlexRow>
            </Grid>

            <Grid item xs={12}>
              <FlexRow>
                <TextField
                  label="Celular"
                  name="celular"
                  value={formData.celular}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
                <TextField
                  label="Fax"
                  name="fax"
                  value={formData.fax}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              </FlexRow>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                autoComplete="off"
              />
            </Grid>
          </Grid>
          <br />
          <br />

          {/* <Section elevation={3}>
            <SectionTitle variant="h6">Datos Matriz</SectionTitle>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="RUC"
                  name="nIdentificacion"
                  value={formData.nIdentificacion}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Razón Social"
                  name="nombre"
                  value={formData.nombre}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </Section> */}

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#114B5E", color: "white" }}
              fullWidth
              onClick={() => handleSubmit()}
            >
              Guardar
            </Button>
          </Grid>
        </Section>
      </Box>
    </>
  );
};

export default Formulario;
