import { createContext, useContext, useState } from "react";

const verificacionDocumentalContext = createContext();

export function useVerificacionDocumentalContext() {
  return useContext(verificacionDocumentalContext);
}

export function VerificacionDocumentalProvider({ children }) {
  //   const [transporteForm, setTransporteForm] = useState({
  //     cabecera: {
  //       ciacodigo: "01",
  //       formcodigo: "FOA2300002701",
  //       formdescri: "OPE-REG-SC-33",
  //       procesocod: "Check List Transporte Recepción",
  //     },
  //     preguntas: [
  //       {
  //         pregcodigo: "OPA2300004401",
  //         pregtipo: "U",
  //         pregobligatoria: -1,
  //         pregdescri: "Fecha de hoy",
  //         opciones: [],
  //       },
  //       {
  //         pregcodigo: "OPA2300004501",
  //         pregtipo: "L",
  //         pregobligatoria: -1,
  //         pregdescri: "Es color gris",
  //         opciones: [
  //           {
  //             opcion: "SI",
  //             pregRespuesta: "1",
  //             pregsecuen: 1,
  //           },
  //           {
  //             opcion: "NO",
  //             pregRespuesta: "0",
  //             pregsecuen: 2,
  //           },
  //         ],
  //       },
  //       {
  //         pregcodigo: "OPA2300004601",
  //         pregtipo: "M",
  //         pregobligatoria: 0,
  //         pregdescri: "Tipos de colores",
  //         opciones: [
  //           {
  //             opcion: "amarillo",
  //             pregRespuesta: "0",
  //             pregsecuen: 1,
  //           },
  //           {
  //             opcion: "rojo",
  //             pregRespuesta: "0",
  //             pregsecuen: 2,
  //           },
  //           {
  //             opcion: "verde",
  //             pregRespuesta: "0",
  //             pregsecuen: 3,
  //           },
  //         ],
  //       },
  //     ],
  //   });

  const [forms, setForms] = useState([]);

  const setSpecificForm = (index, newForm) => {
    setForms((prev) => {
      const _prev = [...prev];
      _prev[index] = newForm;

      return _prev;
    });
  };

  const values = {
    forms,
    setForms,
    setSpecificForm,
  };

  return (
    <verificacionDocumentalContext.Provider value={values}>
      {children}
    </verificacionDocumentalContext.Provider>
  );
}
