import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import TaskIcon from "@mui/icons-material/Task";
import AssignmentIcon from "@mui/icons-material/Assignment";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField, FormControl, MenuItem, Select } from "@mui/material";

import normalFormatDate from "../../utils/date/DDMMYYYFormatDate";
import { styled } from "@mui/system";

const DisabledTextField = ({ label = "", value }) => {
  return (
    <span style={{ flexGrow: 1 }}>
      <TextField
        fullWidth
        id={`outlined`}
        variant="standard"
        label={label}
        value={value ?? ""}
        disabled
      />
    </span>
  );
};

const CustomAccordion = ({ product }) => {
  const styles = {
    accordionSummary: {
      backgroundColor: "rgb(25, 108, 135)",
      color: "white",
      fontWeight: "bolder",
    },
    row: {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: "10px",
    },
    rowTitle: {
      alignSelf: "end",
      paddingRight: "20px",
      fontWeight: "bolder",
    },
  };

  const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiSelect-selectMenu": {
      minWidth: 80,
      overflowX: "scroll",
    },
  }));


  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${product.artcodigo}`}
        id={product.artcodigo}
        style={styles.accordionSummary}
      >
        {`${product.artcodigo} - ${product.artdescri}`}
      </AccordionSummary>
      <AccordionDetails>
        {/* EMBALAJES */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Embalaje:</span>
          <DisabledTextField value={product.embcodigo} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Embalaje:</span>
          <DisabledTextField value={product.embdescripcion} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cúbicos Embalaje:</span>
          <DisabledTextField value={product.embmetros3} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Conversión:</span>
          <DisabledTextField value={product.embpieza} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cuadrados:</span>
          <DisabledTextField value={product.embmetros2} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Tono:</span>
          <DisabledTextField value={product.toncodigo} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Tono:</span>
          <DisabledTextField value={product.tondescripcion} />
        </div>

        {/* UBICACION */}
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Bahía:</span>
          <DisabledTextField value={product.bahcodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Bahía:</span>
          <DisabledTextField value={product.bahdescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Metros Cúbicos Bahía:</span>
          <DisabledTextField value={product.bahmetros3} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Zona:</span>
          <DisabledTextField value={product.izoncodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Zona:</span>
          <DisabledTextField value={product.izondescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Pasillo:</span>
          <DisabledTextField value={product.pascodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Descripción Pasillo:</span>
          <DisabledTextField value={product.pasdescripcion} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Nivel:</span>
          <DisabledTextField value={product.nivcodigo} />
        </div>
        <div style={styles.row}>
          <span style={styles.rowTitle}>Código Posición:</span>
          <DisabledTextField value={product.poscodigo} />
        </div>

        {/* CALCULO DE CANTIDADES */}
        {/* <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Solicitada:</span>
          <DisabledTextField value={product.sgacansol} />
        </div> */}

        <div style={styles.row}>
          <span style={styles.rowTitle}>Cantidad Recibida:</span>
          <DisabledTextField value={Number(product.cantidadEmbalada)} />
        </div>

        <div style={styles.row}>
          <span style={styles.rowTitle}>Series/Lote Registrados:</span>
          <FormControl
            fullWidth
            variant="standard"
            sx={{ m: 1, minWidth: 80, overflowX: "scroll" }}
          >
            <CustomSelect
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              MenuProps={{
                PaperProps: { style: { maxHeight: 200, overflowX: "scroll" } },
              }}
            >
              {product.lotes.map((lote) => (
                <MenuItem value="">{`${lote.codigoLote} Cant:${Number(
                  lote.cantidadLote
                )} Elab:${normalFormatDate(
                  lote.fechaElaboracionLote
                )} Vence:${normalFormatDate(
                  lote.fechaVencimientoLote
                )}`}</MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ProductsInventarioReadable = ({ productsCuarentena = [] }) => {
  return (
    <div>
      {productsCuarentena.map((product) => (
        <CustomAccordion key={product.artcodigo} product={product} />
      ))}
    </div>
  );
};

export default ProductsInventarioReadable;
