import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Paper,
  Typography,
  Button,
  Modal,
  IconButton,
} from "@mui/material";
import { format } from "date-fns"; // Importa la función de formato de fecha de date-fns

import { createTheme, ThemeProvider } from "@mui/material/styles";

import Header from "../../../layouts/Header";

import CrearProforma from "./CrearProforma";
import CreateProformaModal from "../../FiltrarProformas/components/CreateProformaModal";

import math from "../../utils/math";
import ModalAddProduct from "./ModalAddProduct";
import { useCartStore } from "../../../stores/cartStore";
import EditableTableDetalleProforma from "./EditableTableDetalleProforma";
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate";
import BackIcon from "../../../components/BackIcon";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../components/CustomBackdrop";
import AutocompleteClientes from "./AutocompleteClientes";
// import ModalCreateClient from "./ModalCreateClient";
import InputSelectorFormaPago from "./InputSelectorFormaPago";
import InputSelectorVendedor from "./InputSelectorVendedor";
import { FcPlus } from "react-icons/fc";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

//Estilos para Datagrid y botones de filtros
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const styleModalAddProduct = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "70vh",
  maxHeight: "70vh", // Establece una altura máxima para el modal para asegurar que no se desborde del viewport
  overflowY: "auto", //
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const styleModalCreateClient = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "80vh",
  maxHeight: "80vh", // Establece una altura máxima para el modal para asegurar que no se desborde del viewport
  overflowY: "auto", //
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

//Estilos para el color
const theme = createTheme({
  palette: {
    primary: {
      main: "#E8DFD1",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const ProformaEditable = ({
  idProforma,
  cabeceraProforma,
  setCabeceraProforma,
  detallesProforma,
  setDetallesProforma,
  setSpecificQuantity,
  getTotalsPrices,
}) => {
  const { clearUseCartStoreStorage } = useCartStore();
  const [totalPrices, setTotalPrices] = useState({
    subTotal: 0,
    IVA: 0,
    total: 0,
  });
  const [fechaEmision, setFechaEmision] = useState(new Date());
  const [comment, setComment] = useState(cabeceraProforma["peddetalle"]);

  const [openModalAddProduct, setOpenModalAddProduct] = React.useState(false);
  const handleOpenModalAddProduct = () => {
    setOpenModalAddProduct(true);
  };
  const handleCloseModalAddProduct = () => {
    clearUseCartStoreStorage();
    setOpenModalAddProduct(false);
  };

  const [openModalCreateClient, setOpenModalCreateClient] = useState(false);
  const handleOpenModalCreateClient = () => {
    setOpenModalCreateClient(true);
  };
  const handleCloseModalCreateClient = () => {
    console.log("hare");
    console.log(cabeceraProforma);
    console.log(clienteInfo);
    setOpenModalCreateClient(false);
  };

  React.useEffect(() => {
    const { subTotal, IVA, total } = getTotalsPrices(detallesProforma);
    setTotalPrices({
      subTotal,
      IVA,
      total,
    });
  }, [detallesProforma, detallesProforma.length]);

  // const [openModalCliente, setOpenModalCliente] = useState(false);
  // const handleOpenModalCliente = () => setOpenModalCliente(true);
  // const handleCloseModalCliente = () => setOpenModalCliente(false);

  /* *****************************************************
                      Cliente QUERY
  ****************************************************** */
  // const [searchTextCliente, setSearchTextCliente] = useState(
  //   cabeceraProforma.clinombre
  // ); //Estado del textfield cliente en el ui

  //Estado del cliente usado tambien en el modal de agregar cliente

  const [clienteInfo, setClienteInfo] = useState({
    nombre: "",
    cedulaRuc: "",
    correo: "",
    telefono: "",
    direccion: "",
  });

  const {
    data: fetchedCliente = [],
    isError: isLoadingClienteError,
    isPending: isFetchingClientes,
    isLoading: isLoadingCliente,
    refetch: getCliente,
  } = useGetCliente();

  useEffect(() => {
    try {
      getCliente();
    } catch (error) {
      console.log(error);
    }
  }, [cabeceraProforma.clinombre]);

  useEffect(() => {
    if (Object.keys(fetchedCliente) !== 0) {
      setClienteInfo({
        nombre: fetchedCliente.clinombre,
        cedulaRuc: fetchedCliente.cliruc,
        correo: fetchedCliente.cliemail,
        telefono: fetchedCliente.clitelef1,
        direccion: fetchedCliente.clidirec,
      });
    }
  }, [fetchedCliente]);

  if (isLoadingClienteError) {
    console.log("Error en cargar los clientes");
  }

  //READ hook (get Clientes from api)
  function useGetCliente() {
    return useQuery({
      queryKey: ["Cliente"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/proformas/getCliente/${cabeceraProforma.clinombre}`
        );

        response = await response.json();
        console.log(response);
        return response.data;
      },
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    });
  }

  // const handleSearchCliente = async () => {
  //   if (!searchTextCliente) {
  //     alert("Digite el nombre o el ruc del cliente");
  //     return;
  //   }
  //   try {
  //     //Get el cliente searched
  //     const { data } = await getCliente();

  //     //Manejo de lo que se actualiza cuando se encuentra o no un cliente

  //     //Si el cliente que se encontro existe entonces se actualza la cabcera y el estado del textfield cliente
  //     if (Object.keys(data).length !== 0) {
  //       const { clicodigo, clinombre } = data;
  //       //Set new cabecera
  //       setCabeceraProforma((prev) => ({ ...prev, clicodigo, clinombre }));

  //       //Set new name in field cliente
  //       setSearchTextCliente(clinombre);
  //     } else {
  //       // Si el cliente no se encuentra entonces vuelves al poner en el textfield el cliente que estaba antes
  //       alert("No se pudo encontrar ese cliente");
  //       setSearchTextCliente(cabeceraProforma.clinombre);
  //     }
  //   } catch (error) {
  //     alert("No se pudo encontrar el cliente");
  //   }
  // };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Header />
        <div className="main main-app p-3 p-lg-4">
          <BackIcon />
          <div className="d-md-flex align-items-center justify-content-between mb-4"></div>
          <CustomBackdrop isLoading={isLoadingCliente || isFetchingClientes} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "30px",
            }}
          >
            <Paper
              style={{
                padding: "16px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* *****************************************************
                                  CABECERA
              ****************************************************** */}
              <Typography style={{ margin: "10px" }} variant="h6">
                Información de {cabeceraProforma.pednumped}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id=""
                    label="PROFORMA"
                    disabled
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    disabled
                    id="fecha-emision"
                    label="Fecha de Emisión"
                    value={normalFormatDate(cabeceraProforma.pedfecemi)}
                    //value={format(fechaEmision, "dd/MM/yyyy")} // Formatea la fecha en el formato deseado

                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="fecha-vence"
                    label="Fecha de Vencimiento"
                    value={normalFormatDate(cabeceraProforma.pedfecven)}
                    //value={format(fechaEmision, "dd/MM/yyyy")} // Formatea la fecha en el formato deseado
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled
                    fullWidth
                  />
                </Grid>
                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="NumPed"
                    defaultValue={idProforma}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <InputSelectorFormaPago
                    cabeceraProforma={cabeceraProforma}
                    setCabeceraProforma={setCabeceraProforma}
                  ></InputSelectorFormaPago>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputSelectorVendedor
                    cabeceraProforma={cabeceraProforma}
                    setCabeceraProforma={setCabeceraProforma}
                  ></InputSelectorVendedor>
                </Grid>

                {/* <DatePicker
                  key={cabeceraProforma.pedfecemi}
                  label="Fecha de Creación"
                  // setDate={setFechaInicio}
                  date={cabeceraProforma?.pedfecemi}
                  disabled={true}
                /> */}
                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Fecha de Creación"
                    defaultValue={normalFormatDate(cabeceraProforma.pedfecisys)}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}

                {/* <div>
                  <Modal
                    open={openModalCliente}
                    onClose={handleCloseModalCliente}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          minHeight: "50%",
                          marginBottom: "20px",
                        }}
                      >
                        <TextField
                          label="Nombre"
                          name="firstName"
                          value={"dfd"}
                          // onChange={handleInputChange}
                          required
                        />
                        <TextField
                          label="Apellidos"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                          required
                        />
                        <TextField
                          label="Correo Electrónico"
                          type="email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                        <TextField
                          label="Teléfono"
                          name="phone"
                          value={formData.phone}
                          onChange={handleInputChange}
                          required
                        />
                      </Box>

                      <Button
                        variant="contained"
                        color="primary"
                        // onClick={addCliente}
                      >
                        Agregar cliente
                      </Button>
                    </Box>
                  </Modal>
                </div> */}

                <Grid item xs={12} sm={6} md={4}>
                  {/* <TextField
                    key={cabeceraProforma.clinombre}
                    id="outlined-read-only-input"
                    label="Cliente"
                    defaultValue={cabeceraProforma.clinombre}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModalCliente}
                  >
                    agregar
                  </Button> */}
                  <TextField
                    fullWidth
                    label="Cliente"
                    variant="outlined"
                    value={cabeceraProforma.clinombre}
                    InputProps={{
                      readOnly: true,

                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setOpenModalCreateClient(true);
                          }}
                        >
                          <FcPlus />
                        </IconButton>
                      ),
                    }}
                  />
                  {/* <AutocompleteClientes
                    handleSearchCliente={handleSearchCliente}
                    searchText={searchTextCliente}
                    setSearchText={setSearchTextCliente}
                  /> */}
                  {/* *****************************************************
                                   MODAL CREAR CLIENTE
                  ****************************************************** */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Modal
                        open={openModalCreateClient}
                        onClose={handleCloseModalCreateClient}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        {/* <Box sx={{ ...styleModalCreateClient }}>
                          <ModalCreateClient
                            initialValues={clienteInfo}
                            handleCloseModalCreateClient={
                              handleCloseModalCreateClient
                            }
                            cabeceraProforma={cabeceraProforma}
                            setCabeceraProforma={setCabeceraProforma}
                          />
                        </Box> */}
                      </Modal>
                    </div>
                  </div>
                </Grid>

                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    key={cabeceraProforma.pedstatus}
                    id="outlined-read-only-input"
                    label="Estado"
                    defaultValue={cabeceraProforma.pedstatus}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid>*/}

                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    key={cabeceraProforma.vennombre}
                    id="outlined-read-only-input"
                    label="Vendedor"
                    value={cabeceraProforma.vennombre}
                    defaultValue={cabeceraProforma.vennombre}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}

                {/*<Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-read-only-input"
                    label="Empresa"
                    defaultValue={
                      JSON.parse(localStorage.getItem("jwt")).seleccion
                        .cliciacianombre
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                  />
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Observacion Cliente"
                    multiline
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    fullWidth
                  />
                </Grid>

                {/* <CreateProformaModal /> Old create proforma modal */}

                {/* *****************************************************
                                   MODAL AGREGAR PRODUCTO
                  ****************************************************** */}
                {/* 
                <Grid container sx={{ marginTop: "15px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>
                      <Button
                        onClick={handleOpenModalAddProduct}
                        variant="contained"
                        style={{ background: "#114B5E", color: "white" }}
                      >
                        <span className="me-2">Agregar Productos</span>
                        <FcPlus size={"25px"} />

                      </Button>

                      <Modal
                        open={openModalAddProduct}
                        onClose={handleCloseModalAddProduct}
                        aria-labelledby="parent-modal-title"
                        aria-describedby="parent-modal-description"
                      >
                        <Box sx={{ ...styleModalAddProduct }}>
                          <ModalAddProduct
                            detallesProforma={detallesProforma}
                            setDetallesProforma={setDetallesProforma}
                            idProforma={idProforma}
                            handleCloseModalAddProduct={
                              handleCloseModalAddProduct
                            }
                          />

                         
                        </Box>
                      </Modal>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </Paper>
          </div>

          {/* *****************************************************
                                  DETALLE (TABLE)
              ****************************************************** */}

          <EditableTableDetalleProforma
            detallesProforma={detallesProforma}
            setDetallesProforma={setDetallesProforma}
            setSpecificQuantity={setSpecificQuantity}
          />

          {/* *****************************************************
                                  TOTAL PRICES
              ****************************************************** */}

          <Box sx={{ marginLeft: "15px", paddingTop: "25px" }}>
            <div>
              <p>
                {" "}
                <strong>Subtotal:</strong>
                {` $${math.round(totalPrices.subTotal, 2)}`}
                {cabeceraProforma.pedsubtot}
              </p>
            </div>
            <div>
              <p>
                <strong>IVA:</strong>
                {` $${math.round(totalPrices.IVA, 2)}`}
                {cabeceraProforma.pediva}
              </p>
            </div>
            <div>
              <p>
                <strong>Total:</strong>
                {` $${math.round(totalPrices.total, 2)}`}
                {cabeceraProforma.pedtotal}
              </p>
            </div>

            <div>
              <CrearProforma
                pedNum={idProforma}
                detalleProforma={detallesProforma}
                cabeceraProforma={cabeceraProforma}
              />
            </div>
          </Box>
        </div>
      </ThemeProvider>
    </>
  );
};

export default ProformaEditable;
