import React, { useState } from "react";
import Button from "@mui/material/Button";

const App = ({
  enModoLlenado,
  comenzarLlenadoPallets,
  terminarLlenadoPallets,
  palletLleno,
  children,
  fetchedProductos,
}) => {
  return (
    <div>
      {fetchedProductos?.length === 0 ? (
        <div>No quedan mas productos por ingresar</div>
      ) : (
        <>
          {/* <h1>Gestión de Palet</h1> */}
          {enModoLlenado ? (
            <div>
              {/* Aquí colocarías la interfaz para llenar el palet */}
              <Button variant="contained" onClick={palletLleno}>
                Pallet lleno
              </Button>
              <Button variant="contained" onClick={terminarLlenadoPallets}>
                Cancelar
              </Button>
              <br />
              <br />
              {children}
            </div>
          ) : (
            <div>
              <Button variant="contained" onClick={comenzarLlenadoPallets}>
                Llenar Palet
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default App;
