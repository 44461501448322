import React, { useEffect, useState } from "react";
import ReactPDF from "@react-pdf/renderer";
import ReactDOM from "react-dom";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ProformaPDF from "./ProformaPDF.js";
import { pdf } from "@react-pdf/renderer";
import { Button } from "@mui/material";
import { FcDocument } from "react-icons/fc";

const PDFButton = ({ proforma,handleCloseModalPdf }) => {
  const [key, setKey] = useState(0);
  const handlePrint = async (url, blob) => {
    // Open the PDF in a new window and print it
    const newWindow = window.open(url, "_blank");
    newWindow.onload = () => {
      newWindow.print();
      handleCloseModalPdf()
    };
  };

  const handleRegenerate = () => {
    setKey((prevKey) => prevKey + 1); // Cambia la clave para forzar la regeneración del PDFViewer
  };

  useEffect(()=>{
    console.log(JSON.stringify(proforma), "web");

  },[proforma])
  return (
    <div>
      <PDFDownloadLink
        document={<ProformaPDF data={proforma} />}
        fileName="proforma.pdf"
      >
        {({ loading, url, error, blob }) =>
          loading ? (
            <button>Cargando...</button>
          ) : error ? (
            <div>Error al cargar el documento pd</div>
          ) : (
            <Button
              onClick={() => handlePrint(url, blob)}
              style={{ marginTop: "20px" }}
              variant="contained"
              sx={{
                marginRight: "15px",
                marginTop: "20px",
                marginBottom: "10px",
              }}
              color="secondary"
            >
              <span className="me-2">Descargar Proforma</span>
              <FcDocument size={"25px"} />
            </Button>
          )
        }
        {/* 
        <Button
          onClick={() => handlePrint(url, blob)}
          style={{ marginTop: "20px" }}
          variant="contained"
          sx={{ marginRight: "15px", marginTop: "20px", marginBottom: "10px" }}
          color="secondary"
        >
          <span className="me-2">otra cosa</span>
          <FcDocument size={"25px"} />
        </Button> */}
      </PDFDownloadLink>
      <PDFViewer key={key} style={{ width: "100%", height: "80vh" }}>
        <ProformaPDF data={proforma} />
      </PDFViewer>
    </div>
  );
};

export default PDFButton;
