import React, { useState } from "react";
import Header from "../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from "@mui/material";

import BackIcon from "../../../components/BackIcon";
import CustomBackdrop from "../../../components/CustomBackdrop";
import { useNavigate } from "react-router-dom";
import Obturador from "../../../components/Obturador/Obturador";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const CrearMovilizacionBahia = () => {
  const navigate = useNavigate();
  const [palletCode, setPalletCode] = useState("");

  const isValidCodePallet = (code) => {
    const formatCode = String(code).trim();
    const codeChars = formatCode.substring(0, 3);
    const codeNums = formatCode.substring(3);

    return (
      formatCode !== null &&
      formatCode !== "" &&
      codeChars === "PLA" &&
      /^\d+$/.test(codeNums)
    );
  };

  const searchPallet = (e, palletCode, data = {}) => {
    if (e) e.preventDefault();
    const navigateData = {
      palletId: palletCode,
      ...data,
    };

    if (isValidCodePallet(navigateData?.palletId)) {
      navigate("movilizar", { state: navigateData });
    } else {
      alert("Código de pallet inválido");
    }
  };
  const textIsAlreadyDecodedPallet = (infoRead) => {
    const textDecoded = infoRead?.barcodeDataText;

    if (textDecoded) {
      const data = {
        type: "barcodePallet",
        palletId: `PLA${textDecoded}`,
      };

      searchPallet(null, null, data);
    } else {
      throw new Error("");
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Movilización Bahía</b>
        </div>

        <Box className={StyledRoot}>
          <form onSubmit={(e) => searchPallet(e, palletCode)}>
            <TextField
              fullWidth
              label="Código de pallet"
              id="codigoPallet"
              value={palletCode}
              onChange={(event) => {
                setPalletCode(event.target.value);
              }}
              style={{
                marginBottom: "10px",
              }}
            />

            <Obturador
              scannerParams={{
                msg: "Escanea el código de barras",
                codeType: "bar",
              }}
              executeFunction={textIsAlreadyDecodedPallet}
            />

            <br />
            <br />

            <Button
              variant="contained"
              color="primary"
              onClick={(e) => searchPallet(e, palletCode)}
            >
              Buscar
            </Button>
          </form>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default CrearMovilizacionBahia;
