import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  // createRow,
  useMaterialReactTable,
} from "material-react-table";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import { esES } from "@mui/material/locale";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { Box, Button, IconButton } from "@mui/material";
import { TextField, InputAdornment, Modal } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
const SingleRowSelectionTable = ({
  columnsTable,
  dataTable,
  idEachRow,
  setProducto,
  handleCloseModal,
}) => {
  const theme = useTheme();
  const [rowSelection, setRowSelection] = useState({});
  const columns = useMemo(() => columnsTable);
  const data = dataTable;

  const table = useMaterialReactTable({
    localization: { ...MRT_Localization_ES },
    columns,
    data,
    enableRowSelection: true,
    enableMultiRowSelection: false, //use radio buttons instead of checkboxes
    getRowId: (row) => row[idEachRow], //give each row a more useful id
    muiTableBodyRowProps: ({ row }) => ({
      //add onClick to row to select upon clicking anywhere in the row
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
    positionToolbarAlertBanner: "bottom", //move the alert banner to the bottom
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection }, //pass our managed row selection state to the table to use
    initialState: { pagination: { pageSize: 10 } },
  });

  const handleSelectSingleRow = () => {
    try {
      let rowSelected = table.getSelectedRowModel().rows[0];
      rowSelected = rowSelected.original;
      setProducto(rowSelected);
    } catch (error) {
      console.log(error);
    } finally {
      handleCloseModal();
    }
  };
  return (
    <ThemeProvider theme={createTheme(theme, esES)}>
      <MaterialReactTable table={table} />
      <div style={{ clear: "both" }}>
        <Button
          variant="text"
          color="primary"
          style={{ float: "right" }}
          onClick={handleSelectSingleRow}
        >
          Establecer
        </Button>
      </div>
    </ThemeProvider>
  );
};

const SearchTexfield = ({
  idProducto,
  idEachRow,
  value,
  setProducto,
  columnsTable,
  dataTable,
}) => {
  const styles = {
    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      height: "100vh",
      overflowY: "scroll",
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    },
    closeStyle: {
      cursor: "pointer",
      marginLeft: "95%",
    },
  };
  const [open, setOpen] = useState(false); //modal
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);
  return (
    <span style={{ flexGrow: 1 }}>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <IconButton style={styles.closeStyle} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <SingleRowSelectionTable
            columnsTable={columnsTable}
            dataTable={[...dataTable]}
            idEachRow={idEachRow}
            setProducto={setProducto}
            handleCloseModal={handleCloseModal}
          />
        </Box>
      </Modal>

      <TextField
        fullWidth
        id={`outlined-${idProducto}`}
        variant="standard"
        value={value ?? ""}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={handleOpenModal}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </span>
  );
};

export default SearchTexfield;
