import { useState } from "react";
import Header from "../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, TextField, Button } from "@mui/material";

import BackIcon from "../../components/BackIcon";

import { NavLink } from "react-router-dom";

import Obturador from "../../components/Obturador/Obturador";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../components/CustomBackdrop";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});

const VerificacionCodigoDeBarras = () => {
  const [artCode, setArtCode] = useState("");

  const {
    data: fetchedCodigoBarra = {},
    isError: isLoadingCodigoBarraError,
    isFetching: isFetchingCodigoBarra,
    isLoading: isLoadingCodigoBarra,
    refetch: refetchCodigoBarra,
  } = useGetCodigoBarra();

  //READ hook (get FiltrosCB from api)
  function useGetCodigoBarra() {
    return useQuery({
      queryKey: ["VerificacionCodigoBarras"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/verificacionCodigoDeBarras/getCodigoBarraFixed/${artCode}`
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
      enabled: false,
    });
  }

  const isValidCodeArticulo = (code) => {
    const formatCode = String(code).trim();

    return formatCode !== null && formatCode !== "" && /^\d+$/.test(formatCode);
  };
  const searchArticulo = async (e, artCode, data = {}) => {
    if (e) e.preventDefault();
    const navigateData = {
      codigoArticulo: artCode,
      ...data,
    };

    if (isValidCodeArticulo(navigateData?.codigoArticulo)) {
      setArtCode(navigateData.codigoArticulo);
      refetchCodigoBarra(navigateData.codigoArticulo);
    } else {
      alert("Código de artículo inválido");
    }
  };
  const textIsAlreadyDecodedArticulo = (infoRead) => {
    const textDecoded = infoRead?.barcodeDataText;

    if (textDecoded) {
      const data = {
        type: "codigoArticulo",
        codigoArticulo: `${textDecoded}`,
      };

      searchArticulo(null, data.codigoArticulo, data);
    } else {
      throw new Error("");
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Verificación Código de Barras</b>
        </div>

        <Box className={StyledRoot}>
          <form
            onSubmit={(e) => {
              searchArticulo(e, artCode);
            }}
          >
            <TextField
              fullWidth
              id="outlined-controlled"
              label="Código del artículo"
              value={artCode}
              onChange={(event) => {
                setArtCode(event.target.value);
              }}
            />
            <br />
            <br />
            <Obturador
              scannerParams={{
                msg: "Escanea el código de barras",
                codeType: "bar",
              }}
              executeFunction={textIsAlreadyDecodedArticulo}
            />
            <br />
            <br />

            <Button variant="contained" color="primary" type="submit">
              Buscar
            </Button>
          </form>

          <br />
          <br />

          <CustomBackdrop
            isLoading={isLoadingCodigoBarra || isFetchingCodigoBarra}
          />

            {Object.keys(fetchedCodigoBarra).length === 0? (
              <div>No se encontró ningún código de barras asociado a ese artículo</div>
            ) : isLoadingCodigoBarraError ? (
              <div>Error al obtener la información</div>
            ) : (
            <div style={{ border: "1px solid #d3d3d3", borderRadius: "5px", padding: "10px" }}>
            <div style={{ borderBottom: "1px solid #d3d3d3", paddingBottom: "5px", marginBottom: "10px" }}>
            <h4 style={{ margin: "0", color: "#333" }}>Resultados</h4>
          </div>
            <div>
              <p>
                <span style={{ fontWeight: "bolder" }}>
                  Código del artículo:
                </span>{" "}
                {fetchedCodigoBarra.artcodigo}{" "}
              </p>
              <p>
                <span style={{ fontWeight: "bolder" }}>
                  Descripción del artículo:
                </span>{" "}
                {fetchedCodigoBarra.artdescri}{" "}
              </p>
              <p>
                <span style={{ fontWeight: "bolder" }}>Código de barra:</span>{" "}
                {fetchedCodigoBarra.artcodbarra}{" "}
              </p>
            </div>
            </div>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};
export default VerificacionCodigoDeBarras;
