import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import CircularProgress from "@mui/material/CircularProgress";

function InputFiltrarProforma({ setCliente }) {
  const [clienteSelected, setClienteSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para controlar la carga

  const filterOptions = createFilterOptions({
    limit: 10,
    matchFrom: "any",
  });

  useEffect(() => {
    const getClientes = async () => {
      const options = {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      };

      try {
        setIsLoading(true); // Mostrar animación de carga
        let response = await fetchwrapper("/proformas/get_clientes", options);
        response = await response.json();
        console.log(response);
        setOptions(response);
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false); // Ocultar animación de carga
      }
    };

    getClientes();
  }, []);

  return (
    <div>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue(newValue);
            setCliente(newValue.clicodigo);
          } else {
            setValue("");
            setCliente("");
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.clinombre || ""}
        onInputChange={(event, newInputValue) => {
          if (event) {
            console.log(newInputValue)
            setInputValue(newInputValue);
          }
        }}
        id="controllable-states-demo"
        options={options}
        loading={isLoading} // Utilizar el estado de carga para bloquear el componente
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cliente"
            key={options.clicodigo}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}{" "}
                  {/* Mostrar animación de carga */}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={filterOptions}
      />
    </div>
  );
}

export default InputFiltrarProforma;
