import React, { useState } from "react";
import ModalCameraQr from "./ModalCameraQr";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const StyledButtonsFiltro = styled(Button)(({ theme }) => ({
  margin: "auto",
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

function ScannerReaderButton({codeType}) {
  const [isOpen, setIsOpen] = useState(false);

  const leerCodigoQR = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <Button
        variant="outlined"
        onClick={leerCodigoQR}
        color="secondary"
        className={StyledButtonsFiltro}
        sx={{width:"100%"}}
      >
        Lector 
      </Button>
      <ModalCameraQr isOpen={isOpen} handleClose={handleClose} codeType={codeType} />
    </div>
  );
}

export default ScannerReaderButton;
