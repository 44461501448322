import React, { useState, useEffect } from "react";
import Header from "../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import BackIcon from "../../../../components/BackIcon";
import fetchwrapper from "../../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../../components/CustomBackdrop";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ProductsReadable from "../../components/ProductsReadable";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const VerificadoVerificacionQuimico = () => {
  const location = useLocation();
  const [productosVerificadoCabecera, setProductosVerificadoCabecera] =
    useState({});
  const [productosVerificado, setProductosVerificado] = useState([]);

  const [selectedMotivo, setSelectedMotivo] = useState(null);

  const opcionesVerificacion = [
    {
      id: 1,
      descrip: "Sí",
    },
    {
      id: 2,
      descrip: "No",
    },
  ];
  const [selectedOpcionVerificacion, setSelectedOpcionVerificacion] =
    useState(null); //Id selected in custom select component (1 | 2)

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
  } = useGetProductos();

  //READ hook (get Productos from api)
  function useGetProductos() {
    return useQuery({
      queryKey: ["FiltrosVerificacionQuimProductosssssssBuscar"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/verificacionQuimico/getAllProductsVerificados/${location.state.tranumero}`
        );
        response = await response.json();

        let cabeceraResponse = response?.data?.cabecera;
        setProductosVerificadoCabecera(cabeceraResponse);

        let isAprobadoResponse = response?.data?.isAprobado;
        if (isAprobadoResponse === "aceptado") {
          setSelectedOpcionVerificacion(1);
        } else {
          setSelectedOpcionVerificacion(2);
        }

        let motivoResponse = response?.data?.motivo;
        setSelectedMotivo(motivoResponse);

        let productosResponse = response?.data?.productos;
        return productosResponse;
      },
    });
  }

  useEffect(() => {
    if (fetchedProductos.length !== 0) {
      const productsVerificadoFormat = fetchedProductos.map((product) => ({
        artcodigo: product.artcodigo,
        artdescri: product.artdescri,

        embcodigo: product.embcodigo,
        embdescripcion: product.embdescripcion,
        embmetros3: product.embmetros3,
        embpieza: product.embpieza,
        embmetros2: product.embmetros2,

        tondescripcion: product.tondescripcion,
        toncodigo: product.toncodigo,

        cantidadEmbalada: product.cantidadEmbalada,

        lotes: product.lotes,

        isAprobado: null,
      }));

      setProductosVerificado(productsVerificadoFormat);
    } else {
      setProductosVerificado([]);
    }
  }, [fetchedProductos]);

  const styles = {
    accordionSummary: {
      backgroundColor: "rgb(25, 108, 135)",
      color: "white",
      fontWeight: "bolder",
    },
    row: {
      display: "flex",
      // justifyContent: "space-between",
      alignItems: "center",
      paddingBlock: "10px",
    },
    rowTitle: {
      alignSelf: "end",
      paddingRight: "20px",
      fontWeight: "bolder",
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Verificación Químico</b>
        </div>

        <CustomBackdrop isLoading={isLoadingProductos || isFetchingProductos} />

        <Box className={StyledRoot}>
          <div style={{ textAlign: "center" }}>
            <p>
              <strong>Código de pallet:</strong> {location.state.palletid}
            </p>
            <p>
              <strong>Código de Solicitud:</strong>{" "}
              {productosVerificadoCabecera?.sgasoling}
            </p>
            <p>
              <strong>Código de Ingreso:</strong>{" "}
              {productosVerificadoCabecera?.tranumero}
            </p>
            <p>
              <strong>Inventario:</strong>{" "}
              {productosVerificadoCabecera?.invdescri}
            </p>
            <p>
              <strong>Bodega:</strong> {productosVerificadoCabecera?.boddescri}
            </p>
            <p>
              <strong>Bahía:</strong> {productosVerificadoCabecera?.bahcodigo}
            </p>
            <p>
              <strong>Zona:</strong>{" "}
              {productosVerificadoCabecera?.izondescripcion}
            </p>
            <p>
              <strong>Pasillo:</strong> {productosVerificadoCabecera?.pascodigo}
            </p>
            <p>
              <strong>Posición:</strong>{" "}
              {productosVerificadoCabecera?.poscodigo}
            </p>
            <p>
              <strong>Nivel:</strong> {productosVerificadoCabecera?.nivcodigo}
            </p>

            <div style={styles.row}>
              <span style={{ ...styles.rowTitle, paddingBottom: "10px" }}>
                Aprobado:
              </span>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  aria-label="aprobacion"
                  name="aprobacion"
                  value={selectedOpcionVerificacion}
                >
                  {opcionesVerificacion.map((opcion) => (
                    <FormControlLabel
                      key={opcion.id}
                      value={opcion.id}
                      control={<Radio />}
                      label={opcion.descrip}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>

            <div style={styles.row}>
              <span style={{ ...styles.rowTitle }}>Motivo:</span>
              {selectedMotivo?.acccodigo}. {selectedMotivo?.accion}
            </div>

            <br />
            <br />
          </div>

          <div>
            {isLoadingProductosError ? (
              <div>No se pudo ejecutar la búsqueda.Intente otra vez</div>
            ) : (
              <>
                {productosVerificado.length === 0 ? (
                  <div>Este pallet no contiene ningún artículo verificado</div>
                ) : (
                  <>
                    <ProductsReadable products={productosVerificado} />
                  </>
                )}
              </>
            )}
          </div>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default VerificadoVerificacionQuimico;
