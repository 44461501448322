import { useState } from "react";
import Header from "../../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button } from "@mui/material";

import BackIcon from "../../../../../components/BackIcon";

import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../../../../services/interceptors/fetchwrapper";
import CustomBackdrop from "../../../../../components/CustomBackdrop";
import ProductsInventarioReadable from "../../../components/ProductsInventarioReadable";
import ZebraPrinter from "../../../../utils/zebraPrinter";
import normalFormatDate from "../../../../utils/date/DDMMYYYFormatDate";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const VerTodosArticulosIngreso = () => {
  const location = useLocation();
  const [isLoadingPrintIngreso, setIsLoadingPrintIngreso] = useState(false);

  //-----------------------------------------------------

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    error: isFetchingProductosError,
  } = useGetPProductos();

  //READ hook (get InProductos from api)
  function useGetPProductos() {
    return useQuery({
      queryKey: ["ProductosDescargaCuarentenaReadable"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          `/cuarentena/getAllIngresosPalletsDetalle/${location.state?.cabecera?.tranumero}`
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }

  // Posteriormente corregir  camibar esta api a printerxproceso
  const {
    data: allPrintersProceso = [],
    isError: isLoadingallPrintersProcesoError,
    isFetching: isFetchingallPrintersProceso,
    isLoading: isLoadingallPrintersProceso,
    error: isFetchingallPrintersProcesoError,
  } = useGetallPrintersProceso();
  function useGetallPrintersProceso() {
    return useQuery({
      queryKey: ["ImpresorasDescargaCuarentenaReadable"],
      queryFn: async () => {
        //send api request here
        let options = {
          method: "POST",
          body: JSON.stringify({
            printproceso: "ZEBRA",
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        let response = await fetchwrapper("/externos/get_impresoras", options);
        response = await response.json();
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }

  async function printIngreso() {
    if (!fetchedProductos || fetchedProductos.length === 0) return;

    // Recorre cada proceso de impresion
    // Deberia haber solo 2, uno para imprimir lotes ()
    // Otro para imprimir pallets ()
    for (let printerProceso of allPrintersProceso) {
      try {
        let ipPrinter = printerProceso.printip;
        let printproceso = printerProceso.printproceso;
        let lincodigo = printerProceso.lincodigo;
        let temporalProductsToPrint = fetchedProductos;
        if (!ipPrinter && !printproceso && !lincodigo) {
          alert("No se pudo reconocer ninguna impresora");
          return;
        }
        if (!ipPrinter && !printproceso && !lincodigo) {
          alert("No se pudo reconocer ninguna impresora");
          return;
        }

        const CustomZebraPrinter = new ZebraPrinter(ipPrinter);

        console.log(
          printproceso,
          lincodigo,
          {
            dataLabels: temporalProductsToPrint.productosIngreso,
            palletID: location.state?.cabecera?.palletid,
            numIngreso: location.state?.cabecera?.tranumero,
            fecIngreso: normalFormatDate(location.state.cabecera.fechaIngreso),
            clinombre: location.state.cabecera.clinombre,
            numSol: location.state.cabecera.sgasoling,
          },
          "aquii"
        );

        setIsLoadingPrintIngreso(true);

        await CustomZebraPrinter.imprimirEtiquetas(printproceso, lincodigo, {
          dataLabels: temporalProductsToPrint.productosIngreso,
          palletID: location.state?.cabecera?.palletid,
          numIngreso: location.state?.cabecera?.tranumero,
          fecIngreso: normalFormatDate(location.state.cabecera.fechaIngreso),
          clinombre: location.state.cabecera.clinombre,
          numSol: location.state.cabecera.sgasoling,
        });
      } catch (errr) {
        console.error(errr);
        continue;
      } finally {
        setIsLoadingPrintIngreso(false);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>ARTICULOS ASOCIADOS AL INGRESO</b>
        </div>
        <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Solicitud:</strong>{" "}
            {location.state.cabecera.sgasoling}
          </p>
          <p>
            <strong>Código de Ingreso:</strong>{" "}
            {location.state?.cabecera?.tranumero}
          </p>
          <p>
            <strong>Código de Palet:</strong>{" "}
            {location.state?.cabecera?.palletid}
          </p>
          <p>
            <strong>Observación:</strong>{" "}
            {location.state?.cabecera?.tradescripcion}
          </p>
          <br />
          <br />
        </div>
        <CustomBackdrop
          isLoading={
            isLoadingProductos ||
            isFetchingProductos ||
            isLoadingallPrintersProceso ||
            isFetchingallPrintersProceso ||
            isLoadingPrintIngreso
          }
        />

        {isLoadingProductosError || isFetchingProductosError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <Button variant="contained" color="primary" onClick={printIngreso}>
              Imprimir
            </Button>
            <ProductsInventarioReadable
              productsCuarentena={fetchedProductos?.productosIngreso}
            />
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default VerTodosArticulosIngreso;
