import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "@emotion/styled";

import truncateNumber from "../../utils/math/truncate";
import normalFormatDate from "../../utils/date/DDMMYYYFormatDate";

import { useNavigate } from "react-router-dom";

const StyledDataGridContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowX: "auto",
}));

const Info = ({ info }) => {
  const {
    boddescri,
    artcodigo,
    artdescri,
    izondescripcion,
    bahcodigo,
    pascodigo,
    nivcodigo,
    poscodigo,
    embdescripcion,
    tondescripcion,
    stokdetcantidad,
    stokdetcantconver,
  } = info;
  return (
    <div
      style={{
        textAlign: "center",
        fontWeight: "bolder",
        fontSize: "17px",
        marginBottom: "50px",
      }}
    >
      <p>Descripción del Almacén: {boddescri}</p>
      <p>Código del Artículo: {artcodigo}</p>
      <p>Descripción del Artículo: {artdescri}</p>
      <p>Descripción de la Zona: {izondescripcion}</p>
      <p>Código de la Bahía: {bahcodigo}</p>
      <p>Código del Pasillo: {pascodigo}</p>
      <p>Código del Nivel: {nivcodigo}</p>
      <p>Código de la Posición: {poscodigo}</p>
      <p>Descripción del Embalaje: {embdescripcion}</p>
      <p>Descripción del Tono: {tondescripcion}</p>
      <p>Cantidad de Stock: {truncateNumber(stokdetcantidad)}</p>
      <p>Cantidad de Stock Convertida: {truncateNumber(stokdetcantconver)}</p>
    </div>
  );
};

const DatagridBuscarUbicacion = ({ data, setData, filtrarBusqueda, info }) => {
  const navigate = useNavigate();

  const columnas = [
    {
      headerName: "Lote",
      field: "artlote",
      width: 150,
    },
    {
      headerName: "Fecha Fabricación",
      field: "artfecfab",
      width: 150,
      valueFormatter: (params) => {
        return normalFormatDate(params.value);
      },
    },
    {
      headerName: "Fecha Vencimiento",
      field: "artfecven",
      width: 150,
      valueFormatter: (params) => {
        return normalFormatDate(params.value);
      },
    },
    {
      headerName: "Cantidad",
      field: "artcantlote",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value);
      },
    },
    {
      headerName: "Cantidad Conversión",
      field: "lotecantconver",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value);
      },
    },

    {
      headerName: "Cantidad por Confirmar",
      field: "artcantconfirmar",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value);
      },
    },
    {
      headerName: "Cantidad Conversión por Confirmar",
      field: "artcantconvertconfirmar",
      width: 200,
      valueFormatter: (params) => {
        return truncateNumber(params.value);
      },
    },
    {
      headerName: "Zona Código",
      field: "izoncodigo",
      width: 150,
    },

    {
      headerName: "Bahía Código",
      field: "bahcodigo",
      width: 150,
    },
    {
      headerName: "Pasillo Código",
      field: "pascodigo",
      width: 150,
    },
    {
      headerName: "Nivel Código",
      field: "nivcodigo",
      width: 150,
    },
    {
      headerName: "Posición Código",
      field: "poscodigo",
      width: 150,
    },

    {
      headerName: "Emb Código",
      field: "embcodigo",
      width: 150,
    },
    {
      headerName: "Ton Código",
      field: "toncodigo",
      width: 150,
    },
    {
      headerName: "Artículo Código",
      field: "artcodigo",
      width: 200,
    },
  ];

  return (
    <>
      <Info info={info} />
      <Box
        className={StyledDataGridContainer}
        sx={{ height: 800, width: "100%" }}
      >
        <DataGrid
          getRowId={(data) =>
            data.izoncodigo +
            "|" +
            data.bahcodigo +
            "|" +
            data.pascodigo +
            "|" +
            data.nivcodigo +
            "|" +
            data.poscodigo +
            "|" +
            data.embcodigo +
            "|" +
            data.toncodigo +
            "|" +
            data.artcodigo +
            "|" +
            data.artlote
          }
          columnVisibilityModel={{
            izoncodigo: false,
            bahcodigo: false,
            pascodigo: false,
            nivcodigo: false,
            poscodigo: false,
            embcodigo: false,
            toncodigo: false,
            artcodigo: false,
          }}
          columns={columnas}
          rows={data}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25]}
        />
      </Box>
    </>
  );
};

export default DatagridBuscarUbicacion;
