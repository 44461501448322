import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const TagSelector = ({selectedOptions, setSelectedOptions,placeholderMsg}) => {
//   const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (newValue, actionMeta) => {
    // `newValue` contiene las opciones seleccionadas
    setSelectedOptions(newValue);
  };


  return (
    <CreatableSelect
      isMulti
      onChange={handleChange}
      value={selectedOptions}
      noOptionsMessage={()=>"Ninguna opción"}
      formatCreateLabel={(inputValue) => `Crear "${inputValue}"`}
      placeholder={placeholderMsg}
    />
  );
};

export default TagSelector;
