// import React, { useEffect, useState } from "react";
// import Header from "../../../../layouts/Header";
// import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
// import { Box, Grid, TextField } from "@mui/material";

// import BackIcon from "../../../../components/BackIcon";

// import { useLocation, useNavigate } from "react-router-dom";
// import { useQuery } from "@tanstack/react-query";
// import fetchwrapper from "../../../../services/interceptors/fetchwrapper";
// import DatagridProductos from "../../components/DatagridProductos";
// import CustomBackdrop from "../../../../components/CustomBackdrop";
// import CustomSelectComponent from "../../../../components/CustomSelectComponent";
// import PalletsManagment from "../../components/PalletsManagment";
// import IngresoInventarioReadable from "../../components/IngresoInventarioReadable";

// const StyledRoot = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   marginTop: theme.spacing(8),
//   flexGrow: 1,
//   padding: "0 16px",
//   height: "100vh",
// }));

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#196C87",
//     },
//     secondary: {
//       main: "#196C87", // Cambia el color secundario a verde azulado
//     },
//   },
// });
// const ActualizarTodosIngresosReadableAsignacionUbicacion = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   //-----------------------------------------------------

//   const {
//     data: fetchedProductos = [],
//     isError: isLoadingProductosError,
//     isFetching: isFetchingProductos,
//     isLoading: isLoadingProductos,
//     error: isFetchingProductosError,
//   } = useGetPProductos();

//   //READ hook (get InProductos from api)
//   function useGetPProductos() {
//     return useQuery({
//       queryKey: ["ProductosAsignacionUbicacionReadableActualizar"],
//       queryFn: async () => {
//         //send api request here
//         let response = await fetchwrapper(
//           `/asignacionUbicacion/getAllIngresosCabeceraConUbicacionActualizar/${location.state.sgasoling}` //Esta es la misma api que se usa en cuarentena para traer todos las cabeceras de ingreso asociados a una solicitud
//         );
//         response = await response.json();
//         response = response?.data.ingresos;
//         return response;
//       },
//       refetchOnWindowFocus: false,
//     });
//   }

//   return (
//     <ThemeProvider theme={theme}>
//       <Header />
//       <div className="main main-app p-3 p-lg-4">
//         <BackIcon />
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             margin: "0 30px 30px 30px",
//             fontSize: "25px",
//           }}
//         >
//           <b>INGRESOS</b>
//         </div>

//         <div style={{ textAlign: "center" }}>
//           <p>
//             <strong>Código de Solicitud:</strong> {location.state.sgasoling}
//           </p>
//           <br />
//           <br />
//         </div>

//         <CustomBackdrop isLoading={isLoadingProductos || isFetchingProductos} />

//         {isLoadingProductosError || isFetchingProductosError ? (
//           <div>Error al obtener la información</div>
//         ) : (
//           <Box className={StyledRoot}>
//             <IngresoInventarioReadable
//               fetchedInfo={fetchedProductos}
//               isLoadingInfoError={isLoadingProductosError}
//               isLoadingInfo={isLoadingProductos}
//               isFetchingInfo={isFetchingProductos}
//             />
//           </Box>
//         )}
//       </div>
//     </ThemeProvider>
//   );
// };

// export default ActualizarTodosIngresosReadableAsignacionUbicacion;

import React, { useEffect, useState } from "react";
import Header from "../../../../layouts/Header";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Grid, TextField, Button } from "@mui/material";

import BackIcon from "../../../../components/BackIcon";

import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import fetchwrapper from "../../../../services/interceptors/fetchwrapper";
import DatagridProductos from "../../components/DatagridProductos";
import CustomBackdrop from "../../../../components/CustomBackdrop";
import CustomSelectComponent from "../../../../components/CustomSelectComponent";
import PalletsManagment from "../../components/PalletsManagment";
import IngresoInventarioReadable from "../../components/IngresoInventarioReadable";

import CustomDateRangePickerComponent from "../../../../components/CustomDateRangePickerComponent";
import CustomMultiselectComponent from "../../../../components/CustomMultiselectComponent";
import CustomChips from "../../../../components/CustomChips";

const StyledRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: theme.spacing(8),
  flexGrow: 1,
  padding: "0 16px",
  height: "100vh",
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#196C87",
    },
    secondary: {
      main: "#196C87", // Cambia el color secundario a verde azulado
    },
  },
});
const ActualizarTodosIngresosReadableAsignacionUbicacion = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Loaded info about filtros
  const [filtrosLoaded, setFiltrosLoaded] = useState({
    Cliente: [],
    Proveedor: [],
    NumeroSolicitud: [],
  });

  //State about values in each cb  in filtros
  const [clientesFiltroSelected, setClientesFiltroSelected] = useState([]);
  const [selectAllClientes, setSelectAllClientes] = useState(false);

  const [allNumPalletsFiltro, setAllNumPalletsFiltro] = useState([]);
  //-----------------------------------------------------
  const {
    data: fetchedFiltrosCB = {},
    isError: isLoadingFiltrosCBError,
    isFetching: isFetchingFiltrosCB,
    isLoading: isLoadingFiltrosCB,
  } = useGetFiltrosCB();

  //READ hook (get FiltrosCB from api)
  function useGetFiltrosCB() {
    return useQuery({
      queryKey: ["FiltrosAsignacionUbicacionCBEditarr"],
      queryFn: async () => {
        //send api request here
        let response = await fetchwrapper(
          "/verificacionDocumental/getFiltersSolicitudIngreso"
        );
        response = await response.json();
        response = response?.data;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }
  //-----------------------------------------------------

  // when all data of filtros loaded, set state in loaded filtros
  useEffect(() => {
    if (
      fetchedFiltrosCB.clientes &&
      fetchedFiltrosCB.proveedores &&
      fetchedFiltrosCB.codigosSolicitudes
    ) {
      setFiltrosLoaded({
        Cliente: fetchedFiltrosCB.clientes,
        Proveedor: fetchedFiltrosCB.proveedores,
        NumeroSolicitud: fetchedFiltrosCB.codigosSolicitudes,
      });
    }
  }, [fetchedFiltrosCB]);

  //-----------------------------------------------------

  const {
    data: fetchedProductos = [],
    isError: isLoadingProductosError,
    isFetching: isFetchingProductos,
    isLoading: isLoadingProductos,
    error: isFetchingProductosError,
    refetch: refetchFetchedProductos,
  } = useGetPProductos();

  //READ hook (get InProductos from api)
  function useGetPProductos() {
    return useQuery({
      queryKey: ["ProductosAsignacionUbicacionReadableActualizar"],
      queryFn: async () => {
        const options = {
          method: "POST",
          body: JSON.stringify({
            Cliente: clientesFiltroSelected,
            NumerosPallets: allNumPalletsFiltro,
          }),
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        };
        //send api request here
        let response = await fetchwrapper(
          `/asignacionUbicacion/getAllIngresosCabeceraConUbicacionActualizar`,
          options
        );
        response = await response.json();
        response = response?.data.ingresos;
        return response;
      },
      refetchOnWindowFocus: false,
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <BackIcon />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 30px 30px 30px",
            fontSize: "25px",
          }}
        >
          <b>Asignación de Ubicación</b>
        </div>

        {/* <div style={{ textAlign: "center" }}>
          <p>
            <strong>Código de Solicitud:</strong> {location.state.sgasoling}
          </p>
          <br />
          <br />
        </div> */}

        <CustomBackdrop isLoading={isLoadingProductos || isFetchingProductos} />

        {isLoadingProductosError ||
        isFetchingProductosError ||
        isLoadingFiltrosCBError ? (
          <div>Error al obtener la información</div>
        ) : (
          <Box className={StyledRoot}>
            <CustomBackdrop
              isLoading={isLoadingFiltrosCB || isFetchingFiltrosCB}
            />

            <div>
              <div
                style={{
                  display: "grid",
                  gap: "16px",
                  maxWidth: "1200px",
                  margin: "0 auto",
                }}
              >
                <CustomMultiselectComponent
                  options={filtrosLoaded.Cliente}
                  value={clientesFiltroSelected}
                  setValue={setClientesFiltroSelected}
                  selectAll={selectAllClientes}
                  setSelectAll={setSelectAllClientes}
                  placeholder="Selecciona los clientes"
                />

                <CustomChips
                  value={allNumPalletsFiltro}
                  setValue={setAllNumPalletsFiltro}
                  placeholder="Digite las códigos de pallets"
                />
              </div>

              {/* <div
                style={{
                  display: "grid",
                  gap: "16px",
                  maxWidth: "1200px",
                  margin: "20px auto",
                }}
              >
                <CustomDateRangePickerComponent
                  value={rangoFechasCreacionFiltroSelected}
                  setValue={setRangoFechasCreacionFiltroSelected}
                  placeholder="Rango de fechas de creación solicitud"
                  size="lg"
                />
              </div> */}
            </div>

            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={refetchFetchedProductos}
              >
                Filtrar
              </Button>
            </div>

            <IngresoInventarioReadable
              fetchedInfo={fetchedProductos}
              isLoadingInfoError={isLoadingProductosError}
              isLoadingInfo={isLoadingProductos}
              isFetchingInfo={isFetchingProductos}
            />
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default ActualizarTodosIngresosReadableAsignacionUbicacion;
