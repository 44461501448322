import React, { useEffect, useState } from "react";

import TextoLibre from "./TextoLibre";
import ListaOpciones from "./ListaOpciones";
import OpcionesMultiples from "./OpcionesMultiples";

const Form = ({ form, setSpecificForm, step, isReadable }) => {
  return (
    <div>
      {form?.preguntas?.map((pregunta, index) => {
        let component = null;
        switch (pregunta.pregtipo) {
          case "U":
            component = (
              <TextoLibre
                key={pregunta.pregcodigo}
                pregcodigo={pregunta.pregcodigo}
                pregobligatoria={pregunta.pregobligatoria}
                description={pregunta.pregdescri}
                value={
                  pregunta?.respuestaSelected ? pregunta?.respuestaSelected : ""
                }
                setValue={setSpecificForm}
                form={form}
                step={step}
                isReadable={isReadable}
              />
            );

            break;
          case "L":
            component = (
              <ListaOpciones
                key={pregunta.pregcodigo}
                pregcodigo={pregunta.pregcodigo}
                pregobligatoria={pregunta.pregobligatoria}
                description={pregunta.pregdescri}
                opciones={pregunta.opciones}
                value={
                  pregunta?.respuestaSelected
                    ? pregunta?.respuestaSelected?.opcion
                    : ""
                }
                setValue={setSpecificForm}
                form={form}
                step={step}
                isReadable={isReadable}
              />
            );

            break;
          case "M":
            component = (
              <OpcionesMultiples
                key={pregunta.pregcodigo}
                pregcodigo={pregunta.pregcodigo}
                pregobligatoria={pregunta.pregobligatoria}
                description={pregunta.pregdescri}
                opciones={pregunta.opciones}
                values={
                  pregunta?.respuestaSelected ? pregunta?.respuestaSelected : []
                }
                setValue={setSpecificForm}
                form={form}
                step={step}
                isReadable={isReadable}
              />
            );

            break;

          default:
            break;
        }
        return component;
      })}
    </div>
  );
};

export default Form;
