import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import fetchwrapper from "../../../services/interceptors/fetchwrapper";
import { CircularProgress } from "@mui/material";

function InputSelectorVendedor({ cabeceraProforma,setCabeceraProforma }) {
  const [clienteSelected, setClienteSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(""); // Establecer el valor inicial
  const [inputValue, setInputValue] = useState(cabeceraProforma.vennombre || "");
  const [isLoading, setIsLoading] = useState(true); // Nuevo estado para controlar la carga

  const filterOptions = createFilterOptions({
    limit: 10,
    matchFrom: "any",
  });


  useEffect(() => {
    setInputValue(cabeceraProforma.vennombre || ""); // Establecer el valor inicial
  }, []); // Añadir cabeceraProforma.vennombre a las dependencias


  useEffect(() => {
    const getFormaDePago = async () => {
      try {
        setIsLoading(true)
        let response = await fetchwrapper(`/proformas/getVendedor`);
        const data = await response.json();

      
        setOptions(data);
      } catch (err) {
        console.error("error", err);
        console.error(err);
      }finally{
        setIsLoading(false)
      }
    };
    getFormaDePago();
  }, []);

  return (
    <div>
      <Autocomplete
        fullWidth
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            setValue(newValue);
            console.log(cabeceraProforma)
            console.log("cabeceradesdevendedor")
            setCabeceraProforma({
              ...cabeceraProforma,
              vencodigo:newValue.vencodigo,
              vennombre:newValue.vennombre
              
            })
            console.log(newValue)
            console.log(cabeceraProforma)

          } else {
            setValue("");
          }
        }}
        inputValue={inputValue}
        getOptionLabel={(option) => option.vennombre || ""}
        onInputChange={(event, newInputValue) => {
          if (event) {
            console.log(event.target);
            setInputValue(newInputValue);
          }
        }}
        id="controllable-states-demo"
        options={options}
        loading={isLoading} // Utilizar el estado de carga para bloquear el componente
        renderInput={(params) => (
          <TextField
          required
            {...params}
            key={options.vencodigo}

            label="Vendedor"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading && <CircularProgress color="inherit" size={20} />}{" "}
                  {/* Mostrar animación de carga */}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        filterOptions={filterOptions}
      />
    </div>
  );
}

export default InputSelectorVendedor;
